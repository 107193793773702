
import { Options, Vue } from 'vue-class-component';
import { EventBus } from '../events/EventBus';
import { EventType } from '../events/EventType';
import { Box3, DirectionalLight, Font, HemisphereLight, Mesh, MeshBasicMaterial, PerspectiveCamera, TextGeometry, Vector3, Clock } from 'three';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import SceneUI from './SceneUI.vue';
import { UIDebugParams } from '@/debugTools/UIDebugParams';
import { SceneSun } from '@/scene/SceneSun';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { SunRotationCalculator } from '@/calculators/SunRotationCalculator';
import { SunPositionCalculator } from '@/calculators/SunPositionCalculator';
import { CustomScene } from '../scene/CustomScene';
import { SceneMouse } from '../scene/SceneMouse';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import font from 'three/examples/fonts/helvetiker_regular.typeface.json';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { FirstPersonViewManager } from '@/Managers/FirstPersonViewManager';
import { ProjectManager } from '@/Managers/ProjectManager';
import StepBanner from "../components/StepBanner.vue";
import {LoadSaveHelper} from "../helpers/LoadSaveHelper";
import SideMenu from '@/components/SideMenu.vue';

@Options({
  props: {
    object3DFactory: Object3DFromBoxFactory,
    repositorys: Map,
    selectionManager: SelectionManager,
    infoProjet: SceneInfoProjet,
    loadSaveHelper: LoadSaveHelper
  },
  components: {
    SceneUI,
    SideMenu,
    StepBanner
  },
  methods: {
    animate () {
      requestAnimationFrame( this.animate );
      if (!this.firstPersonViewManager.isFirstPersonActivaded()) {
        this.customScene.getControls().update();
        this.uiDebugParams.cameraAngle = this.customScene.getCamera().rotation
      }
      else {
        this.firstPersonViewManager.move((this.clock.getDelta()));
      }
      this.uiDebugParams.setLastFrameTimestamp(Date.now());
      this.customScene.getRenderer().render( this.customScene.getSceneInstance(), this.customScene.getCamera() );
    },
    onWindowResize(){
      const trueSize = this.$refs.sceneSubInternalContainer.getBoundingClientRect();
      this.customScene.resizeWindow(trueSize);
    },
    changeLight (dayPourcentage: number) {
      const newPosition = this.sunPositionCalculator.calculatePositionByDayPercentage(dayPourcentage);
      const newRotation = this.sunRotationCalculator.calculateRotationByDayPercentage(dayPourcentage);
      this.sun.setSunPosition(newPosition);
      this.sun.setSunRotation(newRotation);
    },
    addCardinalPoints () {
      const configs = {
        font: new Font(font),
        size: 50,
        height: 0,
        curveSegments: 6,
        bevelEnabled: true,
        bevelThickness: 5,
        bevelSize: 4,
        bevelOffset: 0,
        bevelSegments: 2.5
      }
      const material = new MeshBasicMaterial( { color: "black" } );
      const materialNord = new MeshBasicMaterial( { color: "red" } );
      
      const textNord = new TextGeometry( 'N', configs);
      const itemNord = new Mesh( textNord, materialNord );
      this.customScene.getSceneInstance().add(itemNord);
      itemNord.position.z = -1000;
      itemNord.geometry.computeBoundingBox();
      itemNord.position.x -= (itemNord.geometry.boundingBox as Box3).getSize(new Vector3()).x/2;

      const textSud = new TextGeometry( 'S', configs);
      const itemSud = new Mesh( textSud, material );
      this.customScene.getSceneInstance().add(itemSud);
      itemSud
      itemSud.position.z = 1000;
      itemSud.geometry.computeBoundingBox();
      itemSud.position.x += (itemSud.geometry.boundingBox as Box3).getSize(new Vector3()).x/2;
      itemSud.rotation.y = Math.PI;

      const textEst = new TextGeometry( 'E', configs);
      const itemEst = new Mesh( textEst, material );
      this.customScene.getSceneInstance().add(itemEst);
      itemEst.position.x = 1000;
      itemEst.geometry.computeBoundingBox();
      itemEst.position.z -= (itemEst.geometry.boundingBox as Box3).getSize(new Vector3()).x/2;
      itemEst.rotation.y = Math.PI/2*3;

      const textOuest = new TextGeometry( 'O', configs);
      const itemOuest = new Mesh( textOuest, material );
      this.customScene.getSceneInstance().add(itemOuest);
      itemOuest.position.x = -1000;
      itemOuest.geometry.computeBoundingBox();
      itemOuest.position.z += (itemOuest.geometry.boundingBox as Box3).getSize(new Vector3()).x/2;
      itemOuest.rotation.y = Math.PI/2;
    },
    reloadRenderer () {
      this.$refs.scene.innerHTML = "";
      this.$refs.scene.appendChild( this.customScene.getRenderer().domElement );
      this.onWindowResize();
    },
    onStepChanged () {
      this.currentStep = ProjectManager.getCurrentStep(); 
      if (this.currentStep >= 3) { 
        this.$nextTick(this.onWindowResize);
      }
    }
  },
  mounted () {
    const trueSize = this.$refs.scene.getBoundingClientRect();
    const camera = new PerspectiveCamera( 45, trueSize.width/trueSize.height, 1, 10000 );
    camera.position.set( 0, 10, 30 );
    const light = new DirectionalLight( 0xffffff, 0.7 );
    this.sun = new SceneSun(light);
    const globalLight = new HemisphereLight();
    globalLight.intensity = 0.4;
    this.customScene = new CustomScene(camera, this.object3DFactory, this.selectionManager, this.infoProjet, document.body);
    document.addEventListener('keydown', (event) => this.firstPersonViewManager.onKeyDown(event) );
    document.addEventListener('keyup', (event) => this.firstPersonViewManager.onKeyUp(event) );
    document.addEventListener('click', (event) => this.firstPersonViewManager.onMouseClick(event) );
    SceneCommunicationBus.emitReady(CustomScene, this.customScene);
    ProjectManager.setScene(this.customScene);
    this.loadSaveHelper.setScene(this.customScene);
    SceneCommunicationBus.emitReady(LoadSaveHelper, this.loadSaveHelper);
    this.firstPersonViewManager = new FirstPersonViewManager(this.customScene, this.selectionManager);
    SceneCommunicationBus.emitReady(FirstPersonViewManager, this.firstPersonViewManager);
    this.$refs.scene.appendChild( this.customScene.getRenderer().domElement );
    this.sceneMouse = new SceneMouse(this.customScene, this.selectionManager, this.firstPersonViewManager);
    this.customScene.addObject3DInScene(globalLight);
    this.customScene.addObject3DInScene(light);
    this.customScene.addObject3DInScene(this.sun.getSunObject());
    this.animate();
    this.changeLight(25);
    window.addEventListener('resize', this.onWindowResize, false );
    EventBus.on(EventType.changeLight, this.changeLight);
    this.onWindowResize();
    this.addCardinalPoints();

    document.addEventListener('keydown', (event) => {
      if(event.key === "Escape"){
          if(this.selectionManager.isSelectedBoxMoving){
            this.selectionManager.deleteSelectedBox();
          }
      }
    });
    
    EventBus.on(EventType.rendererChanged, this.reloadRenderer);
    EventBus.on(EventType.stepChanged, this.onStepChanged);
    if(ProjectManager.getCurrentStep() === -1) {
      ProjectManager.setCurrentStep(3);
    }
    
    if(ProjectManager.getProject().isSet || ProjectManager.getProject().id !== null){
      this.loadSaveHelper.addLoadedObjectsToScene();
    }
    this.customScene.updatesWallsOfBoxes(true);
    EventBus.emit(EventType.sceneLoadCompleted);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onWindowResize, false );
    EventBus.all.clear();
  }
})
export default class Scene extends Vue {
  sceneMouse!: SceneMouse;
  uiDebugParams: UIDebugParams = new UIDebugParams();
  sun!: SceneSun;
  sunPositionCalculator: SunPositionCalculator = new SunPositionCalculator(false);
  sunRotationCalculator: SunRotationCalculator = new SunRotationCalculator(false);
  clock: Clock = new Clock();
  customScene!: CustomScene;
  firstPersonViewManager!: FirstPersonViewManager;
}
