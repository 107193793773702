import { ToastEmitterOptions } from './ToastEmitter/ToastEmitterOptions';
import { ToastEmitterOptionsType } from './ToastEmitter/ToastEmitterOptionsType';

class ToastNotificationsBusClass {
    private events: Map<ToastEmitterOptionsType, Function[]> = new Map<ToastEmitterOptionsType, Function[]>();

    public on (type: ToastEmitterOptionsType, func: Function){
        if(this.events.has(type)) {
            (this.events.get(type) as Function[]).push(func);
        }
        else {
            const newArray: Function[] = [];
            newArray.push(func);
            this.events.set(type, newArray);
        }
    }

    public emit (type: ToastEmitterOptionsType, options: ToastEmitterOptions) {
        const array = this.events.get(type);
        if (array != null) {
            array.forEach(func => {
                func(options);
            })
        }
    }
}

export const ToastNotificationsBus: ToastNotificationsBusClass = new ToastNotificationsBusClass();