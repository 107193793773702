import { createStore } from 'vuex'
import auth from '@/store/auth'
import user from '@/store/user'
import save from '@/store/save'


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    save
  }
})




