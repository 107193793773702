import { PerformanceManager } from '../Managers/PerformanceManager';
import { PriceManager } from '../Managers/PriceManager';
import { AddingRuleChecker } from "@/ActionsRulesCheckers/AddingRuleChecker";
import { Object3D, PerspectiveCamera, Scene, Vector3, WebGLRenderer, Euler } from "three";
import { SceneGrid } from "./SceneGrid";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import { Object3DColorSetter } from "@/helpers/Object3DColorSetter";
import { BoxsObject3D, Object3DFromBoxFactory } from "@/factorys/Object3DFromBoxFactory";
import { Box } from "@/Box/Box";
import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { DeletingRuleChecker } from "@/ActionsRulesCheckers/DeletingRuleChecker";
import { BoxWallBesideHelper } from "@/helpers/BoxWallBesideHelper";
import { SelectionManager } from "@/Managers/SelectionManager/SelectionManager";
import { SelectedBoxState } from "@/Managers/SelectionManager/SelectedBoxState";
import { MovingRuleChecker } from '@/ActionsRulesCheckers/MovingRuleChecker';
import { SceneOptions } from './SceneOptions';
import { BoxStairs } from '@/Box/BoxParts/BoxStairs';
import { SceneCommunicationBus } from './SceneCommunicationBus';
import { FirstPersonViewManager } from '@/Managers/FirstPersonViewManager';
import { ProjectManager } from '@/Managers/ProjectManager';
import { BoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { SceneInfoProjet } from './SceneInfoProjet';
import { StairsManager } from '@/Managers/StairsManager';
import { BoxRotationDirection } from '@/Box/BoxRotationDirection';

export class CustomScene {

    private renderer: WebGLRenderer
    private camera: PerspectiveCamera;
    private grid: SceneGrid;
    private sceneObjects: Box[];
    private debugsObjects: Array<Object3D>;
    private addingRuleChecker: AddingRuleChecker;
    private deletingRuleChecker: DeletingRuleChecker;
    private movingRuleChecker: MovingRuleChecker;
    private orbitControls: OrbitControls;
    private firstPersonControls: PointerLockControls;
    private object3DColorSetter: Object3DColorSetter;
    private object3DFactory: Object3DFromBoxFactory;
    private scene: Scene;
    private boxWallBesideHelper: BoxWallBesideHelper;
    private selectionManager: SelectionManager;
    private priceManager: PriceManager;
    private performanceManager: PerformanceManager;
    private options: SceneOptions;
    private firstPersonViewManager!: FirstPersonViewManager;
    private stairsManager: StairsManager;

    public constructor(camera: PerspectiveCamera, object3DFactory: Object3DFromBoxFactory, selectionManager: SelectionManager, infoProjet: SceneInfoProjet, documentBody: HTMLElement) {
        this.options = new SceneOptions(this.refreshSceneObjectsPositionsAndSetTransparent.bind(this), this.resetRenderer.bind(this));
        SceneCommunicationBus.emitReady(SceneOptions, this.options );
        this.renderer = new WebGLRenderer({ antialias: this.options.antialiasing, alpha: true });
        this.scene = new Scene();
        this.object3DFactory = object3DFactory;
        this.camera = camera;
        this.debugsObjects = new Array<Object3D>();
        this.object3DColorSetter = new Object3DColorSetter();
        this.grid = new SceneGrid(28, 56, 2, 4.679245471954346);
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
        this.firstPersonControls = new PointerLockControls(this.camera, documentBody);
        this.sceneObjects = new Array<Box>();
        this.deletingRuleChecker = new DeletingRuleChecker(this.sceneObjects, this.grid);
        this.movingRuleChecker = new MovingRuleChecker(this.sceneObjects, this.grid);
        this.boxWallBesideHelper = new BoxWallBesideHelper(this.sceneObjects, this.grid);
        SceneCommunicationBus.emitReady(BoxWallBesideHelper, this.boxWallBesideHelper);
        this.addingRuleChecker = new AddingRuleChecker(this.sceneObjects, this.grid, this.boxWallBesideHelper);
        this.selectionManager = selectionManager;
        this.priceManager = new PriceManager(infoProjet,this.sceneObjects);
        this.performanceManager = new PerformanceManager(infoProjet,this.sceneObjects);
        this.orbitControls.update();
        
        this.stairsManager = new StairsManager(this);
        SceneCommunicationBus.emitReady(StairsManager, this.stairsManager );
        
        //this.controls.maxPolarAngle = 3 * Math.PI / 8;

        this.scene.add(this.grid.getGrid());
        this.scene.add(this.grid.getGridHitBox());
        this.scene.add(this.grid.getGazon());
        this.bindToEvents();
    }

    private resetRenderer () {
        this.renderer = new WebGLRenderer({ antialias: this.options.antialiasing, alpha: true });
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement);
        EventBus.emit(EventType.rendererChanged);
    }

    public getOptions(): SceneOptions {
        return this.options;
    }

    public getAllStairsFromBoxesInCurrentFloor (): BoxStairs[] {
        return this.getSceneObjectsFromCurrentFloor().filter(box => box.getStairs() !== undefined).map(box => box.getStairs() as BoxStairs);
    }

    private bindToEvents() {
        EventBus.on(EventType.floorUp, this.floorUp.bind(this));
        EventBus.on(EventType.floorDown, this.floorDown.bind(this));
        EventBus.on(EventType.boxDeleted, ((box: any) => this.deleteBox(box)).bind(this));
        EventBus.on(EventType.selectedBoxMovingChanged, this.selectedBoxMovingChanged.bind(this));
        EventBus.on(EventType.addPreviewObjectToScene, ((box: any) => this.ajouterObjPreview(box)).bind(this));
        EventBus.on(EventType.selectedBoxNeedRefresh, ((box: any) => this.selectedBoxNeedRefresh(box)).bind(this));
        SceneCommunicationBus.whenInstanceIsReady (FirstPersonViewManager, (firstPersonViewManager) => { this.firstPersonViewManager = firstPersonViewManager });
    }

    public getRenderer(): WebGLRenderer {
        return this.renderer;
    }

    public getCamera(): PerspectiveCamera {
        return this.camera;
    }

    public getGrid(): SceneGrid {
        return this.grid;
    }

    public getSceneObjects(): Box[] {
        return this.sceneObjects;
    }

    public getSceneObjectsFromCurrentFloor(): Box[] {
        const objectsOnFloor = new Array<Box>();
        this.sceneObjects.forEach(obj => {
            if (this.grid.getFloorOfBox(obj) === this.grid.getEtageActif()){
                objectsOnFloor.push(obj);
            }
        });
        return objectsOnFloor;
    }

    public getAddingRuleChecker(): AddingRuleChecker {
        return this.addingRuleChecker;
    }

    public getMovingRuleChecker(): MovingRuleChecker {
        return this.movingRuleChecker;
    }

    public getControls(): OrbitControls | PointerLockControls {
        if (this.firstPersonViewManager.isFirstPersonActivaded()) {
            return this.firstPersonControls;
        }
        else {
            return this.orbitControls;
        }
    }

    public getObject3DColorSetter(): Object3DColorSetter {
        return this.object3DColorSetter;
    }

    public getObject3DFactory(): Object3DFromBoxFactory {
        return this.object3DFactory;
    }

    public getSceneInstance(): Scene {
        return this.scene;
    }

    public addBoxInSceneFromPreview(box: Box, boxPositionOnGrid: Vector3) {
        this.sceneObjects.push(box);
        this.getGrid().addBoxOnGrid(box, boxPositionOnGrid);
        this.priceManager.update();
        this.performanceManager.update();
        ProjectManager.calculateAvalablesSteps(false);
    }
    
    public addStairsToBoxes (stairs: BoxStairs) {
        (stairs.getBoxOnBottom() as Box).setStairs(stairs);
        (stairs.getBoxOnTop() as Box).setStairs(stairs);
        this.refreshBox(stairs.getBoxOnBottom() as Box);
        (stairs.getBoxOnBottom() as Box).setPosition((stairs.getBoxOnBottom() as Box).getPosition());
        this.updatesWallsOfBoxes(true);
    }

    private ajouterObjPreview(box: Box) {
        box.updateAllScales();
        this.refreshBox(box);
        box.setPosition(this.getGrid().getGridMiddlePosition());
        
        this.selectionManager.setSelectedBox(box, SelectedBoxState.newBox);
        this.selectionManager.setIfSelectedBoxIsMoving(true);
        const ifPermitted = this.addingRuleChecker.isActionPermitted(box, false);
        if (ifPermitted) {
            box.setBoxColor(this.object3DColorSetter, "green");
        }
        else {
            box.setBoxColor(this.object3DColorSetter, "red");
        }
    }

    private selectedBoxNeedRefresh(box: Box) {
        this.refreshBox(box);
        this.updatesWallsOfBoxes(true);
        if (this.selectionManager.getSelectedBoxState() == SelectedBoxState.newBox) {
            const ifPermitted = this.addingRuleChecker.isActionPermitted(box, false);
            if (ifPermitted) {
                box.setBoxColor(this.object3DColorSetter, "green");
            }
            else {
                box.setBoxColor(this.object3DColorSetter, "red");
            }
        }
        else {
            box.setBoxColor(this.object3DColorSetter, "blue");
        }
    }

    public updatesWallsOfBoxes (ifCheckBeside: boolean) {
        this.sceneObjects.forEach(box => {
            box.getWalls().forEach(wallGroup => {
                wallGroup.forEach(wall => wall.setVisible(wall.getForcedState() !== BoxWallState.none));
            });
        });
        if(ifCheckBeside) {
            this.boxWallBesideHelper.getWallsBesideEachOthers().forEach(wallsCombo => {
                if (wallsCombo[1] !== undefined) {
                    
                    const rotation = wallsCombo[0].getRotation();
                    let nbOfWallNotBeside = 0;
                    wallsCombo[0].forEach(wall => {
                        let wallBesideIndex = -1;
                        if(rotation === BoxRotationDirection.Nord || rotation === BoxRotationDirection.Sud) {
                            wallBesideIndex = wallsCombo[1]!.findIndex(wall2 => Math.fround(wall.getTruePosition().z) === Math.fround(wall2.getTruePosition().z));
                        }
                        else {
                            wallBesideIndex = wallsCombo[1]!.findIndex(wall2 => Math.fround(wall.getTruePosition().x) === Math.fround(wall2.getTruePosition().x));
                        }
                        if(wallBesideIndex != -1) {
                            const wallBeside = wallsCombo[1]![wallBesideIndex];
                            if (!wall.hasForcedState()) {
                                if (wallBeside.hasForcedState() && wallBeside.getForcedState() !== BoxWallState.none) {
                                    wall.setVisible(true);
                                }
                                else {
                                    wall.setVisible(false);
                                }
                            }
                            else if (wall.getForcedState() === BoxWallState.none){
                                wall.setVisible(false);
                            }
                            else {
                                wall.setVisible(true);
                            }
                        }
                        else {
                            nbOfWallNotBeside++;
                        }
                    });
                    if(nbOfWallNotBeside === wallsCombo[0].length) {
                        const tempWallGroup = new Array<BoxWall>();
                        wallsCombo[1].forEach(wall => tempWallGroup.push(wall));
                        tempWallGroup.reverse();
                        for (let x = 0; x < wallsCombo[0].length; x++) {
                            const wall = wallsCombo[0][x];
                            if (!wall.hasForcedState()) {
                                if (tempWallGroup.length > x && tempWallGroup[x].hasForcedState() && tempWallGroup[x].getForcedState() !== BoxWallState.none) {
                                    wall.setVisible(true);
                                }
                                else {
                                    wall.setVisible(false);
                                }
                            }
                            else if (wall.getForcedState() === BoxWallState.none){
                                wall.setVisible(false);
                            }
                            else {
                                wall.setVisible(true);
                            }
                        }
                    }
                }
                else {
                    wallsCombo[0].forEach(wall => {
                        if (!wall.hasForcedState()) {
                            wall.setVisible(false);
                        }
                        else if (wall.getForcedState() === BoxWallState.none){
                            wall.setVisible(false);
                        }
                        else {
                            wall.setVisible(true);
                        }
                    });
                }
            });
        }
        this.sceneObjects.forEach(box => {
            box.updateWallBitsVisible();
        });
    }

    private selectedBoxMovingChanged() {
        this.orbitControls.enableZoom = !this.selectionManager.getIfSelectedBoxIsMoving();
        this.updatesWallsOfBoxes(!this.selectionManager.getIfSelectedBoxIsMoving());
    }

    public addObject3DInScene(objects: Object3D | Object3D[]) {
        if (Array.isArray(objects)) {
            objects.forEach(obj => {
                this.scene.add(obj);
            });
        }
        else {
            this.scene.add(objects);
        }
    }

    public getGridArrayFromGridHitbox() {
        const array: Object3D[] = [];
        array.push(this.grid.getGridHitBox());
        return array;
    }

    private floorUp() {
        if(this.grid.getEtageActif() < 1) {
            this.grid.setEtageActif(1);
            this.updateFloor();
        }
    }

    private floorDown() {
        if(this.grid.getEtageActif() > 0) {
            this.grid.setEtageActif(0);
            this.updateFloor();
        }
    }

    private updateFloor() {
        if (this.selectionManager.isThereABoxSelected()){
            if(!this.selectionManager.getIfSelectedBoxIsMoving()) {
                this.selectionManager.clearSelectedBox();
            }
            else if(this.selectionManager.getSelectedBoxState() == SelectedBoxState.existingBox){
                const selectedBoxGridPosition = (this.grid.getGridPositionFromBox(this.selectionManager.getSelectedBox()) as Vector3);
                selectedBoxGridPosition.y = this.grid.getEtageActif();
                this.grid.addBoxOnGrid(this.selectionManager.getSelectedBox(),selectedBoxGridPosition);
            }
            else {
                const selectedBoxGridPosition = (this.grid.getGridSquareContainingPosition(this.selectionManager.getSelectedBox().getPosition()) as Vector3);
                selectedBoxGridPosition.y = this.grid.getEtageActif();
                this.selectionManager.getSelectedBox().setPosition(this.grid.getPositionFromGridSquare(selectedBoxGridPosition));
            }
        }
        this.refreshSceneObjectsPositionsAndSetTransparent();
        if(this.selectionManager.getIfSelectedBoxIsMoving()) {
            const ifPermitted = this.addingRuleChecker.isActionPermitted(this.selectionManager.getSelectedBox(), false);
            if (ifPermitted) {
                this.selectionManager.getSelectedBox().setBoxColor(this.object3DColorSetter, "green");
            }
            else {
                this.selectionManager.getSelectedBox().setBoxColor(this.object3DColorSetter, "red");
            }
        }
        
    }

    private refreshSceneObjectsPositionsAndSetTransparent() {
        if (!this.options.isOtherFloorVisible) {
            this.sceneObjects.forEach(box => {
                const ifTransparent = this.grid.getFloorOfBox(box) != this.grid.getEtageActif();
                box.setPosition(this.grid.getTruePositionFromBox(box) as Vector3);
                if(ifTransparent){
                    this.refreshBox(box);
                    box.setBoxTransparent(this.object3DColorSetter);
                }
                else {
                    this.refreshBox(box);
                }
            });
        }
        else {
            this.sceneObjects.forEach(box => {
                box.setPosition(this.grid.getTruePositionFromBox(box) as Vector3);
                this.refreshBox(box);
            });
        }
    }

    public refreshBox (box: Box) { 
        const newObj3D = this.object3DFactory.getNewObject3DFromBox(box);
        this.removeBox3dObjectsFromScene(box);
        box.refreshBox(newObj3D as BoxsObject3D);
        this.addBox3dObjectsInScene(box);
    }

    private removeBox3dObjectsFromScene (box: Box) {
        box.getAllObjects3dOfBox().forEach(obj => {
            this.scene.remove(obj);
        });
        this.scene.remove(box.getCollision().getHitbox());
        //if (this.options.debugMode) {
            box.getAllHitboxsOfAllObjects3dOfBox().forEach(hitbox => {
                this.scene.remove(hitbox);
            });
        //}
    }

    private addBox3dObjectsInScene (box: Box) {
        box.getAllObjects3dOfBox().forEach(obj => {
            this.scene.add(obj);
        });
        const boxHitbox = box.getCollision();
        boxHitbox.setIsVisible(false);
        this.scene.add(boxHitbox.getHitbox());
        if (this.options.debugMode) {
            boxHitbox.setIsVisible(true);
            box.getAllHitboxsOfAllObjects3dOfBox().forEach(hitbox => {
                this.scene.add(hitbox);
            });
        }
    }

    public deleteBox(box: Box) {
        if (this.deletingRuleChecker.isActionPermitted(box)) {
            if (this.selectionManager.isThisBoxSelected(box)) {
                this.selectionManager.clearSelectedBox();
            }
            this.removeBox3dObjectsFromScene(box);
            const sceneObjectsId = this.sceneObjects.indexOf(box, 0);
            if (sceneObjectsId > -1) {
                this.sceneObjects.splice(sceneObjectsId, 1);
            }
            const debugsObjectsId = this.debugsObjects.indexOf(box.getCollision().getHitbox(), 0);
            if (debugsObjectsId > -1) {
                this.debugsObjects.splice(debugsObjectsId, 1);
            }
            this.grid.removeBoxOnGrid(box);
            this.updatesWallsOfBoxes(true);
            ProjectManager.calculateAvalablesSteps(false);
        }
    }

    public deleteStairsFromBox (box: Box) {
        const stairs = box.getStairs();
        if(stairs != undefined) {
            this.scene.remove(stairs.getObject());
            const debugsObjectsId = this.debugsObjects.indexOf(stairs.getHitbox(), 0);
            if (debugsObjectsId > -1) {
                this.debugsObjects.splice(debugsObjectsId, 1);
            }
            this.scene.remove(stairs.getHitbox())
            stairs.getBoxOnBottom()?.removeStairs()
            stairs.getBoxOnTop()?.removeStairs()
        }
        this.updatesWallsOfBoxes(true);
    }

    public resizeWindow(trueSize: DOMRect) {
        this.renderer.setSize(trueSize.width, trueSize.height);
        this.camera.aspect = trueSize.width / trueSize.height;
        this.camera.updateProjectionMatrix();
    }

    public ajouterBox(box: Box, boxPositionOnGrid: Vector3) {
        box.updateAllScales();
        this.refreshBox(box);
        this.sceneObjects.push(box);
        this.getGrid().addBoxOnGrid(box, boxPositionOnGrid);
        box.setPosition(this.getGrid().getPositionFromGridSquare(boxPositionOnGrid) as Vector3);
        this.priceManager.update();
        this.performanceManager.update();
    }

    public getPriceManager(): PriceManager {
        return this.priceManager;
    }

    public getPerformanceManager(): PerformanceManager {
        return this.performanceManager;
    }
}