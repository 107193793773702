import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import Home from '../views/Home.vue'
import Inscription from '../views/Inscription.vue'
import FormCreation from '../views/FormCreation.vue'
import HomeClient from '../views/HomeClient.vue'
import PageAdmin from '../views/PageAdmin.vue'
import Rapports from '../views/Rapports.vue'
import Profil from '../views/Profil.vue'
import ChoixTemplateProjet from '../views/ChoixTemplateProjet.vue'
import Synthese from '../views/Synthese.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/MainPage/:projectId',
    name: 'MainPage',
    component: MainPage,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/Synthese/:projectId',
    name: 'Synthese',
    component: Synthese,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/inscription',
    name: 'Inscription',
    component: Inscription
  },
  {
    path: '/homeclient',
    name: 'HomeClient',
    component: HomeClient,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/formcreation/:projectId?',
    name: 'FormCreation',
    component: FormCreation,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'PageAdmin',
    component: PageAdmin,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/rapports',
    name: 'Rapports',
    component: Rapports,
    meta: {
      requiresAdmin: true
    }
  },
  {
    path: '/profil',
    name: 'Profil',
    component: Profil,
    meta: {
      requiresLogin: true
    }
  },
  {
    path: '/ChoixTemplateProjet/:projectId',
    name: 'ChoixTemplateProjet',
    component: ChoixTemplateProjet,
    meta: {
      requiresLogin: true
    }
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('token') != null;
  let isAdmin = false;
  if(localStorage.getItem('categorie') != null) {
    isAdmin = parseInt(localStorage.getItem('categorie') as string) ===  1;
  }
  if (to.matched.some(record => record.meta.requiresLogin) && !isLoggedIn) {
    next({ name: 'Home' })
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (isLoggedIn && isAdmin) {
      next()
    } else {
      next({ name: 'Home' })
    }
  } else if (to.matched.some(record => record.name === 'Home')) {
    if (isLoggedIn) {
      if (isAdmin) {
        next({ name: 'PageAdmin' })
      } else {
        next({ name: 'HomeClient' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;



