<template>
  <div class="selectionMenuStepComponentContainer" v-if="renderIt">
    <div class="selectionMenuStepComponentContent">
      <div @click="deleteObject" class="paramIndividuel textBtn"><img class="iconeParam" src="/icones/poubelle.svg" />Supprimer la pièce</div>
      <div v-if="hasStairs" @click="removeStairs" class="paramIndividuel textBtn"><img class="iconeParam" src="/icones/poubelle.svg" />Enlever l'escalier</div>
      <MenuGroup ref="groupMenuEscaliers" class="menuGroup" v-if="!hasStairs" groupTitle="AJOUTER UN ESCALIER"> 
        <template v-slot:content>
          <div class="paramSelection">
            <div class="boussole"><img src="/boussole.png" :style="`transform: rotate(${(4 - decalageCamera) * 90}deg);`"></div>
            <div class="paramSelectionContainer">
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" @click="addStairs(getNewRelativePosition(1))" v-if="canShowStairs[getNewRelativePosition(1)]"><img src="/escaliers cote.png"></div>
                <div class="paramSelectionContainerRowEmptyItem" v-else></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowItem" @click="addStairs(getNewRelativePosition(0))" v-if="canShowStairs[getNewRelativePosition(0)]"><img src="/escaliers cote.png"></div>
                <div class="paramSelectionContainerRowEmptyItem" v-else></div>
                <div class="paramSelectionContainerRowItemCenter1"> Boite</div>
                <div class="paramSelectionContainerRowItem" @click="addStairs(getNewRelativePosition(2))" v-if="canShowStairs[getNewRelativePosition(2)]" ><img src="/escaliers cote.png"></div>
                <div class="paramSelectionContainerRowEmptyItem" v-else></div>
              </div>
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" @click="addStairs(getNewRelativePosition(3))" v-if="canShowStairs[getNewRelativePosition(3)]"><img src="/escaliers cote.png"></div>
                <div class="paramSelectionContainerRowEmptyItem" v-else></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
            </div>
          </div>
        </template>
      </MenuGroup>
      <MenuGroup ref="groupMenuMurs" class="menuGroup" v-if="!hasStairs" groupTitle="GÉRER LES MURS"> 
        <template v-slot:content>
          <div class="paramSelection2">
            <div class="boussole"><img src="/boussole.png" :style="`transform: rotate(${(4 - decalageCamera) * 90}deg);`"></div>
            <div class="paramSelectionContainer">
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(1),0)"><img :src="liensImagesMurs[getNewRelativePosition(1)][0]"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(1),1)"><img :src="liensImagesMurs[getNewRelativePosition(1)][1]"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(1),2)"><img :src="liensImagesMurs[getNewRelativePosition(1)][2]"></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
              <div class="paramSelectionContainerRow middleRow">
                <div class="paramSelectionContainerRowItemColumn">
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(0),2)"><img :src="liensImagesMurs[getNewRelativePosition(0)][2]"></div>
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(0),1)"><img :src="liensImagesMurs[getNewRelativePosition(0)][1]"></div>
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(0),0)"><img :src="liensImagesMurs[getNewRelativePosition(0)][0]"></div>
                </div>
                <div class="paramSelectionContainerRowItemCenter2"> Boite</div>
                <div class="paramSelectionContainerRowItemColumn">
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(2),0)"><img :src="liensImagesMurs[getNewRelativePosition(2)][0]"></div>
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(2),1)"><img :src="liensImagesMurs[getNewRelativePosition(2)][1]"></div>
                  <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(2),2)"><img :src="liensImagesMurs[getNewRelativePosition(2)][2]"></div>
                </div>
              </div>
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(3),2)"><img :src="liensImagesMurs[getNewRelativePosition(3)][2]"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(3),1)"><img :src="liensImagesMurs[getNewRelativePosition(3)][1]"></div>
                <div class="paramSelectionContainerRowItem" @click="changeWallState(getNewRelativePosition(3),0)"><img :src="liensImagesMurs[getNewRelativePosition(3)][0]"></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
            </div>
          </div>
        </template>
      </MenuGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Box } from '@/Box/Box';
import MenuGroup from '@/components/MenuGroup.vue';
import Modal from '@/components/Modal.vue';
import { BoxWallState, GetNextBoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { BoxRotationDirection } from '@/Box/BoxRotationDirection';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { Object3DTrueScaleCalculator } from '@/calculators/Object3DTrueScaleCalculator';
import { StairsValidationObject } from '@/validationObjects/StairsValidationObject';
import { StairsRepository } from '@/repositorys/StairsRepository';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { CustomScene } from '@/scene/CustomScene';
import { StairsManager } from '@/Managers/StairsManager';
import { OrbitControls } from 'node_modules/@types/three/examples/jsm/controls/OrbitControls';
import { BoxStairs } from '@/Box/BoxParts/BoxStairs';
import { BoxWallGroup } from '@/Box/BoxParts/BoxWall/BoxWallGroup';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { BoxWallBesideHelper } from "@/helpers/BoxWallBesideHelper";

@Options({
  props: {
    repositorys: Map,
    selectionManager: SelectionManager
  },
  components: {
    Modal,
    MenuGroup
  },
  methods: {
    getNewRelativePosition(oldPosition): number {
      return (oldPosition + this.decalageCamera) % 4;
    },
    deleteObject () {
      EventBus.emit(EventType.boxDeleted, this.selectionManager.getSelectedBox());
    },
    removeStairs () {
      this.scene.deleteStairsFromBox(this.selectionManager.getSelectedBox());
      this.regenerateElements();
    },
    updateDecalageFromCamera () {
      const cameraAngle = Math.round(this.scene.getCamera().rotation.z/Math.PI*2);
      if (cameraAngle >= 0) {
        this.decalageCamera = 4 - cameraAngle;
      }
      else {
        this.decalageCamera = -cameraAngle;
      }
    },
    updateWallsImagesLinks () {
      const wallsGroups = this.selectionManager.getSelectedBox().getWalls() as BoxWallGroup[];
      this.liensImagesMurs = new Array<Array<BoxWallState>>();
      for (let x = 0; x < wallsGroups.length; x++) {
        const wallsGroupItem = wallsGroups[x];
        const liensGroupe = new Array<BoxWallState>();
        for (let y = 0; y < wallsGroupItem.length; y++) {
          const wall = wallsGroupItem[y];
          if (wall.hasForcedState()) {
            liensGroupe.push(wall.getForcedState() as BoxWallState);
          }
          else if (!wall.getIsVisible()) {
            liensGroupe.push(BoxWallState.none);
          } else if (wall.constructor.name === "BoxWindow") {
            liensGroupe.push(BoxWallState.window);
          }
          else {
            liensGroupe.push(BoxWallState.wall);
          }
        }
        this.liensImagesMurs.push(liensGroupe);
      }
    },
    addStairs (side: number) {
      const box = this.selectionManager.getSelectedBox() as Box;
      const stairs = this.stairInstanceReference.getCopy();
      this.stairsManager.prepareStairs(box, stairs);
      stairs.setSideRelativeToBottomBox(side);
      if(this.stairsManager.checkIfStairsCanBePlaced(stairs)) {
        this.scene.addStairsToBoxes(stairs);
      }
      this.regenerateElements();
    },
    regenerateElements () {
      this.updateWallsImagesLinks();
      this.updateStairsChoice();
      this.hasStairs = this.selectionManager.getSelectedBox().getStairs();
      this.$refs.groupMenuEscaliers.forceReRender();
      this.$refs.groupMenuMurs.forceReRender();
    },
    changeWallState (wallGroupNumber: number, wallNb: number) {
     
      let list: Array<string|number> = [];
      let newWallState = GetNextBoxWallState(this.liensImagesMurs[wallGroupNumber][wallNb]);
      const wall = this.selectionManager.getSelectedBox().getWalls()[wallGroupNumber][wallNb] as BoxWall;
      
      list = this.boxWallBesideHelper.getWallStatus(this.selectionManager.getSelectedBox());
      
      const walls = this.selectionManager.getSelectedBox().getWalls()[wallGroupNumber];
      if(newWallState === BoxWallState.window && wall.getRotation() === BoxRotationDirection.Est) {
        newWallState = GetNextBoxWallState(newWallState);
      }

      if( list.length == 0){
        if((newWallState === BoxWallState.none)) {
          newWallState = GetNextBoxWallState(newWallState);
        }
      }else{
        for (const startingWallToRemove of list) {
          if(walls.emplacement != startingWallToRemove){
            if((newWallState === BoxWallState.none)) {
              newWallState = GetNextBoxWallState(newWallState);
            }
          }
        }
      }
    
      wall.setForcedState(newWallState);

      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
      this.regenerateElements();
    },
    updateStairsChoice () {
      const box = this.selectionManager.getSelectedBox() as Box;
      this.stairInstanceReference.setScaleFromBox(Object3DTrueScaleCalculator.getScaleWanted());
      for (let x = 0; x < 4; x++){
        const stairsValidationObject = new StairsValidationObject(x, box.getPosition().clone(), box.getRotation(), this.stairInstanceReference.getSize());
        this.stairsManager.prepareStairsValidationObject(box, stairsValidationObject);
        this.canShowStairs[x] = this.stairsManager.checkIfStairsCanBePlaced(stairsValidationObject, false);
      }
    },
    whenSelectedBoxChange () {
      if (this.selectionManager.isThereABoxSelected()) {
        this.updateStairsChoice();
        this.updateWallsImagesLinks();
        this.renderIt = true;
      }
      else {
        this.renderIt = false;
      }
    },
    initialSetupStairs () { 
      const stairsRepository = this.repositorys.get(StairsRepository.name);
      this.stairInstanceReference = stairsRepository.getCopyByName("stairs1");
    },
    whenSceneLoadIsCompleted () {
      (this.scene.getControls() as OrbitControls).addEventListener('change', this.updateDecalageFromCamera );
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
    EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
    this.initialSetupStairs();
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, (scene) => { this.scene = scene });
    SceneCommunicationBus.whenInstanceIsReady(BoxWallBesideHelper, (boxWallBesideHelper) => { this.boxWallBesideHelper = boxWallBesideHelper });
    SceneCommunicationBus.whenInstanceIsReady(StairsManager, (stairsManager: StairsManager) => { this.stairsManager = stairsManager; });
    EventBus.on(EventType.sceneLoadCompleted, this.whenSceneLoadIsCompleted);
  }
})
export default class SelectionMenuStep3 extends Vue {
  decalageCamera: number = 0;
  canShowStairs: Array<boolean> = new Array<boolean>();
  stairsManager!: StairsManager;
  liensImagesMurs!: Array<Array<BoxWallState>>;
  stairInstanceReference!: BoxStairs;
  renderIt = false;
  scene!: CustomScene;
  boxWallBesideHelper!: BoxWallBesideHelper;
  hasStairs = false;
}
</script>

<style scoped>
</style>
