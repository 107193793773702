import { Box } from "@/Box/Box";
import { BoxRotationDirection, getNextCounterClockwiseDirection } from "@/Box/BoxRotationDirection";
import { BoxSideRelativeToWindow } from "@/Box/BoxSideRelativeToWindow";
import { Vector3 } from "three";

export class StairsValidationObject {
    public boxOnTop: Box | undefined;
    public boxOnBottom: Box | undefined;
    public sideRelativeToBottomBox: BoxSideRelativeToWindow;
    public position!: Vector3;
    public rotation!: BoxRotationDirection;
    public size: Vector3;

    public constructor(sideRelativeToBottomBox: BoxSideRelativeToWindow, boxPosition: Vector3, boxRotation: BoxRotationDirection, size: Vector3) {
        this.sideRelativeToBottomBox = sideRelativeToBottomBox;
        this.size = size;
        this.setPositionFromBoxPosition(boxPosition, boxRotation);
    }

    public getStairsRotationRelativeToBox (): BoxRotationDirection {
        let rotationIncrementation = 0
        switch (this.sideRelativeToBottomBox) {
            case BoxSideRelativeToWindow.Droite : {
                rotationIncrementation = 3;
                break;
            }
            case BoxSideRelativeToWindow.Avant : {
                rotationIncrementation = 0;
                break;
            }
            case BoxSideRelativeToWindow.Gauche : {
                rotationIncrementation = 1;
                break;
            }
            case BoxSideRelativeToWindow.Derrière : {
                rotationIncrementation = 2;
                break;
            }
        }
        return getNextCounterClockwiseDirection((this.boxOnBottom as Box).getRotation(), rotationIncrementation) as BoxRotationDirection
    }

    private getRotationConsideringStairsSide (oldRotation: BoxRotationDirection) {
        let rotationIncrementation = 0
        switch (this.sideRelativeToBottomBox) {
            case BoxSideRelativeToWindow.Droite : {
                rotationIncrementation = 0;
                break;
            }
            case BoxSideRelativeToWindow.Avant : {
                rotationIncrementation = 1;
                break;
            }
            case BoxSideRelativeToWindow.Gauche : {
                rotationIncrementation = 2;
                break;
            }
            case BoxSideRelativeToWindow.Derrière : {
                rotationIncrementation = 3;
                break;
            }
        }
        return getNextCounterClockwiseDirection(oldRotation, rotationIncrementation) as BoxRotationDirection
    }


    private setPositionFromBoxPosition (boxPosition: Vector3, boxRotation: BoxRotationDirection) {
        const newRotationDirection = this.getRotationConsideringStairsSide(boxRotation);
        this.rotation = newRotationDirection;
        const newPosition = boxPosition;
        let otherBoxPosition = boxPosition;
        if(this.boxOnTop != undefined) {
            otherBoxPosition = (this.boxOnTop as Box).getPosition();
        }
        const decalage = new Vector3();
        let isDecalageFromBottomBox = false;
        switch (newRotationDirection) {
            case BoxRotationDirection.Est: {
                if (newPosition.z > otherBoxPosition.z) {
                    decalage.z -= newPosition.z - otherBoxPosition.z;
                    isDecalageFromBottomBox = true;
                }
                newPosition.z -= 0.25 + this.size.x/2;
                break;
            }
            case BoxRotationDirection.Ouest:{
                if (newPosition.z < otherBoxPosition.z) {
                    decalage.z += otherBoxPosition.z - newPosition.z;
                    isDecalageFromBottomBox = true;
                }
                newPosition.z += 6 - this.size.x/2;
                break;
            }
            case BoxRotationDirection.Sud:{
                if (newPosition.x < otherBoxPosition.x) {
                    decalage.x += otherBoxPosition.x - newPosition.x;
                    isDecalageFromBottomBox = true;
                }
                newPosition.x += 6 - this.size.x/2;
                break;
            }
            case BoxRotationDirection.Nord:{
                if (newPosition.x > otherBoxPosition.x) {
                    decalage.x -= newPosition.x - otherBoxPosition.x;
                    isDecalageFromBottomBox = true;
                }
                newPosition.x -= 0.5 + this.size.x/2;
                break;
            }
        }
        newPosition.add(decalage);
        newPosition.y += this.size.y/2;
        this.position = newPosition;
    }
}