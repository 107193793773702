import { BoxFloor } from "@/Box/BoxParts/BoxFloor";
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { BoxHelper } from "three";
import floors from '../../configs/floor.json';
import { Repository } from "./Repository";

export class FloorRepository extends Repository<BoxFloor> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (floors as any[]).length;
        floors.forEach(floor => {
            loader.loadObj('models/Planchers bas/'+floor.filename).then(wallInstance => {
                wallInstance.position.copy(this.object3DTruePositionCalculator.getTruePosition(wallInstance));
                const hitbox = new BoxHelper(wallInstance, 0xff0000);
                hitbox.update();
                this.objectMap.set(floor.name, new BoxFloor(wallInstance, hitbox, floor.name));
                this.verifyIfEndLoading();
            })
        })
    }

    public getCopyByName(name: string): BoxFloor | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}