import { BoxWall } from "@/Box/BoxParts/BoxWall/BoxWall";
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { BoxHelper } from "three";
import walls from '../../configs/walls.json';
import { Repository } from "./Repository";

export class WallRepository extends Repository<BoxWall> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (walls as any[]).length;
        walls.forEach(wall => {
            loader.loadObj('models/'+wall.location).then(wallInstance => {
                wallInstance.position.copy(this.object3DTruePositionCalculator.getTruePosition(wallInstance));
                const hitbox = new BoxHelper(wallInstance, 0xff0000);
                hitbox.update();
                this.objectMap.set(wall.name, new BoxWall(wallInstance, hitbox, wall.name, wall.size));
                this.verifyIfEndLoading();
            })
        })
    }

    public getCopyByName(name: string): BoxWall | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}