import { Vector3 } from "three";

export class SunRotationCalculator {

    private ifCountNightInDayCalculation: boolean;

    public constructor (ifCountNightInDayCalculation: boolean) {
        this.ifCountNightInDayCalculation = ifCountNightInDayCalculation;
    }

    public calculateRotationByDayPercentage(dayPercentage: number): Vector3 {
        const rotation = new Vector3();
        rotation.y = (Math.PI*2) * (100 - dayPercentage/2/100) + Math.PI/2*3
        rotation.x = Math.PI/2*3;
        return rotation;
    }
}