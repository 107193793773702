
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ProjectManager } from "@/Managers/ProjectManager";
import StepBanner from "../components/StepBanner.vue";
import { ProjectRapport } from "@/project/ProjectRapport";
import { Project } from "@/project/Project";
import { LoadSaveHelper } from "@/helpers/LoadSaveHelper";

@Options({
   data(){
    return {
      erreurs: false,
    };
  },
  created(){
    this.loadSaveHelper = new LoadSaveHelper();
    this.requestUserDetails().then(utilisateur => {
      this.currentUser = ((utilisateur.data).user);

      this.rapport.prenom = this.currentUser.prenom;
      this.rapport.nom = this.currentUser.nom;
      this.rapport.email = this.currentUser.email;
    });
  },
  components: {
    StepBanner
  },
  methods: {
    validation(): boolean {
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },

    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },

    send() {
      this.erreurs = true;
      if (this.validation()) {
        this.desactiveButton();

        //const formData = new FormData();
        const rapportDTO = new ProjectRapport();
        rapportDTO.adresse = this.rapport.adresse;
        rapportDTO.ville = this.rapport.ville;
        rapportDTO.province = this.rapport.province;
        rapportDTO.telephone = this.rapport.telephone;
        rapportDTO.budget = this.rapport.budget;
        rapportDTO.finance = this.rapport.finance;
        rapportDTO.terrain = this.rapport.terrain;
        rapportDTO.presentation = this.rapport.presentation;
        ProjectManager.getProject().rapport = rapportDTO;
        this.loadSaveHelper.saveProject(this.save, true).then(() => {
          this.activeButton();
          ProjectManager.incrementSteps();
        });


        // formData.append("adresse", this.rapport.adresse);
        // formData.append("ville", this.rapport.ville);
        // formData.append("province", this.rapport.province);
        // formData.append("telephone", this.rapport.telephone);
        // formData.append("budget", this.rapport.budget);
        // formData.append("finance", this.rapport.finance);
        // formData.append("terrain", this.rapport.terrain);
        // formData.append("presentation", this.rapport.presentation);

        // const object = {};
        // formData.forEach((value, key) => {
        //   object[key] = value;
        // });
        // const json = object;
        // this.addRapport(json)
        //   .then(() => {
        //     ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
        //       message:
        //         "Félicitations! Formulaire enregistré avec succès",
        //       duration: 5000,
        //       unique:true
        //     });
            
        //     ProjectManager.setCompletedStep(1);
        //     ProjectManager.incrementSteps();
        //   })
        //   .catch(() => {
        //     ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
        //       message: "Erreur lors du sauvegarde du formulaire !",
        //       duration: 3000,
        //       unique: true
        //     });
        //   });
      }
    },
  },
  mounted () {
    if (ProjectManager.getProject().id === null) {
      ProjectManager.setProject(new Project());
      ProjectManager.setCurrentStep(1);
    }
    if(ProjectManager.getProject().rapport !== null) {
      const rapportDTO = ProjectManager.getProject().rapport as ProjectRapport;
      this.rapport.adresse = rapportDTO.adresse;
      this.rapport.ville = rapportDTO.ville;
      this.rapport.province = rapportDTO.province;
      this.rapport.telephone = rapportDTO.telephone;
      this.rapport.budget = rapportDTO.budget;
      this.rapport.finance = rapportDTO.finance;
      this.rapport.terrain = rapportDTO.terrain;
      this.rapport.presentation = rapportDTO.presentation;
    }
  }
})

export default class FormCreation extends Vue {

    @Action("save/saveProject") save: any;
    loadSaveHelper!: LoadSaveHelper;

    currentRapport = {};

    rules = {
      prenom: {required},
      nom: {required },
      email: {required, email},
      adresse: { required },
      ville: { required },
      province: { required },
      telephone: { required },
      budget: { required },
      presentation: { required },
      finance: { required },
      terrain: { required }
    };

    rapport = reactive({
      prenom: "",
      nom: "",
      email: "",
      adresse: "",
      ville: "",
      province: "",
      telephone: "",
      budget: "",
      presentation: "",
      finance: "",
      terrain: "",
    });

    @Action("requestUserDetails", { namespace: "user" }) requestUserDetails: any;

    vv = useVuelidate(this.rules, {
      prenom: toRef(this.rapport, "prenom"),
      nom: toRef(this.rapport, "nom"),
      email: toRef(this.rapport, "email"),
      adresse: toRef(this.rapport, "adresse"),
      ville: toRef(this.rapport, "ville"),
      province: toRef(this.rapport, "province"),
      telephone: toRef(this.rapport, "telephone"),
      budget: toRef(this.rapport, "budget"),
      presentation: toRef(this.rapport, "presentation"),
      finance: toRef(this.rapport, "finance"),
      terrain: toRef(this.rapport, "terrain"),
    });

    submitted = false;

}


