import materials from './../../configs/materials.json';
import { CustomEnum } from './CustomEnum';
import { CustomEnumLibrairy } from './CustomEnumLibrairy';

export class Material extends CustomEnum {
    public static _initialize() {
        CustomEnumLibrairy.registerNewEnum(Material, Material.getMaterialsPossibleEntrys());
    }

    private static getMaterialsPossibleEntrys (): Material[] {
        const newList = new Array<Material>();
        materials.forEach((mat) => {newList.push(new Material(mat.name, mat.name, mat.preview));});
        return newList;
    }
}
Material._initialize();