import { Box3, BoxHelper, Group, Object3D, Vector3 } from "three";

export class Collision {
    private hitbox: BoxHelper;
    private objectBasedOn:  Group | Object3D;
    private position: Vector3;

    public constructor(objectBasedOn: Object3D | null = null) {
        if (objectBasedOn == null) {
            this.objectBasedOn = new Group();
        }
        else {
            this.objectBasedOn = objectBasedOn;
        }
        this.hitbox = new BoxHelper(this.objectBasedOn);
        this.position = new Vector3();
    }

    public setIsVisible(value: boolean) {
        this.hitbox.visible = value;
    }

    public getHitbox(): BoxHelper {
        this.hitbox.update();
        return this.hitbox;
    }

    public update() {
        this.hitbox.update();
    }

    public setFromObject (object: Object3D) {
        this.hitbox.setFromObject(object);
        this.objectBasedOn = object;
    }

    public addObjectsInHitbox (object: Object3D[]) {
        if (this.objectBasedOn.type == "Group") {
            object.forEach(obj => {
                this.objectBasedOn.add(obj.clone());
            });
            this.hitbox.setFromObject(this.objectBasedOn);
            this.hitbox.update();
        }
    }
    
    public resetObjectsInHitbox() {
        if (this.objectBasedOn.type == "Group") {
            this.objectBasedOn.clear();
        }
        else {
            this.objectBasedOn = new Object3D();
        }
        this.hitbox.setFromObject(this.objectBasedOn);
    }

    public setPosition(value: Vector3) {
        this.position.copy(value);
    }

    public getPosition(): Vector3 {
        return this.position;
    }

    public getSize (): Vector3 {
        this.hitbox.update();
        this.hitbox.geometry.computeBoundingBox();
        const hitboxValue = this.hitbox.geometry.boundingBox ?? new Box3();
        return hitboxValue.max.sub(hitboxValue.min);
    }

    public checkIfBesideWithOtherObject (otherObjectPosition: Vector3, otherObjectSize: Vector3, precision = 3): boolean {
        const thisPosition = this.getVector3CloneWithPrecision(this.getPosition(), precision);
        const otherPosition = this.getVector3CloneWithPrecision(otherObjectPosition, precision);
        const thisSize = this.getVector3CloneWithPrecision(this.getSize(), precision);
        const otherSize = this.getVector3CloneWithPrecision(otherObjectSize, precision);
        const onX = thisPosition.x + thisSize.x == otherPosition.x || thisPosition.x == otherPosition.x + otherSize.x;
        if (onX) {
            const inZ = (thisPosition.z + thisSize.z >= otherPosition.z && thisPosition.x <= otherPosition.z + otherSize.z) || (thisPosition.z <= otherPosition.z && thisPosition.z + thisSize.z >= otherPosition.z)
            if (inZ) {
                return true;
            }
        }        
        const onZ = thisPosition.z + thisSize.z == otherPosition.z || thisPosition.z == otherPosition.z + otherSize.z;
        if(onZ) {      
            const inX = (thisPosition.x + thisSize.x >= otherPosition.x && thisPosition.x <= otherPosition.x + otherSize.x) || (thisPosition.x <= otherPosition.x && thisPosition.x + thisSize.x >= otherPosition.x);
            if (inX) {
                return true;
            }

        }
        return false;
    }

    public getIfExactlyAlignWithOtherObject (otherObjectPosition: Vector3, otherObjectSize: Vector3, precision = 3) {
        const thisPosition = this.getVector3CloneWithPrecision(this.getPosition(), precision);
        const otherPosition = this.getVector3CloneWithPrecision(otherObjectPosition, precision);
        const thisSize = this.getVector3CloneWithPrecision(this.getSize(), precision);
        const otherSize = this.getVector3CloneWithPrecision(otherObjectSize, precision);
        const sameX = thisPosition.x == otherPosition.x;
        const sameZ = thisPosition.z == otherPosition.z;
        if (sameX) {
            const alignZ = thisPosition.z + thisSize.z == otherPosition.z || thisPosition.z == otherPosition.z + otherSize.z;
            if (alignZ) {
                return true;
            }
        }        
        if(sameZ) {      
            const alignX = thisPosition.x + thisSize.x == otherPosition.x || thisPosition.x == otherPosition.x + otherSize.x;
            if (alignX) {
                return true;
            }

        }
        return false;
    }

    private getVector3CloneWithPrecision (vector: Vector3, precision: number) {
        const newVector = vector.clone();
        newVector.multiplyScalar(Math.pow(2,precision)).round();
        return newVector;
    }

    public checkIFCollideWithOtherSceneObjectNotIncludingBorder(otherObjectPosition: Vector3, otherObjectSize: Vector3, considerOnly: CollisionConsideredAxys = { x: true, y: true, z: true}, precision = 3): boolean {
        const thisPosition = this.getVector3CloneWithPrecision(this.position, precision);
        const otherPosition = this.getVector3CloneWithPrecision(otherObjectPosition, precision);
        const thisSize = this.getVector3CloneWithPrecision(this.getSize(), precision);
        const otherSize = this.getVector3CloneWithPrecision(otherObjectSize, precision);
        let colisionX = !considerOnly.x;
        let colisionY = !considerOnly.y;
        let colisionZ = !considerOnly.z;
        if (considerOnly.x) {
            if (thisPosition.x > otherPosition.x) {
                if (thisPosition.x < otherPosition.x + otherSize.x) {
                    colisionX = true;
                }
            }
            else {
                if (thisPosition.x + thisSize.x > otherPosition.x) {
                    colisionX = true;
                }
            }
        }
        if (considerOnly.y) {
            if (thisPosition.y > otherPosition.y) {
                if (thisPosition.y < otherPosition.y + otherSize.y) {
                    colisionY = true;
                }
            }
            else {
                if (thisPosition.y + thisSize.y > otherPosition.y) {
                    colisionY = true;
                }
            }
        }
        if (considerOnly.z) {
            if (thisPosition.z > otherPosition.z) {
                if (thisPosition.z < otherPosition.z + otherSize.z) {
                    colisionZ = true;
                }
            }
            else {
                if (thisPosition.z + thisSize.z > otherPosition.z) {
                    colisionZ = true;
                }
            }
        }
        return colisionX && colisionY && colisionZ;
    }

    public checkIFCollideWithOtherSceneObject (otherObjectPosition: Vector3, otherObjectSize: Vector3,  considerOnly: CollisionConsideredAxys = { x: true, y: true, z: true}, precision = 3): boolean {
        const thisSize = this.getVector3CloneWithPrecision(this.getSize(), precision);
        const otherSize = this.getVector3CloneWithPrecision(otherObjectSize, precision);
        const thisPosition = this.getVector3CloneWithPrecision(this.position, precision);
        const otherPosition = this.getVector3CloneWithPrecision(otherObjectPosition, precision);
        let colisionX = !considerOnly.x;
        let colisionY = !considerOnly.y;
        let colisionZ = !considerOnly.z;
        if (considerOnly.x) {
            if (thisPosition.x >= otherPosition.x) {
                if (thisPosition.x <= otherPosition.x + otherSize.x) {
                    colisionX = true;
                }
            }
            else {
                if (thisPosition.x + thisSize.x > otherPosition.x) {
                    colisionX = true;
                }
            }
        }
        if (considerOnly.y) {
            if (thisPosition.y >= otherPosition.y) {
                if (thisPosition.y <= otherPosition.y + otherSize.y) {
                    colisionY = true;
                }
            }
            else {
                if (thisPosition.y + thisSize.y > otherPosition.y) {
                    colisionY = true;
                }
            }
        }
        if (considerOnly.z) {
            if (thisPosition.z >= otherPosition.z) {
                if (thisPosition.z <= otherPosition.z + otherSize.z) {
                    colisionZ = true;
                }
            }
            else {
                if (thisPosition.z + thisSize.z > otherPosition.z) {
                    colisionZ = true;
                }
            }
        }
        return colisionX && colisionY && colisionZ;
    }
}

interface CollisionConsideredAxys {
    x: boolean;
    y: boolean;
    z: boolean;
}