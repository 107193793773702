import roomType from '../../configs/roomType.json';
import { CustomEnum } from './CustomEnum';
import { CustomEnumLibrairy } from './CustomEnumLibrairy';

export class RoomType extends CustomEnum {
    public static _initialize() {
        CustomEnumLibrairy.registerNewEnum(RoomType, RoomType.getMaterialsPossibleEntrys());
    }

    private static getMaterialsPossibleEntrys (): RoomType[] {
        const newList = new Array<RoomType>();
        roomType.forEach((room) => {newList.push(new RoomType(room.name, room.name, room.preview));});
        return newList;
    }
}
RoomType._initialize();