
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { ToastEmitterOptionsPosition } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsPosition";
import Modal from '@/components/Modal.vue';
import { load } from 'recaptcha-v3'


const namespace = "auth";


@Options({
  components: {
    Modal
  },
  mounted(){
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
    useRecaptchaNet: true
    }).then((recaptcha) => {
      this.recaptcha = recaptcha;
    })
  },
  methods: {
    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },

    login() { 
        this.recaptcha.execute("login").then((token) => {
          this.submitted = true;
          this.errors = true;
          this.vv.$touch();
          if (this.vv.$invalid) {
            return;
          }
          this.desactiveButton();
          this.user.token = token;
          this.actionLogin(this.user)
            .then(() => {
              this.actionRequestUserDetails().then(() => {
                location.reload();
              });
            })
            .catch((erreur) => {
              let message = "Une erreur s'est produite";
              switch (erreur.response.data) {
                case "Unauthorised": {
                  message = "Courriel ou mot de passe invalide. Veuillez réessayer";
                  break;
                }
                case "Not Human": {
                  message = "Il y a un problème avec recaptcha, veuillez vérifier si vous le bloquez.";
                  break;
                }
              }
              ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: message, duration: 5000, position: ToastEmitterOptionsPosition.bottom, unique: true});
              this.activeButton();
            });
           
        });
    }
  }
})

export default class Home extends Vue {
  user = reactive({
    email: "",
    password: "",
  });

  errors = false;

  @Action("login", { namespace }) actionLogin: any;
  @Action("requestUserDetails", { namespace }) actionRequestUserDetails: any;

  rules = {
    email: { required, email },
    password: { required },
  };

  vv = useVuelidate(this.rules, {
    email: toRef(this.user, "email"),
    password: toRef(this.user, "password"),
  });

  submitted = false;

  recaptcha = null;
  
}
