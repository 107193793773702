import { Object3D } from 'three';
import { ColladaLoader, Collada } from 'three/examples/jsm/loaders/ColladaLoader.js';
import { CustomLoader } from './CustomLoader';


export class CustomColladaLoader implements CustomLoader {
    public loadObj(lien: string) {
        return new Promise<Object3D>((resolve, reject) => {
            const loader = new ColladaLoader();
            loader.load( "/"+ lien,
            (objet: Collada) => this.onLoadFunction(objet, resolve),
            function () {
                // eslint-disable-next-line
                //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            function (error) {
                console.log("unable to parse " + lien);
                reject(error);
            });
        });
    }

    private onLoadFunction(colladaObject: Collada, resolve: Function) {
        resolve(colladaObject.scene);
    }
    
}