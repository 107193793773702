<template>
  <div class="sideMenuContainer">
    <div v-if="currentStep !== 3" class="infos">
      <p>Prix: {{ infoProjet.prix }}$ <br>
      Performance carbone: {{ infoProjet.performanceEcologique }}</p>
    </div>
    <MenuOptionsProjet />
    <!-- <SyntheseMenu v-show="currentStep === 7" :infoProjet="infoProjet" /> -->
    <div class="blocSelectionnee" ref="blocSelectionnee" v-show="renderSelectedBox">
      <h1 class="menuTitle">Pièce sélectionné</h1>
      <div v-if="renderSelectedBox" class="paramIndividuel">{{ getParam('boxName').keyDisplayName }}: 
        <input type="text" v-if="currentStep !== 7" :value="getParam('boxName').value" @input="(event) => valueChanged(event.target.value, 'boxName')">
        <span class="infoSynthese" v-else>{{ getParam('boxName').value }}</span>
      </div>
      <!-- <SelectedBoxMenuParameter v-for="param in params" v-bind:key="param.key" :param="param" :selectedBox="selectedBox" :object3DFactory="object3DFactory" /> -->
      <SelectionMenuStep3 :selectionManager="selectionManager" :repositorys="repositorys" v-show="currentStep === 3" />
      <SelectionMenuStep4 :selectionManager="selectionManager" v-if="paramsGenerated" :params="params" v-show="currentStep === 4" />
      <SelectionMenuStep5 :selectionManager="selectionManager" v-if="paramsGenerated" :params="params" v-show="currentStep === 5" />
      <SelectionMenuStep6 :selectionManager="selectionManager" v-if="paramsGenerated" :params="params" v-show="currentStep === 6" />
      <!-- <SelectionMenuStep7 :selectionManager="selectionManager" v-show="currentStep === 7" /> -->
      <div class="btnsMenuSelectionContainer">
        <input type="button" v-if="currentStep >= 6" value="Voir de l'intérieur" class="btnMenuSelection" @click="seeFromInside">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { EventBus } from '../events/EventBus';
import { EventType } from '../events/EventType';
import { Box } from '@/Box/Box';
import { ObjectParameter, ObjectParametersAsMapGetter } from '../helpers/ObjectParametersAsMapGetter';
import SelectedBoxMenuParameter from '@/components/SelectedBoxMenuParameter.vue';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import MenuOptionsProjet from '@/components/MenuOptionsProjet.vue';
import SyntheseMenu from '@/components/SyntheseMenu.vue';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { CustomScene } from '@/scene/CustomScene';
import { FirstPersonViewManager } from '@/Managers/FirstPersonViewManager';
import Modal from '@/components/Modal.vue';
import SelectionMenuStep3 from '@/components/SelectionMenuBySteps/SelectionMenuStep3.vue';
import SelectionMenuStep4 from '@/components/SelectionMenuBySteps/SelectionMenuStep4.vue';
import SelectionMenuStep5 from '@/components/SelectionMenuBySteps/SelectionMenuStep5.vue';
import SelectionMenuStep6 from '@/components/SelectionMenuBySteps/SelectionMenuStep6.vue';
import SelectionMenuStep7 from '@/components/SelectionMenuBySteps/SelectionMenuStep7.vue';
import { ProjectManager } from '@/Managers/ProjectManager';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { StairsManager } from '@/Managers/StairsManager';

@Options<SideMenu>({
  props: {
    repositorys: Map,
    object3DFactory: Object3DFromBoxFactory,
    selectionManager: SelectionManager,
    infoProjet: SceneInfoProjet
  },
  components: {
    SelectedBoxMenuParameter,
    MenuOptionsProjet,
    Modal,
    SelectionMenuStep3,
    SelectionMenuStep4,
    SelectionMenuStep5,
    SelectionMenuStep6,
    SelectionMenuStep7,
    SyntheseMenu
  },
  methods: {
    seeFromInside () {
      this.firstPersonViewManager.seeInsideBox(this.selectionManager.getSelectedBox());
    },
    getParam (name: string): ObjectParameter {
      return this.params.get(name);
    },
    updateLocalSelectedBox() {
      this.selectedBox = undefined;
      if (this.selectionManager.isThereABoxSelected()) {
        this.selectedBox = this.selectionManager.getSelectedBox();
        this.updateParamOfSelectedObject();
      }
      else {
        this.renderSelectedBox = false;
      }
      this.$forceUpdate();
    },
    updateStepsRelatedObjects () {
      this.updateLocalSelectedBox();
      this.currentStep = ProjectManager.getCurrentStep();
    },
    updateParamOfSelectedObject() {
      this.params = (this.objectParametersAsMapGetter.getParameters(this.selectedBox) as Map<string, ObjectParameter>);
      this.paramsGenerated = true;
      if (this.params.size === 0) {
        this.renderSelectedBox = false;
      }
      else {
        this.renderSelectedBox = true;
      }
      // const rotation = params[0];
      // const boxName = params[2];
      // params[2] = rotation;
      // params[0] = boxName;
    },
    updateSelectedBox() {
      this.selectedBox = this.selectionManager.getSelectedBox();
      this.updateParamOfSelectedObject();
      this.$forceUpdate();
    },
    valueChanged (item: any, paramName) {
      if (item.key !== undefined) {
        this.selectedBox[this.getParam(paramName).editable.setter](item.key);
      }
      else {
        this.selectedBox[this.getParam(paramName).editable.setter](item);
      }

      if(this.getParam(paramName).editable.needRefresh) {
        //const newObj3D = this.object3DFactory.getNewObject3DFromBox(this.selectedBox);
        // EventBus.emit(EventType.beforeBoxRefreshed, this.selectedBox);
        // this.selectedBox[editableParameterEntry.refreshMethodName as string](newObj3D);
        EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectedBox);
        this.$forceUpdate();
      }
    },
    setupSceneWhenReady (scene: CustomScene) {
      this.scene = scene;
    },
    setupFirstPersonViewManagerWhenReady (firstPersonViewManager: FirstPersonViewManager) {
      this.firstPersonViewManager = firstPersonViewManager;
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.updateLocalSelectedBox);
    EventBus.on(EventType.removedSelectedBox, this.updateLocalSelectedBox);
    EventBus.on(EventType.stepChanged, this.updateStepsRelatedObjects);
    this.updateStepsRelatedObjects();
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady);
    SceneCommunicationBus.whenInstanceIsReady(StairsManager, (stairsManager: StairsManager) => { this.stairsManager = stairsManager; });
    SceneCommunicationBus.whenInstanceIsReady(FirstPersonViewManager, this.setupFirstPersonViewManagerWhenReady);
  }
})
export default class SideMenu extends Vue {
  selectedBox?: Box;
  renderSelectedBox = false;
  objectParametersAsMapGetter: ObjectParametersAsMapGetter = new ObjectParametersAsMapGetter();
  params?: Map<string, ObjectParameter>;
  scene!: CustomScene;
  firstPersonViewManager!: FirstPersonViewManager;
  currentStep: number = 3;
  paramsGenerated = false;
}
</script>

<style lang="scss" scoped>
  ::v-deep(.menuGroup) {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  ::v-deep(.selectionMenuStepComponentContainer) {
    width: 100%;
  }
  ::v-deep(.selectionMenuStepComponentContent) {
    width: 100%;
  }
  ::v-deep(.textBtn) {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: flex-start !important;
  }
  ::v-deep(.iconeParam){
    width: 20px;
    margin-right: 1%;
  }
  ::v-deep(input[type=checkbox]) {
    background: #B0CC78 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
  }
  ::v-deep(.infoSynthese) {
    color: black;
  }
  ::v-deep(.btnMenuSelection){
    background-color:#A8CE6C!important;
    border-radius: 50px;
    padding:10px;
    margin-left:10px;
    margin-bottom:10px;
    font-family: roboto;
    width:150px;
    color: white;
    border:none;
  }
  ::v-deep(.btnMenuSelection:hover){
    background-color:#a3a3a3!important;
    cursor:pointer;
  }
  ::v-deep(.btnsMenuSelectionContainer) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  ::v-deep(.boussole) {
    margin-top: 2px;
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  ::v-deep(.boussole) img {
    width: 4vh;
    height: 4vh;
  }
  ::v-deep(.paramSelection) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 30vh;
  } 
  ::v-deep(.paramSelection2) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 40vh;
  }
  ::v-deep(.paramSelectionContainer) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
  }
  ::v-deep(.paramSelectionContainerRow) {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  ::v-deep(.paramSelectionContainerRowItem) {
    width: 6vh;
    height: 6vh;
    margin: 0.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep(.paramSelectionContainerRowItem:hover) {
    background-color: #A8CE6C;
  }
  ::v-deep(.paramSelectionContainerRowItem) > img {
    width: 90%;
    height: 90%;
  }
  ::v-deep(.paramSelectionContainerRowItem) > img:hover {
  -webkit-filter: invert(1);
  filter: invert(1);
  }
  ::v-deep(.paramSelectionContainerRowEmptyItem) {
    width: 6vh;
    height: 6vh;
    margin: 0.5vh;
  }
  ::v-deep(.paramSelectionContainerRowItemColumn) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ::v-deep(.paramSelectionContainerRowItemCenter2) {
    width: calc(18vh - 10px);
    height: calc(18vh - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size:5vh;
    border: 5px solid black;
  }
  ::v-deep(.paramSelectionContainerRowItemCenter1) {
    width: calc(6vh - 10px);
    height: calc(6vh - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size:2vh;
    border: 5px solid black;
    margin: 0.5vh;
  }
  ::v-deep(.paramIndividuel) {
    color: #7E7E7E;
    padding:2.5%;
    font-size:16px;
    display: flex;
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    font-family: roboto;
  }
  // ::v-deep(.categorieName:hover){
  //   background-color:#a3a3a3!important;
  //   cursor:pointer;
  // }
  // ::v-deep(.categorieName){
  //   user-select: none;
  //   background-color:#A8CE6C!important;
  //   font-family: roboto;
  //   text-align:center;
  //   color: white;
  //   padding-left: 5px;
  //   font-size:16px;
  //   padding-top: 10px;
  //   padding-bottom: 10px;
  // }
  // ::v-deep(.categoryContent) {
  //   border: 1px solid #a3a3a3;
  // }
  ::v-deep(.carousselItemPreview) {
    height: 100px;
  }
  ::v-deep(.carousselItem) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep(.menuTitle) {
    user-select: none;
    background-color:#A8CE6C!important;
    font-family: roboto;
    color: white;
    padding-left: 10px;
    font-size:22px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    margin-bottom: 1vh;
  }
</style>

<style scoped>
  .sideMenuContainer {
    width: 100%;
    position: relative;
  }
  .blocSelectionnee {
    width: 90%;
    font-family: roboto;
    margin-top:5%;
  }
  .middleRow {
    flex-grow: 3;
  }
  .infos {
    background-color: black;
    color: white;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
