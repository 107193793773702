import { Box } from '@/Box/Box';

export class PerformanceCalculator {

    public calculate (boxes: Box[]): number | undefined {
        let performance: number = 0;
        for (let i = 0; i < (boxes).length ; i++) {
            performance += boxes[i].getPerformance();
        } 
        return performance;
    }

}