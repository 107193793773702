<template>
  <div id="corpsPage">
    <div id="conteneurMillieu">
      <StepBanner />
      <div id="conteneurBas">
        <div id="conteneurDroit">
        </div>
        <div id="conteneurGauche">
          <div id="GaucheHaut">
            <h2>Parlez-nous de vous et de votre projet</h2>
          </div>
          <form @submit.prevent="send" :class="erreurs ? 'erreurs' : false" >
            <div id="Ligne1">
              <div class="LigneGauche">
                <label for="prenom">Prénom<span class="etoileform"> *</span></label>
                <input type="text" id="prenom" v-model="vv.prenom.$model" name="prenom" disabled>
              </div>
              <div class="LigneDroit">
                <label for="nom">Nom<span class="etoileform"> *</span></label>
                <input type="text" id="nom" v-model="vv.nom.$model" name="nom"  disabled> 
              </div>
            </div>
            <div id="Ligne2">
              <div class="LigneGauche3">
                <label for="adresse">Adresse<span class="etoileform"> *</span></label>
                <input type="text" id="adresse" v-model="vv.adresse.$model" name="adresse" required @invalid="send">
              </div>
              <div class="LigneMillieu3">
                <label for="ville">Ville<span class="etoileform"> *</span></label>
                <input type="text" id="ville" v-model="vv.ville.$model" name="ville" required @invalid="send">
              </div>
              <div class="LigneDroite3">
                <label for="province">Province<span class="etoileform"> *</span></label>
                <select name="province" id="province" v-model="vv.province.$model" required @invalid="send">
                  <option value="alberta">Alberta</option>
                  <option value="colombiebritannique">Colombie-Britannique</option>
                  <option value="Ileprinceedouard">Île-du-Prince-Édouard</option>
                  <option value="Manitoba">Manotiba</option>
                  <option value="NouveauBrunswick">Nouveau-Brunswick</option>
                  <option value="Nouvelleecosse">Nouvelle-Écosse</option>
                  <option value="ontario">Ontario</option>
                  <option value="quebec">Québec</option>
                  <option value="saskatchewan">Saskatchewan</option>
                  <option value="terreneuveetlabrador">Terre-Neuve-et-Labrador</option>
                </select>
              </div>
            </div>
            <div id="Ligne4">
              <div class="LigneGauche">
                <label for="telephone">Téléphone<span class="etoileform"> *</span></label>
                <input type="tel" id="telephone" v-model="vv.telephone.$model" name="telephone" required @invalid="send">
              </div>
              <div class="LigneDroit">
                <label for="email">Courriel<span class="etoileform"> *</span></label>
                <input type="email" id="email" v-model="vv.email.$model" name="email" required @invalid="send">
              </div>
            </div>
            <div id="Ligne6">
              <div class="LigneGauche2">
                <label for="budget">Budget<span class="etoileform"> *</span></label>
                <select name="budget" id="budget" v-model="vv.budget.$model" required @invalid="send">
                  <option value="50000">50 000$ - 150 000$</option>
                  <option value="150000">150 000$ - 300 000$</option>
                  <option value="300000">300 000$ - 500 000$</option>
                  <option value="500000">+ 500 000$</option>
                </select>
              </div>
              <div class="LigneMillieu2">
                <label for="finance">Finance<span class="etoileform"> *</span></label>
                <select name="finance" id="finance" v-model="vv.finance.$model" required @invalid="send">
                  <option value="budget etabli">J'ai un budget établi</option>
                  <option value="besoin daide pour etablir un budget">J'ai besoin d'aide pour établir un budget</option>
                </select>
              </div>
              <div class="LigneDroit2">
                <label for="terrain">Terrain<span class="etoileform"> *</span></label>
                <select name="terrain" id="terrain" v-model="vv.terrain.$model" required @invalid="send">
                  <option value="jai un terrain">J'ai déjà un terrain</option>
                  <option value="cherche un terrain">Je cherche activement un terrain</option>
                </select>
              </div>
            </div>
            <div id="Ligne7">
              <label for="presentation">Bref présentation de votre projet<span class="etoileform"> *</span></label>
              <textarea id="presentation" rows="5" v-model="vv.presentation.$model" name="presentation" required @invalid="send"/>
            </div>
            <div id="Ligne8">
              <button id="button">Étape suivante</button>
            </div>
          </form>
        </div>
      </div>   
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ProjectManager } from "@/Managers/ProjectManager";
import StepBanner from "../components/StepBanner.vue";
import { ProjectRapport } from "@/project/ProjectRapport";
import { Project } from "@/project/Project";
import { LoadSaveHelper } from "@/helpers/LoadSaveHelper";

@Options({
   data(){
    return {
      erreurs: false,
    };
  },
  created(){
    this.loadSaveHelper = new LoadSaveHelper();
    this.requestUserDetails().then(utilisateur => {
      this.currentUser = ((utilisateur.data).user);

      this.rapport.prenom = this.currentUser.prenom;
      this.rapport.nom = this.currentUser.nom;
      this.rapport.email = this.currentUser.email;
    });
  },
  components: {
    StepBanner
  },
  methods: {
    validation(): boolean {
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },

    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },

    send() {
      this.erreurs = true;
      if (this.validation()) {
        this.desactiveButton();

        //const formData = new FormData();
        const rapportDTO = new ProjectRapport();
        rapportDTO.adresse = this.rapport.adresse;
        rapportDTO.ville = this.rapport.ville;
        rapportDTO.province = this.rapport.province;
        rapportDTO.telephone = this.rapport.telephone;
        rapportDTO.budget = this.rapport.budget;
        rapportDTO.finance = this.rapport.finance;
        rapportDTO.terrain = this.rapport.terrain;
        rapportDTO.presentation = this.rapport.presentation;
        ProjectManager.getProject().rapport = rapportDTO;
        this.loadSaveHelper.saveProject(this.save, true).then(() => {
          this.activeButton();
          ProjectManager.incrementSteps();
        });


        // formData.append("adresse", this.rapport.adresse);
        // formData.append("ville", this.rapport.ville);
        // formData.append("province", this.rapport.province);
        // formData.append("telephone", this.rapport.telephone);
        // formData.append("budget", this.rapport.budget);
        // formData.append("finance", this.rapport.finance);
        // formData.append("terrain", this.rapport.terrain);
        // formData.append("presentation", this.rapport.presentation);

        // const object = {};
        // formData.forEach((value, key) => {
        //   object[key] = value;
        // });
        // const json = object;
        // this.addRapport(json)
        //   .then(() => {
        //     ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
        //       message:
        //         "Félicitations! Formulaire enregistré avec succès",
        //       duration: 5000,
        //       unique:true
        //     });
            
        //     ProjectManager.setCompletedStep(1);
        //     ProjectManager.incrementSteps();
        //   })
        //   .catch(() => {
        //     ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
        //       message: "Erreur lors du sauvegarde du formulaire !",
        //       duration: 3000,
        //       unique: true
        //     });
        //   });
      }
    },
  },
  mounted () {
    if (ProjectManager.getProject().id === null) {
      ProjectManager.setProject(new Project());
      ProjectManager.setCurrentStep(1);
    }
    if(ProjectManager.getProject().rapport !== null) {
      const rapportDTO = ProjectManager.getProject().rapport as ProjectRapport;
      this.rapport.adresse = rapportDTO.adresse;
      this.rapport.ville = rapportDTO.ville;
      this.rapport.province = rapportDTO.province;
      this.rapport.telephone = rapportDTO.telephone;
      this.rapport.budget = rapportDTO.budget;
      this.rapport.finance = rapportDTO.finance;
      this.rapport.terrain = rapportDTO.terrain;
      this.rapport.presentation = rapportDTO.presentation;
    }
  }
})

export default class FormCreation extends Vue {

    @Action("save/saveProject") save: any;
    loadSaveHelper!: LoadSaveHelper;

    currentRapport = {};

    rules = {
      prenom: {required},
      nom: {required },
      email: {required, email},
      adresse: { required },
      ville: { required },
      province: { required },
      telephone: { required },
      budget: { required },
      presentation: { required },
      finance: { required },
      terrain: { required }
    };

    rapport = reactive({
      prenom: "",
      nom: "",
      email: "",
      adresse: "",
      ville: "",
      province: "",
      telephone: "",
      budget: "",
      presentation: "",
      finance: "",
      terrain: "",
    });

    @Action("requestUserDetails", { namespace: "user" }) requestUserDetails: any;

    vv = useVuelidate(this.rules, {
      prenom: toRef(this.rapport, "prenom"),
      nom: toRef(this.rapport, "nom"),
      email: toRef(this.rapport, "email"),
      adresse: toRef(this.rapport, "adresse"),
      ville: toRef(this.rapport, "ville"),
      province: toRef(this.rapport, "province"),
      telephone: toRef(this.rapport, "telephone"),
      budget: toRef(this.rapport, "budget"),
      presentation: toRef(this.rapport, "presentation"),
      finance: toRef(this.rapport, "finance"),
      terrain: toRef(this.rapport, "terrain"),
    });

    submitted = false;

}


</script>

<style lang="scss" scoped>
#GaucheHaut
{
  width:80%;
  margin-right:auto;
  margin-left:auto;
}
#GaucheHaut h2
{
  margin-top:20px;
  padding:0px;
  font-size:30px;
  color:#535353;
}
form
{
  display:flex;
  flex-direction:column;
  width:80%;
  margin-right:auto;
  margin-left:auto;
}
label {
  font-size: 20px;
  margin-bottom:10px;
  margin-top:10px;
  font-weight: 600;
  color: #535353;
}
input{
  background: #f7f7f7;
  border: none;
  padding: 10px;
}
textarea
{
  background: #f7f7f7;
  border: none;
  margin-bottom:20px;
}
#Ligne1,#Ligne4
{
  display:flex;
  flex-direction:row;
}
#Ligne6
{
  display:flex;
  flex-direction:row;
  margin-top:10px;
}
#Ligne2
{
  display:flex;
  flex-direction:row;
}
#Ligne7
{
  display:flex;
  flex-direction:column;
}
#Ligne8
{
  display:flex;
  justify-content: flex-end;
}
.LigneGauche, .LigneDroit
{
  display:flex;
  flex-direction:column;
  width:50%;
}
.LigneGauche3, .LigneMillieu3, .LigneDroit3
{
  display:flex;
  flex-direction:column;
  width:33.33%;
}
.LigneGauche
{
  margin-right:10px;
}
.LigneDroit
{
  margin-left:10px;
}
.LigneMillieu3, .LigneMillieu2
{
  margin-left:20px;
  margin-right:20px;
}
.LigneDroite3
{
  display:flex;
  flex-direction: column;
}
#budget, #terrain, #finance
{
  height:35px;
  width:100%;
  margin-top:10px;
}
#province
{
  height:35px;
  width:100%;
}
#conteneurBas
{
  width:100%;
  display:flex;
  flex-direction:row;
  height:100%;
}
button {
  background-color: #a8ce6c !important;
  color: white !important;
  width: 163px;
  border-radius: 24px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  border: none !important;
  font-family: roboto !important;
}
button:hover {
  background-color: #a3a3a3 !important;
  cursor: pointer;
  border: none !important;
}
#conteneurDroit
{
  background-image: url("/table.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width:25%;
}
#conteneurGauche
{
  background-color:white;
  width:75%;
}
#corpsPage
{
  background-image: url("/wood-grispale.jpg");
  display:flex;
  flex-direction:column;
  mix-blend-mode: luminosity;
  justify-content: center;
  align-items: center;
  height:95vh;
}
#conteneurMillieu
{
  background-color:white;
  width:100%;
  display:flex;
  flex-direction:column;
  margin-right:auto;
  margin-left:auto;
  height:100%;
}
.invalid-feedback {
  color: red;
  font-family: roboto;
  font-size: 12px;
}
form.erreurs {
    :invalid {
      outline: 2px solid red;
    }
}
</style>