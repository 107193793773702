import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { Project } from "@/project/Project";
import { CustomScene } from "@/scene/CustomScene";
import { Router } from "vue-router";
import stepBannerText from "../../configs/stepBannerText.json";

class ProjectManagerClass {
    private currentStep: number;
    private minStep: number;
    private maxStep: number;
    private avalableSteps: Map<number, boolean>;
    private isChanging: boolean;
    private project: Project;
    private scene: CustomScene | null;

    public constructor (currentStep = -1) {
        this.scene = null;
        this.currentStep = currentStep;
        this.minStep = 1;
        this.maxStep = stepBannerText.length - 1 + this.minStep;
        this.avalableSteps = new Map<number, boolean>();
        this.project = new Project();
        for (let x = this.minStep; x <= this.maxStep; x++) {
            this.avalableSteps.set(x, false);
        }
        this.isChanging = false;
    }

    public setScene (scene: CustomScene | null) {
        this.scene = scene;
    }

    public getCurrentStep (): number {
        return this.currentStep;
    }

    public setCurrentStep (value: number) {
        if (value <= this.maxStep && value >= this.minStep) {
            const infoStep = { oldStep: this.currentStep, newStep: value};
            this.currentStep = value;
            EventBus.emit(EventType.stepChanged, infoStep);
        }
    }

    public getAllStepsNumbersIfCompleted (): [number,boolean][] {
        const allKeys = Array.from(this.avalableSteps.keys());
        const newArray = new Array<[number,boolean]>();
        allKeys.forEach(key => newArray.push([key, this.avalableSteps.get(key) as boolean]));
        return newArray;
    }

    public incrementSteps () {
        this.setCurrentStep(this.currentStep + 1);
    }

    public decrementSteps () {
        this.setCurrentStep(this.currentStep - 1);
    }

    public getMinimumStep (): number {
        return this.minStep;
    }

    public getMaximumStep (): number {
        return this.maxStep;
    }

    public isCurrentStepAtMaximumStep (): boolean {
        return this.currentStep === this.maxStep;
    }

    public isCurrentStepAtMinimumStep (): boolean {
        return this.currentStep === this.minStep;
    }

    public getCurrentStepBannerText (): string {
        return stepBannerText[this.currentStep-this.minStep];
    }

    public changePageDependingCurrentStep (router: Router) {
        let newRoute = router.currentRoute.value.name;
        switch (this.currentStep) {
            case 1: {
                newRoute = "FormCreation"
                break;
            }
            case 2: {
                newRoute = "ChoixTemplateProjet"
                break;
            }
            case 3: {
                newRoute = "MainPage"
                break;
            }
            case 4: {
                newRoute = "MainPage"
                break;
            }
            case 5: {
                newRoute = "MainPage"
                break;
            }
            case 6: {
                newRoute = "MainPage"
                break;
            }
            case 7: {
                newRoute = "Synthese"
                break;
            }
        }
        if (newRoute !== router.currentRoute.value.name) {
            router.push(("/" + (newRoute as string) + "/" + (this.project.id as number)));
        }
    }

    public getIfStepAvalable (stepNumber: number): boolean {
        return this.avalableSteps.get(stepNumber) as boolean;
    }

    public getIfIsChanging (): boolean {
        return this.isChanging;
    }

    public setIfIsChanging (value: boolean) {
        this.isChanging = value;
        this.calculateAvalablesSteps(false);
    }

    public getProject(): Project{
        return this.project;
    }

    public setProject(project: Project){
        this.resetProject();
        this.project = project;
    }

    private resetProject () {
        this.minStep = 1;
        this.maxStep = stepBannerText.length - 1 + this.minStep;
        this.avalableSteps = new Map<number, boolean>();
        this.project = new Project();
        for (let x = this.minStep; x <= this.maxStep; x++) {
            this.avalableSteps.set(x, false);
        }
        this.isChanging = false;
    }

    public calculateAvalablesSteps(considerSave: boolean) {
        for (let x = this.minStep; x <= this.maxStep; x++) {
            this.avalableSteps.set(x, false);
        }
        this.avalableSteps.set(1,true);
        if(this.project.rapport !== null) {
            this.avalableSteps.set(2,true);
            if(this.project.isSet) {
                this.avalableSteps.set(3,true);
            }
        }
        if(!considerSave && this.scene !== null) {
            if (this.scene.getSceneObjects().length > 0) {
                this.avalableSteps.set(4,true);
                this.avalableSteps.set(5,true);
                this.avalableSteps.set(6,true);
                this.avalableSteps.set(7,true);
            }
        }
        else {
            if (this.project.blocks.length > 0) {
                this.avalableSteps.set(4,true);
                this.avalableSteps.set(5,true);
                this.avalableSteps.set(6,true);
                this.avalableSteps.set(7,true);
            }
        }
        EventBus.emit(EventType.avalableStepsUpdated);
    }
}

export const ProjectManager = new ProjectManagerClass();