import { Box } from "@/Box/Box";
import { BoxRotationDirection } from "@/Box/BoxRotationDirection";
import { SceneGrid } from "@/scene/SceneGrid";
import { Vector3 } from "three";
import { BoxWallGroup } from "@/Box/BoxParts/BoxWall/BoxWallGroup";

export class BoxWallBesideHelper {

    private boxs: Array<Box>;
    private grid: SceneGrid;
    //public values: Array<string | number>;

    public constructor (boxs: Array<Box>, grid: SceneGrid) {
        this.boxs = boxs;
        this.grid = grid;
    }

    public getWallsBesideEachOthers (): [BoxWallGroup, BoxWallGroup | undefined][]{
        const wallsList = new Array<[BoxWallGroup, BoxWallGroup | undefined]>();
        this.boxs.forEach(box1 => {
            this.boxs.forEach(box2 => {
                if (box1 != box2) {
                    if (this.grid.getFloorOfBox(box1) == this.grid.getFloorOfBox(box2)) {
                        const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(box1, box2);
                        const dist2 = this.grid.getGridPositionDiferenceBetweenTwoObjects(box2, box1);
                        if(Math.abs(dist.z) <= 14 && Math.abs(dist.x) <= 14 && Math.abs(dist.z) != Math.abs(dist.x)){
                            const startingWallToRemove = this.getStartingWallToRemoveFromDistance(box1, dist);
                            const startingWallToRemoveBox2 = this.getStartingWallToRemoveFromDistance(box2, dist2);
                            wallsList.push([box1.getWalls()[startingWallToRemove], box2.getWalls()[startingWallToRemoveBox2]])
                            
                        }
                    }
                }
            });
            const stairs = box1.getStairs();
            if (stairs !== undefined) {
                const walls = box1.getWalls();
                let startingWallToRemove: number;
                if (stairs.isBoxTheBottomBox(box1)) {
                    startingWallToRemove = stairs.getSideNumberRelativeToBottomBox();
                }
                else {
                    startingWallToRemove = stairs.getSideNumberRelativeToTopBox();
                }
                wallsList.push([walls[startingWallToRemove], undefined]);
            }
        });
        return wallsList;
    }

    public getWallStatus(box1: Box){
        const list: Array<string|number> = [];
        this.boxs.forEach(box2 => {
            if (box1 != box2) {
                if (this.grid.getFloorOfBox(box1) == this.grid.getFloorOfBox(box2)) {
                    const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(box1, box2);
                    const dist2 = this.grid.getGridPositionDiferenceBetweenTwoObjects(box2, box1);
                    if(Math.abs(dist.z) <= 14 && Math.abs(dist.x) <= 14 && Math.abs(dist.z) != Math.abs(dist.x)){
                        const startingWallToRemove = this.getStartingWallToRemoveFromDistance(box1, dist);
                        //const startingWallToRemoveBox2 = this.getStartingWallToRemoveFromDistance(box2, dist2);
                        list.push(startingWallToRemove)
                    }
                }
            }
        });
        return list;        
    }

    public getIfBoxWallsAreAlignToAnotherBox(box1: Box, box2: Box): boolean {
        const dist = box2.getPosition().clone().sub(box1.getPosition()).multiplyScalar(2);
        const dist2 = box1.getPosition().clone().sub(box2.getPosition()).multiplyScalar(2);
        const startingWallToRemove = box1.getWalls()[this.getStartingWallToRemoveFromDistance(box1, dist)];
        const startingWallToRemove2 = box2.getWalls()[this.getStartingWallToRemoveFromDistance(box2, dist2)];
        
        const wallGroup1Rotation = startingWallToRemove.getRotation();
        if(wallGroup1Rotation === BoxRotationDirection.Nord || wallGroup1Rotation === BoxRotationDirection.Sud) {
            return startingWallToRemove.some(wall1 => startingWallToRemove2.some(wall2 => Math.fround(wall1.getTruePosition().z) === Math.fround(wall2.getTruePosition().z)));
        }
        else {
            return startingWallToRemove.some(wall1 => startingWallToRemove2.some(wall2 => Math.fround(wall1.getTruePosition().x) === Math.fround(wall2.getTruePosition().x)));
        }
    }

    private getStartingWallToRemoveFromDistance (box1: Box, distance: Vector3): number {
        let startingWallToRemove = 0;
        const ifXisEvaluated = Math.abs(distance.z) == 14;
        let ifXNeededToBeEvaluated = true;
        switch (box1.getRotation()) {
            case BoxRotationDirection.Est: {
                startingWallToRemove = 0;
                ifXNeededToBeEvaluated = false;
                break;
            }
            case BoxRotationDirection.Ouest:{
                startingWallToRemove = 2;
                ifXNeededToBeEvaluated = false;
                break;
            }
            case BoxRotationDirection.Sud:{
                startingWallToRemove = 0;
                break;
            }
            case BoxRotationDirection.Nord:{
                startingWallToRemove = 2;
                break;
            }
        }
        if(ifXisEvaluated) {
            if(distance.z < 0) {
                startingWallToRemove += 2;
            }
        }
        else {
            if(distance.x > 0) {
                startingWallToRemove += 2;
            }
        }

        if (ifXisEvaluated == ifXNeededToBeEvaluated) {
            startingWallToRemove = 1;
        }
        return startingWallToRemove % 4;
    }

    // public getWallsBesideEachOthers (): BoxWall[]{
    //     const wallsList = new Array<BoxWall>();
    //     this.boxs.forEach(box1 => {
    //         this.boxs.forEach(box2 => {
    //             if (box1 != box2) {
    //                 if (this.grid.getFloorOfBox(box1) == this.grid.getFloorOfBox(box2)) {
    //                     const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(box1, box2);
    //                     if(Math.abs(dist.z) <= 14 && Math.abs(dist.x) <= 14 && Math.abs(dist.z) != Math.abs(dist.x)){
    //                         let evaluatedAxys = dist.z;
    //                         let otherAxys = dist.x;
    //                         if (Math.abs(dist.z) == 14) {
    //                             evaluatedAxys = dist.x;
    //                             otherAxys = dist.z;
    //                         }
    //                         let inverseEvaluation = false;
    //                         let distanceVoulue = 0;
    //                         if(box1.getRotation() == BoxRotationDirection.Est || box1.getRotation() == BoxRotationDirection.Ouest) {
    //                             if(box2.getRotation() == BoxRotationDirection.Est || box2.getRotation() == BoxRotationDirection.Ouest) {
    //                                 if (evaluatedAxys == dist.x && box2.getRotation() != box1.getRotation()) {
    //                                     distanceVoulue = 2;
    //                                     inverseEvaluation = true;
    //                                 }
    //                                 else {
    //                                     distanceVoulue = 0;
    //                                     inverseEvaluation = true;
    //                                 }
    //                             }
    //                             else {
    //                                 distanceVoulue = 1
    //                                 if (dist.z != evaluatedAxys) {
    //                                     distanceVoulue = -distanceVoulue;
    //                                 }
    //                                 inverseEvaluation = true;
    //                             }
    //                         }
    //                         else {
    //                             if(box2.getRotation() == BoxRotationDirection.Est || box2.getRotation() == BoxRotationDirection.Ouest) {
    //                                 distanceVoulue = -1;
    //                             }
    //                             else {
    //                                 if (evaluatedAxys == dist.x || box2.getRotation() == box1.getRotation()) {
    //                                     distanceVoulue = 0;
    //                                 }
    //                                 else {
    //                                     distanceVoulue = 2;
    //                                 }
    //                             }
    //                         }
    //                         let incremente = true;
    //                         let initialWallIndex = 0;
    //                         if((dist.z == evaluatedAxys && !inverseEvaluation) || (inverseEvaluation && dist.x == evaluatedAxys)) {
    //                             let nbDroiteFin = 8;
    //                             let nbDroiteDebut = 6;
    //                             let nbGaucheFin = 2;
    //                             let nbGaucheDebut = 0;
    //                             if(box1.getRotation() == BoxRotationDirection.Nord) {
    //                                 nbDroiteFin = 2;
    //                                 nbGaucheFin = 8
    //                                 nbDroiteDebut = 0;
    //                                 nbGaucheDebut = 6;
    //                             }
    //                             if (box1.getRotation() == BoxRotationDirection.Est) {
    //                                 nbDroiteFin = 0;
    //                                 nbGaucheFin = 6
    //                                 nbDroiteDebut = 2;
    //                                 nbGaucheDebut = 8;
    //                                 incremente = !incremente;
    //                                 distanceVoulue = -distanceVoulue;
    //                             }
    //                             if (box1.getRotation() == BoxRotationDirection.Ouest) {
    //                                 nbDroiteFin = 6;
    //                                 nbGaucheFin = 0
    //                                 nbDroiteDebut = 8;
    //                                 nbGaucheDebut = 2;
    //                                 incremente = !incremente;
    //                                 distanceVoulue = -distanceVoulue;
    //                             }
    //                             if (box2.getRotation() == BoxRotationDirection.Nord) {
    //                                 distanceVoulue = -distanceVoulue;
    //                             }
    //                             if(evaluatedAxys - distanceVoulue >= 0) {
    //                                 if(otherAxys > 0) {
    //                                     incremente = !incremente;
    //                                     initialWallIndex += nbDroiteFin;
    //                                 }
    //                                 else {
    //                                     initialWallIndex += nbGaucheDebut;
    //                                 }
    //                             }
    //                             else {
    //                                 if(otherAxys > 0) {
    //                                     initialWallIndex += nbDroiteDebut;
    //                                 }
    //                                 else {
    //                                     incremente = !incremente;
    //                                     initialWallIndex += nbGaucheFin;
    //                                 }
    //                             }
    //                         }
    //                         else {
    //                             if (evaluatedAxys > 0) {
    //                                 initialWallIndex = 5;
    //                                 incremente = !incremente;
    //                             }
    //                             else {
    //                                 initialWallIndex = 3;
    //                             }
    //                         }
    //                         const numberOfWallsToCollect = 3-Math.abs((evaluatedAxys - distanceVoulue)/4);
    //                         const wallsIndexs: number[] = [];
    //                         if (incremente) {
    //                             for(let x = initialWallIndex; x < numberOfWallsToCollect + initialWallIndex; x++){
    //                                 wallsList.push(box1.getWalls()[x]);
    //                                 wallsIndexs.push(x);
    //                             }
    //                         }
    //                         else {
    //                             for(let x = initialWallIndex; x > initialWallIndex - numberOfWallsToCollect; x--){
    //                                 wallsList.push(box1.getWalls()[x]);
    //                                 wallsIndexs.push(x);
    //                             }
    //                         }
    //                         }
    //                     }
    //             }
    //         });
    //     });
    //     return wallsList;
    // }

    
}