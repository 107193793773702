import axios from 'axios'
import { Module } from 'vuex'


const auth: Module<any, any> = {
  namespaced: true,

  state: {
  },
  getters: {
    isLoggedIn: () => localStorage.getItem('token') !== null,
  },

  actions: {
    logout({ commit }) {
      commit('clearUserData')
    },
    login({ commit }, credentials) {
      return axios
        .post(process.env.VUE_APP_API_URL + '/login', credentials)
        .then(({ data }) => {
          localStorage.setItem('token', data.token)
          axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
        })
    },
    requestUserDetails({ commit }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/details',
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          }
        }).then(response => {
          localStorage.setItem('categorie', response.data.user.categorie_id)
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
    register(context, informations) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/register',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          data: informations
        }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    requestUserEmailsList({ commit }) {
      return new Promise((resolve, reject) => {
        axios.request({
          url: '/userEmailList',
          method: 'get',
          baseURL: process.env.VUE_APP_API_URL
        }).then(response => {
          localStorage.setItem('listEmails', response.data)
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUser(context, informations) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/update_user',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: informations
        }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updatePassword(context, informations) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/update_password',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: informations
        }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getPassword(context, informations) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/get_password',
          method: 'post',
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
          },
          data: informations
        }).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
    }
  },
  

  mutations: {
    clearUserData() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('categorie');
    },
  },
}

export default auth
