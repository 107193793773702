import { Box } from "@/Box/Box";
import { Vector3 } from "three";
import { SceneRayCaster } from "./raycaster/SceneRayCaster";
import { SceneRayCasterObjectType } from "./raycaster/SceneRayCasterObjectType";
import { CustomScene } from "./CustomScene";
import { BoxRotationDirection, getNextClockwiseDirection, getNextCounterClockwiseDirection } from "@/Box/BoxRotationDirection";
import { SelectionManager } from "@/Managers/SelectionManager/SelectionManager";
import { SelectedBoxState } from "@/Managers/SelectionManager/SelectedBoxState";
import { FirstPersonViewManager } from "@/Managers/FirstPersonViewManager";
import { ProjectManager } from "@/Managers/ProjectManager";

export class SceneMouse {

    private raycaster: SceneRayCaster;
    private scene: CustomScene;
    private selectionManager: SelectionManager;
    private firstPersonViewManager: FirstPersonViewManager;

    public constructor(scene: CustomScene, selectionManager: SelectionManager, firstPersonViewManager: FirstPersonViewManager) {
        this.scene = scene;
        this.raycaster = new SceneRayCaster(1);
        this.selectionManager = selectionManager;
        this.firstPersonViewManager = firstPersonViewManager;
    }

    public onClick(event: MouseEvent) {
        if (!this.firstPersonViewManager.isFirstPersonActivaded()) {
            let arreteDeBouger = false;
            this.raycaster.setFromCameraConsideringMouse(event, this.scene.getRenderer(), this.scene.getCamera());
            const obj = this.getBoxMousePointingTo();
            if (this.selectionManager.isThereABoxSelected()) {
                if (this.selectionManager.getSelectedBoxState() === SelectedBoxState.newBox) {
                    arreteDeBouger = this.scene.getAddingRuleChecker().isActionPermitted(this.selectionManager.getSelectedBox());
                    if (arreteDeBouger) {
                        this.scene.addBoxInSceneFromPreview(this.selectionManager.getSelectedBox(), this.getGridPositionOnSceneGrid() as Vector3);
                        this.selectionManager.clearSelectedBox();
                    }
                }
                else {
                    if (this.selectionManager.getIfSelectedBoxIsMoving()) {
                        arreteDeBouger = this.scene.getAddingRuleChecker().isActionPermitted(this.selectionManager.getSelectedBox());
                        if (arreteDeBouger) {
                            if (obj != null) {
                                if(!this.selectionManager.isThisBoxSelected(obj)) {
                                    this.selectionManager.setSelectedBox(obj, SelectedBoxState.existingBox);
                                }
                                obj.setBoxColor(this.scene.getObject3DColorSetter(), "blue");
                            }
                        }
                    }
                    else {
                        if(obj != null && !this.selectionManager.isThisBoxSelected(obj)) {
                            this.selectionManager.setSelectedBox(obj, SelectedBoxState.existingBox);
                            obj.setBoxColor(this.scene.getObject3DColorSetter(), "blue");
                        }
                        else if (obj == null) {
                            this.selectionManager.clearSelectedBox();
                        }
                    }
                }
            }
            else if (obj != null) {
                this.selectionManager.setSelectedBox(obj, SelectedBoxState.existingBox);
                obj.setBoxColor(this.scene.getObject3DColorSetter(), "blue");
            }
            if (arreteDeBouger) {
                this.selectionManager.setIfSelectedBoxIsMoving(false);
            }
        }
    }

    private getGridPositionOnSceneGrid () {
        const tilePointedTo = this.raycaster.getFirstIntersectedObject(this.scene.getGridArrayFromGridHitbox(), SceneRayCasterObjectType.Mesh);
        if (tilePointedTo != null && tilePointedTo.point != null) {
            return this.scene.getGrid().getGridSquareContainingPosition(tilePointedTo.point);
        }
    }

    public onMouseMove(event: MouseEvent) {
        if (this.selectionManager.isThereABoxSelected() && this.selectionManager.getIfSelectedBoxIsMoving()) {
            this.raycaster.setFromCameraConsideringMouse(event, this.scene.getRenderer(), this.scene.getCamera());
            const tilePointedTo = this.raycaster.getFirstIntersectedObject(this.scene.getGridArrayFromGridHitbox(), SceneRayCasterObjectType.Mesh);
            if (tilePointedTo != null && tilePointedTo.point != null) {
                const newPositionOnGrid = this.scene.getGrid().getGridSquareContainingPosition(tilePointedTo.point);
                if (newPositionOnGrid != null) {
                    const newWorldPosition = this.scene.getGrid().getPositionFromGridSquare(newPositionOnGrid);
                    const selectedBox = this.selectionManager.getSelectedBox();
                    selectedBox.setPosition(newWorldPosition);
                    const ifBoxFirstTimeChecked = !this.scene.getAddingRuleChecker().getIfBoxIsLastCheckedBox(selectedBox);
                    const ifLastTimeWasAllowed = this.scene.getAddingRuleChecker().getIfLastBoxWasAllowed();
                    const ifPermitted = this.scene.getAddingRuleChecker().isActionPermitted(selectedBox, false);
                    let doesColorChange = false;
                    if (ifBoxFirstTimeChecked) {
                        doesColorChange = true;
                    }
                    else if(ifPermitted != ifLastTimeWasAllowed) {
                        doesColorChange = true;
                        this.scene.refreshBox(selectedBox);
                    }
                    if(doesColorChange) {
                        let newColor: string;
                        if (!ifPermitted) {
                            newColor = "red";
                        }
                        else {
                            newColor = "green";
                        }
                        selectedBox.setBoxColor(this.scene.getObject3DColorSetter(), newColor);
                    }
                    if (this.selectionManager.getSelectedBoxState() === SelectedBoxState.existingBox) {
                        this.scene.getGrid().addBoxOnGrid(selectedBox, newPositionOnGrid);
                    }
                    //this.objetSelectionSouris.deplacer(new Vector3(0,this.objetSelectionSouris.getSize().y*0.5,0));
                }
            }
        }
    }

    public mouseWheel(event: any) {
        event.preventDefault();
        if (this.selectionManager.isThereABoxSelected() && this.selectionManager.getIfSelectedBoxIsMoving()) {
            const selectedBox = this.selectionManager.getSelectedBox();
            if (event.wheelDelta < 0) {
                selectedBox.setRotation(getNextCounterClockwiseDirection(selectedBox.getRotation()) as BoxRotationDirection);
            }
            else {
                selectedBox.setRotation(getNextClockwiseDirection(selectedBox.getRotation()) as BoxRotationDirection);
            }
            this.setMouseColorDependingIfAddingIsPermitted(selectedBox);
        }
    }

    private setMouseColorDependingIfAddingIsPermitted (box: Box) {
        const ifAddingPermitted = this.scene.getAddingRuleChecker().isActionPermitted(box, false);
        if (ifAddingPermitted) {
            box.setBoxColor(this.scene.getObject3DColorSetter(), "green");
        }
        else {
            box.setBoxColor(this.scene.getObject3DColorSetter(), "red");
        }
    }

    public mouseDblClic(event: MouseEvent) {
        if (!this.firstPersonViewManager.isFirstPersonActivaded() && ProjectManager.getCurrentStep() === 3) {
            this.raycaster.setFromCameraConsideringMouse(event, this.scene.getRenderer(), this.scene.getCamera());
            const obj = this.getBoxMousePointingTo();
        
            if (obj != null && this.selectionManager.isThisBoxSelected(obj)) {     
   
                const ifMovingPermited = this.scene.getMovingRuleChecker().isActionPermitted(obj);
                if (ifMovingPermited) {
                    this.setMouseColorDependingIfAddingIsPermitted(obj);
                    this.selectionManager.setIfSelectedBoxIsMoving(true);
                }   
            }
        }
    }

    // private floorChanged () {
    //     if (!this.previewObject) {
    //         if (this.objetSelectionSourisBouge) {
    //             this.resetObjetSourisSelected();
    //         }
    //         else {
    //             this.objetSelectionSouris = undefined;
    //         }
    //     }
    // }

    private getBoxMousePointingTo(): Box | null {
        const boxPointedTo = this.raycaster.getBoxFromIntersectedObjectOfAMesh(this.scene.getSceneObjectsFromCurrentFloor());
        // const sceneObject = this.scene.getSceneObjects().filter((sceneObj) => {
        //     const sceneObjPos = this.scene.getGrid().getGridPositionFromBox(sceneObj);
        //     return objectPointedTo == sceneObj.getObject() && sceneObjPos != undefined && sceneObjPos.y == this.scene.getGrid().getEtageActif();
        // });
        return boxPointedTo;
    }
}