import { ObjectSearchIgnore } from "@/decorators/ObjectSearchIgnore";
import { ParameterDisplay } from "@/decorators/ParameterDisplay";

export class BoxDefaultValues {
    @ParameterDisplay({ name: "Performance environementale", stepsVisible: []})
    public performance!: number;
    @ParameterDisplay({ name: "Prix", stepsVisible: []})
    public price!: number;
    @ParameterDisplay({ name: "Type Pièce", stepsVisible: []})
    public name!: string;
    @ObjectSearchIgnore
    public location!: string;
}