import { Vector3 } from "three";

export class SunPositionCalculator {

    private ifCountNightInDayCalculation: boolean;

    private sunDistance: number;

    public constructor (ifCountNightInDayCalculation: boolean) {
        this.ifCountNightInDayCalculation = ifCountNightInDayCalculation;
        this.sunDistance = 500;
    }

    public calculatePositionByDayPercentage(dayPercentage: number): Vector3 {
        const position = new Vector3();
        position.x = this.sunDistance/2 - dayPercentage/100*this.sunDistance;
        position.y = -Math.pow(position.x/this.sunDistance,2)*this.sunDistance + this.sunDistance/4;
        return position;
    }
}