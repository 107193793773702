const FIELD_HAVE_CHILDS_BY_CLASS = new Map<string,string[]>();

export function ifFieldHaveChild(object: any ,fieldName: string): boolean {
    if(FIELD_HAVE_CHILDS_BY_CLASS.has(object.constructor.name as string)) {
        if((FIELD_HAVE_CHILDS_BY_CLASS.get(object.constructor.name as string) as string[]).indexOf(fieldName) > -1) {
            return true;
        }
    }
    let classToSearch = Object.getPrototypeOf(object);
    while(classToSearch != null) {
        const classHaveChildParameter = FIELD_HAVE_CHILDS_BY_CLASS.has(classToSearch.constructor.name)
        if(classHaveChildParameter) {
            if((FIELD_HAVE_CHILDS_BY_CLASS.get(classToSearch.constructor.name as string) as string[]).indexOf(fieldName) > -1) {
                return true;
            }
        }
        classToSearch = Object.getPrototypeOf(classToSearch);
    }
    return false;
}

export function ParameterHasChild(target: any, name: string) {
    const clsName = target.constructor.name;
    let list: string[];

    if (FIELD_HAVE_CHILDS_BY_CLASS.has(clsName)) {
        list = FIELD_HAVE_CHILDS_BY_CLASS.get(clsName) as [];
    } else {
        list = [];
        FIELD_HAVE_CHILDS_BY_CLASS.set(clsName, list);
    }

    list.push(name);
}