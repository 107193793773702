import { Box3, Object3D, Vector3 } from 'three';

export class Object3DTruePositionCalculator {
    public getTruePosition (object: Object3D, decalage: Vector3 | null = null): Vector3 {
        const trueNewPosition = new Vector3().copy(object.position);
        const box = new Box3().setFromObject(object);
        const center = box.getCenter( new Vector3() );
        trueNewPosition.x += ( object.position.x - center.x );
        trueNewPosition.y += ( object.position.y - center.y );
        trueNewPosition.z += ( object.position.z - center.z );
        if (decalage != null) {
            trueNewPosition.add(decalage);
        }
        return trueNewPosition;
    }
}