
import { ProjectManager } from "@/Managers/ProjectManager";
import { Options, Vue } from "vue-class-component";
import StepBanner from "../components/StepBanner.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import presetList from "../../configs/presets.json"
import { ProjectBox } from "@/project/ProjectBox";
import { ProjectBoxDeseresiazer } from "@/Desereasizer/ProjectBoxDeseresiazer";
import { LoadSaveHelper } from "@/helpers/LoadSaveHelper";

@Options({
    components: {
        StepBanner,
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    mounted () {
        ProjectManager.setCurrentStep(2);
    },
    methods: {
        setTemplateChoiceAsNew () {
            ProjectManager.getProject().isSet = true;
            ProjectManager.incrementSteps();
        },
        setTemplateChoiceAsPreset (presetId: number) {
            if (ProjectManager.getProject().blocks.length === 0 || confirm("Si vous choisisez un autre preset, toutes les boites placés seront remplacé. Voulez-vous continuer?")) {
                ProjectManager.getProject().isSet = true;
                ProjectManager.incrementSteps();
                const preset = presetList.find(presetItem => presetItem.id === presetId);
                preset?.blocks.forEach(boxItem => {
                    const box = new ProjectBox();
                    ProjectBoxDeseresiazer.toInstance(box, boxItem);
                    // box.internalId = internalID++;
                    // box.floorName = boxItem.floorName;
                    // box.frontFacingName = boxItem.frontFacingName;
                    // box.wallName = boxItem.wallName;
                    // box.windowName = boxItem.windowName;
                    // box.position = new Vector3(boxItem.positionX, boxItem.positionY, boxItem.positionZ);
                    ProjectManager.getProject().addBoxtoListBox(box);
                });
            }
        },
    }
})

export default class ChoixTemplateProjet extends Vue {
    presets = presetList;
    loadSaveHelper!: LoadSaveHelper;
}
