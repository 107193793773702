import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { Box } from "@/Box/Box";
import { PerformanceCalculator } from '@/calculators/PerformanceCalculator';

export class PerformanceManager {
    private infoProjet: SceneInfoProjet;
    private boxes: Box[];
    private performanceCalculator: PerformanceCalculator;

    public constructor(infoProjet: SceneInfoProjet, boxes: Box[]) {
        this.infoProjet = infoProjet;
        this.boxes = boxes;
        this.performanceCalculator = new PerformanceCalculator();
    }

    public update() {
        this.infoProjet.performanceEcologique = (this.performanceCalculator.calculate(this.boxes))!;
    }

    public getPerformance (): number {
        return this.infoProjet.performanceEcologique;
    }

}