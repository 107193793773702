
import { Action } from "vuex-class";
import { Options, Vue } from "vue-class-component";
import { ProjectManager } from "@/Managers/ProjectManager";
import Modal from '@/components/Modal.vue';
import { Project } from "@/project/Project";
import { ProjectDesereliazer } from "../Desereasizer/ProjectDesereliazer";

@Options({
  components: {
    Modal
  },
  methods: {
    assignToObject<T>(target: T, params: any) {
      for (const key of Object.keys(target)) {
          if (params[key] !== undefined) {
            target[key] = params[key];
          }
      }
    },
    loadSave (id: number) {
      this.$refs.chargementSauvegarde.openModal();
      this.getSave(id).then(response => {
        ProjectManager.setProject(ProjectDesereliazer.toInstance(new Project(), response));
        // response.data["blocks"].boxList.forEach(boxItem => {
        //   const box = new ProjectBox();
        //   box.floorName = boxItem.floorName;
        //   box.frontFacingName = boxItem.frontFacingName;
        //   box.wallName = boxItem.wallName;
        //   box.windowName = boxItem.windowName;
        //   box.position = new Vector3(boxItem.position.x, boxItem.position.y, boxItem.position.z);
        //   ProjectManager.getProject().addBoxtoListBox(box);
        // });
        this.$refs.chargementSauvegarde.closeModal();
        ProjectManager.setCurrentStep(3);
        ProjectManager.getProject().isSet = true;
        ProjectManager.changePageDependingCurrentStep(this.$router);
      });
    }
  },
  created () {
    this.loadAllSaves().then(response => {
      this.saveList = response.data;
    });
  }
})
export default class HomeClient extends Vue {
  @Action("save/getAllSaves") loadAllSaves: any;
  @Action("save/getSave") getSave!: any;
  saveList = [];
}
