import { Box } from "@/Box/Box";
import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { CustomScene } from "@/scene/CustomScene";
import { SceneCommunicationBus } from "@/scene/SceneCommunicationBus";
import { ProjectManager } from "../ProjectManager";
import { SelectedBoxState } from "./SelectedBoxState";

export class SelectionManager {
    private selectedBox: Box | undefined;
    private selectionMode: SelectedBoxState | undefined;
    private isSelectedBoxMoving: boolean;
    private scene!: CustomScene;

    public constructor () {
        this.isSelectedBoxMoving = false;
        SceneCommunicationBus.whenInstanceIsReady(CustomScene, (scene) => { this.scene = scene })
    }

    public isThereABoxSelected (): boolean {
        return this.selectedBox != undefined;
    }

    public getSelectedBox (): Box {
        return this.selectedBox as Box;
    }

    public setSelectedBox (newSelectedBox: Box, mode: SelectedBoxState) {
        if (this.isThereABoxSelected()) {
            this.clearSelectedBox();
        }
        this.selectedBox = newSelectedBox;
        this.selectionMode = mode;
        EventBus.emit(EventType.newSelectedBox);
    }

    public clearSelectedBox () {
        if (this.selectedBox != undefined) {
            this.scene.refreshBox(this.selectedBox);
        }
        this.selectedBox = undefined;
        this.selectionMode = undefined;
        EventBus.emit(EventType.removedSelectedBox);
    }

    public deleteSelectedBox() {
        if (this.selectedBox != undefined) {
            this.scene.deleteBox(this.selectedBox);
        }
	    this.selectedBox = undefined;
        this.selectionMode = undefined;
        EventBus.emit(EventType.removedSelectedBox);
    } 

    public getSelectedBoxState (): SelectedBoxState {
        if(this.selectionMode != undefined) {
            return this.selectionMode;
        }
        else {
            throw new Error("nothing is selected");
        }
    }

    public getIfSelectedBoxIsMoving (): boolean {
        return this.isSelectedBoxMoving;
    }

    public getIfCanChangeSelectedBox(): boolean {
        return !this.isThereABoxSelected() || !this.getIfSelectedBoxIsMoving()
    }

    public setIfSelectedBoxIsMoving (newValue: boolean) {
        this.isSelectedBoxMoving = newValue;
        ProjectManager.setIfIsChanging(newValue);
        EventBus.emit(EventType.selectedBoxMovingChanged);
    }

    public isThisBoxSelected(box): boolean {
        return this.isThereABoxSelected() && this.getSelectedBox() == box;
    }
}