
import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/Modal.vue';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { BoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { ObjectParameter } from '@/helpers/ObjectParametersAsMapGetter';
import { OrbitControls } from 'node_modules/@types/three/examples/jsm/controls/OrbitControls';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { BoxWindow } from '@/Box/BoxParts/BoxWall/BoxWindow';
import { Material } from '@/CustomEnums/Material';
import { WindowType } from '@/CustomEnums/WindowType';

@Options({
  props: {
    params: Map,
    selectionManager: SelectionManager
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    nbItemsToShow () {
      return 3;
    }
  },
  methods: {
    facadeChanged (item: ObjectParameter) {
      this.selectionManager.getSelectedBox().setFrontFacingMaterial(item.key);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    getNewRelativePosition(oldPosition): number {
      return (oldPosition + this.decalageCamera) % 4;
    },
    getParam (name: string): ObjectParameter {
      return this.params.get(name);
    },
    changeFenetreIndividuel (wallGroupNumber: number, wallNb: number) {
      this.currentChangingWindowPosition = [wallGroupNumber, wallNb];
      this.$refs.modalWindowIndividuel.openModal();
    },
    getEnumItemsListCaroussel (paramName: string) {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.params.get(paramName).editable.enumItemList.concat(this.params.get(paramName).editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.params.get(paramName).editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    openWindowModal () {
      this.$refs.modalWindow.openModal();
    },
    whenSelectedBoxChange () {
      if (this.selectionManager.isThereABoxSelected()) {
        this.updateSiMontreFenetre();
        this.renderIt = true;
      }
      else {
        this.renderIt = false;
      }
    },
    updateSiMontreFenetre () {
      const wallsGroup = this.selectionManager.getSelectedBox().getWalls();
      this.siMontreFenetre = new Array<Array<boolean>>(wallsGroup.length);
      for(let x = 0; x < wallsGroup.length; x++) {
        this.siMontreFenetre[x] = new Array<boolean>(wallsGroup[x].length);
        for(let y = 0; y < wallsGroup[x].length; y++) {
          this.siMontreFenetre[x][y] = wallsGroup[x][y].constructor.name === BoxWindow.name;
        }
      }
    },
    updateDecalageFromCamera () {
      const cameraAngle = Math.round(this.scene.getCamera().rotation.z/Math.PI*2);
      if (cameraAngle >= 0) {
        this.decalageCamera = 4 - cameraAngle;
      }
      else {
        this.decalageCamera = -cameraAngle;
      }
    },
    changeMateriel (item: ObjectParameter) {
      const wall = this.selectionManager.getSelectedBox().getWalls()[this.currentChangingWindowPosition[0]][this.currentChangingWindowPosition[1]] as BoxWindow;
      wall.setMaterial(Material.getEntryOf(item.key) as Material);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    changeType (item: ObjectParameter) {
      const wall = this.selectionManager.getSelectedBox().getWalls()[this.currentChangingWindowPosition[0]][this.currentChangingWindowPosition[1]] as BoxWindow;
      wall.setType(WindowType.getEntryOf(item.key) as WindowType);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    whenSceneLoadIsCompleted () {
      if(this.scene.getControls() != undefined) {
        (this.scene.getControls() as OrbitControls).addEventListener('change', this.updateDecalageFromCamera );
      }
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
    EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, (scene) => { this.scene = scene });
    EventBus.on(EventType.sceneLoadCompleted, this.whenSceneLoadIsCompleted);
    this.whenSceneLoadIsCompleted();
  }
})
export default class SelectionMenuStep5 extends Vue {
  decalageCamera: number = 0;
  renderIt = false;
  currentChangingWindowPosition = [-1, -1];
  lienImageFenetre = BoxWallState.window;
  scene!: CustomScene;
  siMontreFenetre!: Array<Array<boolean>>;
}
