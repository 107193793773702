
import { Options, Vue } from "vue-class-component";
import { Action, Getter } from "vuex-class";

const namespace = "auth";

@Options({
  methods: {
    deconnect() {
      this.logout().then(() => {
        location.reload();
      });
    },
    profil() {
     this.$router.push("/profil");  
    },
    rapports() {
     this.$router.push("/rapports");  
    }
  },
})
export default class Header extends Vue {
  @Action("logout", { namespace }) logout: any;
  @Getter("auth/isLoggedIn") isLoggedIn: any;
}
