import { Material } from "@/CustomEnums/Material";
import { WindowType } from "@/CustomEnums/WindowType";
import { BoxHelper, Object3D } from "three";
import { BoxWall } from "./BoxWall";
import { BoxWallState } from './BoxWallState';

export class BoxWindow extends BoxWall {
    private material: Material;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string, material: Material, unitSize: number){
        super(object, hitbox, name, unitSize);
        this.defaultState = BoxWallState.window;
        this.material = material;
    }
    
    public getCopy(): BoxWindow {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxWindow(parentCopy.getObject(),parentCopy.getHitbox(), this.name, this.material, this.getUnitSize());
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }
    
    public setType(newType: WindowType) {
        this.name = newType.value;
    }

    public setMaterial (mat: Material) {
        this.material = mat;
    }

    public getMaterial (): Material {
        return this.material;
    }

    public getWallType (): WindowType {
        return WindowType.getEntryOf(this.name) as WindowType;
    }
}