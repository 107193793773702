<template>
    <div id="corpsPage">
        <div id="conteneurMillieu">
            <StepBanner />
            <div id="conteneurChoix">
                    <h2>Se baser sur un choix prédéfini</h2>
                    <div class="carousselInternalContainer">
                        <carousel :items-to-show="4" :transition="1000" :wrap-around="true">
                            <slide v-for="preset in presets" :key="preset.id">
                                <img class="carousselItem" draggable="false" :src="'/'+preset.src" @click="setTemplateChoiceAsPreset(preset.id)">
                            </slide>

                            <template #addons>
                                <navigation />
                                <pagination />
                            </template>
                        </carousel>
                </div>
                <div class="ouTexte">OU</div>
                <div>
                    <button class="btnNew" @click="setTemplateChoiceAsNew">Partir de zéro</button>
                </div>
            </div>
        </div>
  </div>
</template>

<script lang="ts">
import { ProjectManager } from "@/Managers/ProjectManager";
import { Options, Vue } from "vue-class-component";
import StepBanner from "../components/StepBanner.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import presetList from "../../configs/presets.json"
import { ProjectBox } from "@/project/ProjectBox";
import { ProjectBoxDeseresiazer } from "@/Desereasizer/ProjectBoxDeseresiazer";
import { LoadSaveHelper } from "@/helpers/LoadSaveHelper";

@Options({
    components: {
        StepBanner,
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    mounted () {
        ProjectManager.setCurrentStep(2);
    },
    methods: {
        setTemplateChoiceAsNew () {
            ProjectManager.getProject().isSet = true;
            ProjectManager.incrementSteps();
        },
        setTemplateChoiceAsPreset (presetId: number) {
            if (ProjectManager.getProject().blocks.length === 0 || confirm("Si vous choisisez un autre preset, toutes les boites placés seront remplacé. Voulez-vous continuer?")) {
                ProjectManager.getProject().isSet = true;
                ProjectManager.incrementSteps();
                const preset = presetList.find(presetItem => presetItem.id === presetId);
                preset?.blocks.forEach(boxItem => {
                    const box = new ProjectBox();
                    ProjectBoxDeseresiazer.toInstance(box, boxItem);
                    // box.internalId = internalID++;
                    // box.floorName = boxItem.floorName;
                    // box.frontFacingName = boxItem.frontFacingName;
                    // box.wallName = boxItem.wallName;
                    // box.windowName = boxItem.windowName;
                    // box.position = new Vector3(boxItem.positionX, boxItem.positionY, boxItem.positionZ);
                    ProjectManager.getProject().addBoxtoListBox(box);
                });
            }
        },
    }
})

export default class ChoixTemplateProjet extends Vue {
    presets = presetList;
    loadSaveHelper!: LoadSaveHelper;
}
</script>

<style lang="scss" scoped>
.btnNew{
    width: 16vh;
    height: 7vh;
    font-size: 1.9vh;
    font-weight: bolder;
    background-color: #A8CE6C;
    border-color: transparent;
    color: white;
    border-radius: 5vh;
}
#corpsPage
{
  display:flex;
  flex-direction:column;
  mix-blend-mode: luminosity;
  justify-content: center;
  align-items: center;
  height:95vh;
}
#conteneurMillieu
{
  background-image: url("/wood-grispale.jpg");
  width:100%;
  display:flex;
  flex-direction:column;
  margin-right:auto;
  margin-left:auto;
  height:100%;
}
#conteneurChoix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#conteneurChoix > div:not(.carousselInternalContainer) {
    align-self: center;
}

.carousselInternalContainer{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 100%;
    position: relative;
    align-self: inherit;
}
.carousselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
}
.ouTexte {
    margin: 5vh;
    font-size: 20px;
    font-weight: bolder;
    font-family: roboto;
}
h1 {
    font-family: roboto;
}
</style>