<template>
  <div class="menuContainer">
      <h1 class="menuTitle">Synthèse</h1>
      <div class="menuContent" v-if="isReady">
            <div class="infoContainer">
                <div class="menuItem">Prix total: {{ infoProjet.prix }}</div>
                <div class="menuItem">Performance totale: {{ infoProjet.performanceEcologique }}</div>
            </div>
            <input type="button" value="Réserver" class="btnMenuSelection" @click="reserver()">
      </div>
  </div>
</template>

<script lang="ts">
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { Options, Vue } from 'vue-class-component';


@Options({
    props: {
        infoProjet: SceneInfoProjet
    },
    methods: {
        reserver () {
            // this.loadSaveHelper.saveProject(this.save).then((success) => {
            //     if (success) {
            //         ToastNotificationsBus.emit(ToastEmitterOptionsType.success, { message: "Projet Sauvegardé" });
            //     }
            //     else {
            //         ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: "Il y a eu un problème avec la sauvegarde, veuillez contacter les administrateurs" });
            //     }
            // });
        },
        setupSceneWhenReady (scene: CustomScene) {
            this.scene = scene;
            this.isReady = true;
        }
    },
    created () {
        SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady)
    }
})
export default class SyntheseMenu extends Vue {
    scene!: CustomScene;
    isReady = false;
}
</script>

<style scoped>
    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        margin-left: 7%;
        margin-top: 2%;
    }
    .menuContent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .menuTitle {
        user-select: none;
        color:#A8CE6C!important;
        font-family: roboto;
        text-align:center;
        color: white;
        padding-left: 5px;
        font-size:20px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        border-bottom: 1px solid black;
    }
    .menuContainer {
        position: absolute;
        top: 30vh;
        border: 1px solid black;
        margin: 5px;
        background-color: white;
        font-family: roboto;
        width: 90%;
    }
</style>
