import { Project } from '@/project/Project'
import axios from 'axios'
import { Module } from 'vuex'

const save: Module<any, any> = {

    namespaced: true,
    
    state: {
    },

    getters: {
    },
    
    actions: {
        getAllSaves({ commit }) {
            return new Promise((resolve, reject) => {
              axios.request({
                url: '/all_projects',
                method: 'get',
                baseURL: process.env.VUE_APP_API_URL,
                headers: {
                  Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
                }
              }).then(response => {
                resolve(response)
              }).catch(err => {
                reject(err)
              })
            })
        },
        getSave({ commit }, saveId): Promise<Project> {
            return new Promise<Project>((resolve, reject) => {
              axios.request({
                url: '/project/' + saveId,
                method: 'get',
                baseURL: process.env.VUE_APP_API_URL,
                headers: {
                  Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
                },
              }).then(response => {
                resolve(response.data)
              }).catch(err => {
                reject(err)
              })
            })
        },
        saveProject({ commit }, saveBody) {
            return new Promise((resolve, reject) => {
              axios.request({
                url: '/save_project',
                method: 'post',
                baseURL: process.env.VUE_APP_API_URL,
                headers: {
                  Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
                },
                data: saveBody
              }).then(response => {
                resolve(response)
              }).catch(err => {
                reject(err)
              })
            })
        }
    },
    
}

export default save
