
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";

const namespace = "auth";


@Options({
  created() {
    this.requestUserDetails().then(utilisateur => {
      this.currentUser = ((utilisateur.data).user);
      this.user.prenom = this.currentUser.prenom;
      this.user.nom = this.currentUser.nom;
      this.user.email = this.currentUser.email;
    });
 },
  methods: {
    desactiveButton(id: string) {
      (document.getElementById(id) as HTMLButtonElement).disabled = true;
    },
    activeButton(id: string){
      (document.getElementById(id) as HTMLButtonElement).disabled = false;
    },
    validation(): boolean {
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    validationMdp(): boolean {
      this.submitted = true;
      this.v.$touch();
      let isValid = true;

      if (this.v.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    send() {
      this.erreurs = true;
      if (this.validation()) {
        this.desactiveButton("button");

        const formData = new FormData();

        formData.append("prenom", this.user.prenom);
        formData.append("nom", this.user.nom);
        formData.append("email", this.user.email);
       

        const object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        const json = object;

        this.updateUser(json)
          .then(() => {
            this.requestUserDetails().then(() => {
              location.reload();
            });
            ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
              message:
                "Félicitations! Formulaire enregistré avec succès",
              duration: 5000,
              unique: true
            });
          })
          .catch((error) => {
            if(error.response.data.password){
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Le mot de passe de confirmation est différent du mot de passe saisi",
                  duration: 3000,
                  unique: true
                });
            }else{
              ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Cette adresse e-mail est déjà utilisée",
              duration: 3000,
              unique: true
              });
            }
            this.activeButton("button");     
          });
      }
    },
    sendPassword() {
      this.erreurs_ = true;
      if (this.validationMdp()) {
        this.erreurs_ = true;
        this.desactiveButton("button_");
        const formData = new FormData();
        formData.append("password", this.user_.password);
        formData.append("password_confirmation", this.user_.passwordconfirmation)
        formData.append("ancienpassword", this.user_.ancienpassword)

        const object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        const json = object;
        this.getPassword(json).then(() => {
          this.updatePassword(json)
          .then(() => {
            this.requestUserDetails().then(() => {
              location.reload();
            });
            ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
              message:
                "Félicitations! Formulaire enregistré avec succès",
              duration: 5000,
              unique : true
            });
          })
          .catch(() => {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Veuillez utiler un mot de passe différent de l'ancien",
              duration: 3000,
              unique : true
            });
            this.activeButton("button_");
          });
        })
        .catch(() => {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Mot de passe saisi incorrect",
              duration: 3000,
              unique: true
            });
          this.activeButton("button_");
        });
      }
    },
  },
})
export default class Profil extends Vue {

    erreurs = false;
    erreurs_= false;

    currentUser = {};

    rules = {
      prenom: {required},
      nom: {required},
      email: {required, email},
    };

    user = reactive({
      prenom: "",
      nom: "",
      email: "",
    });

    vv = useVuelidate(this.rules, {
      prenom: toRef(this.user, "prenom"),
      nom: toRef(this.user, "nom"),
      email: toRef(this.user, "email"),
    });

    rules_ = {
      ancienpassword: {required},
      password: {required},
      passwordconfirmation: {required}
    }

    user_ = reactive({
      ancienpassword: "",
      password: "",
      passwordconfirmation: "",
    });

    v = useVuelidate(this.rules_, {
        password: toRef(this.user_, "password"),
        passwordconfirmation: toRef(this.user_, "passwordconfirmation"),
        ancienpassword: toRef(this.user_, "ancienpassword"),
    });

    @Action("updateUser", { namespace }) updateUser: any;
    @Action("updatePassword", { namespace }) updatePassword: any;
    @Action("getPassword", { namespace }) getPassword: any;
    @Action("requestUserDetails", { namespace }) requestUserDetails: any;

    submitted = false;
   

}

