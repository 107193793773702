import { EditableParameterEntry } from "./EditableParameterEntry";
import { EditableParameterType } from "./EditableParameterType";

export class EditableParameterEntryEnumItem {
    public key: any;
    public value: any;
    public displayedValue: string;
    public previewLocation: string

    constructor (key: any, value: any, displayedValue: string, previewLocation: string) {
        this.key = key;
        this.value = value;
        this.displayedValue = displayedValue;
        this.previewLocation = previewLocation;
    }
}

export class EditableParameterEntryEnum extends EditableParameterEntry {

    public enumItemList: EditableParameterEntryEnumItem[];

    public constructor(type: EditableParameterType, name: string, setter: string, nullable: boolean, needRefresh: boolean, enumItemList: EditableParameterEntryEnumItem[], refreshParameterName?: string){
        super(type, name, setter, nullable, needRefresh, refreshParameterName);
        this.enumItemList = enumItemList;
    }
}
