<template>
  <div class="container">
        <div class="categorieName"  @click="show = !show"><span class="categorieNameText">{{ groupTitle }}</span> <span v-if="show" class="menuFleche">-</span><span v-else class="menuFleche">+</span> </div>
        <div class="categoryContent" v-show="show">
            <slot name="content">
            </slot>
        </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    groupTitle: {
      type: String,
      require: true
    }
  },
  methods: {
    toggleCategoryVisibility (event: any) {
      const htmlElementToToggle = event.target.parentElement.children[1];
      if (htmlElementToToggle.style.display == "block"){
        htmlElementToToggle.style.display = "none";
      }
      else {
        htmlElementToToggle.style.display = "block";
      }
    },
    forceReRender () {
      this.$forceUpdate();
    }
  }
})
export default class MenuGroup extends Vue {
    show = false;
    symbole = "-";
}
</script>

<style scoped>
  .menuFleche {
    padding-right: 2%;
  }
  .container {
      width: 100%;
  }
  .categorieName{
    user-select: none;
    background-color:#B4B4B4!important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
  }
  .categorieName:hover{
      cursor: pointer;
  }
  .categorieName > span {
    font-family: roboto;
    text-align:center;
    color: white;
    font-size:20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .categoryContent {
    border: 1px solid #a3a3a3;
  }
</style>