<template>
   <div id="header">
     <div class="debutHeader">
      <a id="alogo" href="/"><img src="/myo-logo-blanc.png" alt="MYO" id="logo"/></a>
     </div>
     <div class="finHeader">
        <button size="md" id="compte" class="my-2 my-sm-0" @click="profil()">Mon profil</button>
        <button size="md" id="deco" class="my-2 my-sm-0" @click="deconnect()">Se déconnecter</button>
     </div>
    </div>
</template>


<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Action, Getter } from "vuex-class";

const namespace = "auth";

@Options({
  methods: {
    deconnect() {
      this.logout().then(() => {
        location.reload();
      });
    },
    profil() {
     this.$router.push("/profil");  
    },
    rapports() {
     this.$router.push("/rapports");  
    }
  },
})
export default class Header extends Vue {
  @Action("logout", { namespace }) logout: any;
  @Getter("auth/isLoggedIn") isLoggedIn: any;
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@400;700&display=swap');
</style>
<style scoped>
#header
{
  height:5vh;
  display:flex;
  justify-content: space-between;
  flex-direction:row;
  width:100%;
  background-color:#202224;
  align-items:center;
  flex-grow: 1;
  color:#A3A3A3;
}
button
{
  font-family: roboto;
  font-size:15px;
  padding:0px;
  border-radius:50px;
  border:none;
  background-color:#202224;
  color:#A3A3A3;
  width: 8em;
}
#logo {
  height: 100%;
}
#alogo {
  height: 100%;
}
.debutHeader {
  height: 75%;
  margin-left: 3vh;
}
.finHeader {
  height: 100%;
  display: flex;
  flex-direction: row;
}
button:hover
{
  color:white;
  cursor:pointer;
}
button:target
{
  border:none;
}
#deco
{
  margin-right:10%;
}

</style>