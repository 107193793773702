import { CustomEnum } from "@/CustomEnums/CustomEnum";
import { EditableParameterEntry } from "./EditableParameterEntry";
import { EditableParameterEntryEnum, EditableParameterEntryEnumItem } from "./EditableParameterEntryEnum";
import { EditableParameterType } from "./EditableParameterType";

const EDITABLE_FIELDS_BY_CLASS = new Map<string,EditableParameterEntry[]>();

interface EditableParameterParameters {
    setter: string;
    type: EditableParameterType;
    nullable: boolean;
    needRefresh?: boolean;
    refreshMethodName?: string;
    enumClass?: any;
}

function generateEditableParameterEntry (className: string, fieldName: string) {
    return (EDITABLE_FIELDS_BY_CLASS.get(className) as EditableParameterEntry[]).filter((entry) => entry.name == fieldName)[0];
}

export function ifFieldEditable(object: any, fieldName: string): undefined | EditableParameterEntry {
    let classToSearch = object;
    while(classToSearch != null) {
        const classHasEditableFields = EDITABLE_FIELDS_BY_CLASS.has(classToSearch.constructor.name)
        if(classHasEditableFields) {
            return generateEditableParameterEntry(classToSearch.constructor.name as string, fieldName);
        }
        classToSearch = Object.getPrototypeOf(classToSearch);
    }
    return undefined;
}

export function EditableParameter(params: EditableParameterParameters) {
    return (target: any, name: string) => {
        const clsName = target.constructor.name;
        let list: EditableParameterEntry[];
        if (EDITABLE_FIELDS_BY_CLASS.has(clsName)) {
            list = EDITABLE_FIELDS_BY_CLASS.get(clsName) as [];
        } else {
            list = [];
            EDITABLE_FIELDS_BY_CLASS.set(clsName, list);
        }
        if(params.needRefresh == undefined) {
            params.needRefresh = false;
        }
        else if(params.refreshMethodName == undefined){
            throw new Error("parameter RefreshMethodName is required with parameter NedRefresh");
        }
        if (params.type == EditableParameterType.enum) {
            if (params.enumClass == undefined) {
                throw new Error("parameter EnumClass is required with parameter EditableParameterType");
            }
            let enumItems: EditableParameterEntryEnumItem[];
            if (params.enumClass.prototype && params.enumClass.prototype instanceof CustomEnum) {
                enumItems = params.enumClass.getPossibleEntrys().map(item => {
                    return new EditableParameterEntryEnumItem(item.key, item.value, item.value, item.preview)
                });
            }
            else {
                enumItems = Object.keys(params.enumClass).filter(key => isNaN(Number(key))).map(key => {
                    return new EditableParameterEntryEnumItem(key,params.enumClass[key],key, "");
                });
            }
            list.push(new EditableParameterEntryEnum(params.type, name, params.setter, params.nullable, params.needRefresh, enumItems, params.refreshMethodName));
        }
        else {
        list.push(new EditableParameterEntry(params.type, name, params.setter, params.nullable, params.needRefresh, params.refreshMethodName));
        }
    }
}