
import { Options, Vue } from 'vue-class-component';


@Options({
    props: {
        onChangeFunction: Function,
        checked: {
            type: Boolean,
            default: false
        }
    },
    methods: {
    },
})
export default class ToggleSwitch extends Vue {
}
