
import { Options, Vue } from 'vue-class-component';
import { EventBus } from '../events/EventBus';
import { EventType } from '../events/EventType';
import { Box } from '@/Box/Box';
import { ObjectParameter, ObjectParametersAsMapGetter } from '../helpers/ObjectParametersAsMapGetter';
import SelectedBoxMenuParameter from '@/components/SelectedBoxMenuParameter.vue';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import MenuOptionsProjet from '@/components/MenuOptionsProjet.vue';
import SyntheseMenu from '@/components/SyntheseMenu.vue';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { CustomScene } from '@/scene/CustomScene';
import { FirstPersonViewManager } from '@/Managers/FirstPersonViewManager';
import Modal from '@/components/Modal.vue';
import SelectionMenuStep3 from '@/components/SelectionMenuBySteps/SelectionMenuStep3.vue';
import SelectionMenuStep4 from '@/components/SelectionMenuBySteps/SelectionMenuStep4.vue';
import SelectionMenuStep5 from '@/components/SelectionMenuBySteps/SelectionMenuStep5.vue';
import SelectionMenuStep6 from '@/components/SelectionMenuBySteps/SelectionMenuStep6.vue';
import SelectionMenuStep7 from '@/components/SelectionMenuBySteps/SelectionMenuStep7.vue';
import { ProjectManager } from '@/Managers/ProjectManager';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { StairsManager } from '@/Managers/StairsManager';

@Options<SideMenu>({
  props: {
    repositorys: Map,
    object3DFactory: Object3DFromBoxFactory,
    selectionManager: SelectionManager,
    infoProjet: SceneInfoProjet
  },
  components: {
    SelectedBoxMenuParameter,
    MenuOptionsProjet,
    Modal,
    SelectionMenuStep3,
    SelectionMenuStep4,
    SelectionMenuStep5,
    SelectionMenuStep6,
    SelectionMenuStep7,
    SyntheseMenu
  },
  methods: {
    seeFromInside () {
      this.firstPersonViewManager.seeInsideBox(this.selectionManager.getSelectedBox());
    },
    getParam (name: string): ObjectParameter {
      return this.params.get(name);
    },
    updateLocalSelectedBox() {
      this.selectedBox = undefined;
      if (this.selectionManager.isThereABoxSelected()) {
        this.selectedBox = this.selectionManager.getSelectedBox();
        this.updateParamOfSelectedObject();
      }
      else {
        this.renderSelectedBox = false;
      }
      this.$forceUpdate();
    },
    updateStepsRelatedObjects () {
      this.updateLocalSelectedBox();
      this.currentStep = ProjectManager.getCurrentStep();
    },
    updateParamOfSelectedObject() {
      this.params = (this.objectParametersAsMapGetter.getParameters(this.selectedBox) as Map<string, ObjectParameter>);
      this.paramsGenerated = true;
      if (this.params.size === 0) {
        this.renderSelectedBox = false;
      }
      else {
        this.renderSelectedBox = true;
      }
      // const rotation = params[0];
      // const boxName = params[2];
      // params[2] = rotation;
      // params[0] = boxName;
    },
    updateSelectedBox() {
      this.selectedBox = this.selectionManager.getSelectedBox();
      this.updateParamOfSelectedObject();
      this.$forceUpdate();
    },
    valueChanged (item: any, paramName) {
      if (item.key !== undefined) {
        this.selectedBox[this.getParam(paramName).editable.setter](item.key);
      }
      else {
        this.selectedBox[this.getParam(paramName).editable.setter](item);
      }

      if(this.getParam(paramName).editable.needRefresh) {
        //const newObj3D = this.object3DFactory.getNewObject3DFromBox(this.selectedBox);
        // EventBus.emit(EventType.beforeBoxRefreshed, this.selectedBox);
        // this.selectedBox[editableParameterEntry.refreshMethodName as string](newObj3D);
        EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectedBox);
        this.$forceUpdate();
      }
    },
    setupSceneWhenReady (scene: CustomScene) {
      this.scene = scene;
    },
    setupFirstPersonViewManagerWhenReady (firstPersonViewManager: FirstPersonViewManager) {
      this.firstPersonViewManager = firstPersonViewManager;
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.updateLocalSelectedBox);
    EventBus.on(EventType.removedSelectedBox, this.updateLocalSelectedBox);
    EventBus.on(EventType.stepChanged, this.updateStepsRelatedObjects);
    this.updateStepsRelatedObjects();
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady);
    SceneCommunicationBus.whenInstanceIsReady(StairsManager, (stairsManager: StairsManager) => { this.stairsManager = stairsManager; });
    SceneCommunicationBus.whenInstanceIsReady(FirstPersonViewManager, this.setupFirstPersonViewManagerWhenReady);
  }
})
export default class SideMenu extends Vue {
  selectedBox?: Box;
  renderSelectedBox = false;
  objectParametersAsMapGetter: ObjectParametersAsMapGetter = new ObjectParametersAsMapGetter();
  params?: Map<string, ObjectParameter>;
  scene!: CustomScene;
  firstPersonViewManager!: FirstPersonViewManager;
  currentStep: number = 3;
  paramsGenerated = false;
}
