
import { Box } from '@/Box/Box';
import { BoxMiddleFloor } from '@/Box/BoxParts/BoxMiddleFloor';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { BoxWallBit } from '@/Box/BoxParts/BoxWall/BoxWallBit';
import { Object3DTrueScaleCalculator } from '@/calculators/Object3DTrueScaleCalculator';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { ProjectManager } from '@/Managers/ProjectManager';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { BoxRepository } from '@/repositorys/BoxRepository';
import { FloorRepository } from '@/repositorys/FloorRepository';
import { FrontFacingRepository } from '@/repositorys/FrontFacingRepository';
import { MiddleFloorRepository } from '@/repositorys/MiddleFloorRepository';
import { SideMiddleFloorRepository } from '@/repositorys/SideMiddleFloorRepository';
import { WallBitRepository } from '@/repositorys/WallBitRepository';
import { WallRepository } from '@/repositorys/WallRepository';
import { WindowsRepository } from '@/repositorys/WindowsRepository';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneOptions } from '@/scene/SceneOptions';
import { ToastEmitterOptionsType } from '@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType';
import { ToastNotificationsBus } from '@/ToastNotifications/ToastNotificationsBus';
import { Options, Vue } from 'vue-class-component';
import { UIDebugParams } from '../debugTools/UIDebugParams';

@Options({
  props: {
    debugParams: UIDebugParams,
    repositorys: Map,
    selectionManager: SelectionManager
  },
  methods: {
    floorUp(){
      EventBus.emit(EventType.floorUp);
    },
    floorDown(){
      EventBus.emit(EventType.floorDown);
    },
    setLight(event: any) {
      const valeur: number = event.target.value;
      EventBus.emit(EventType.changeLight, valeur);
    },
    setShowAimDot (value: boolean) {
      this.showAimDot = value;
    },
    whenOptionChange () {
      this.showDebugInfo = this.options.debugMode;
    },
    updateFloorBtns () {
      this.$refs.btnfloor1.classList.remove("etageBtn-active");
      this.$refs.btnfloor2.classList.remove("etageBtn-active");
      const currentFloor = this.scene.getGrid().getEtageActif();
      if(currentFloor === 0) {
        this.$refs.btnfloor1.classList.add("etageBtn-active");
      }
      else {
        this.$refs.btnfloor2.classList.add("etageBtn-active");
      }
    },
    whenSceneIsReady (scene: CustomScene) {
      this.scene = scene;
      if(this.hasMounded) {
        this.updateFloorBtns();
        this.hasMounded = false;
      }
    },
    addPreviewToMouse (idPreset) {
        const nomObjet = "Cuisine";
        let floorName = "";
        let frontFacingName = "";
        let wallName = "";
        let windowName = "";
        switch (idPreset){
          case 0: {
              floorName = "floor1";
              frontFacingName = "Blanc_frontFacing1";
              wallName = "RC404";
              windowName = "Aluminium_fenetre 1 pièce";
              break;
          }
          case 1: {
              floorName = "floor1";
              frontFacingName = "Anthracite_frontFacing1";
              wallName = "RC862";
              windowName = "Anthracite_fenetre 3 pièce";
              break;
          }
          case 2: {
              floorName = "floor1";
              frontFacingName = "Anthracite_frontFacing1";
              wallName = "RT1432";
              windowName = "Aluminium_fenetre 1 pièce";
              break;
          }
        }
        if (!this.selectionManager.getIfCanChangeSelectedBox()) {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: "Il faut placer la boite avant" });
        }
        else {
          if(this.selectionManager.isThereABoxSelected()) {
              this.selectionManager.clearSelectedBox();
          }
          const box = this.repositorys.get(BoxRepository.name).getCopyByName(nomObjet, true) as Box;
          const walls = new Array<BoxWall>();
          const wallRepository = this.repositorys.get(WallRepository.name);
          const windowRepository = this.repositorys.get(WindowsRepository.name);
          const floorRepository = this.repositorys.get(FloorRepository.name);
          const frontFacingRepository = this.repositorys.get(FrontFacingRepository.name);
          const middleFloorRepository = this.repositorys.get(MiddleFloorRepository.name);
          const sideMiddleFloorRepository = this.repositorys.get(SideMiddleFloorRepository.name);
          const wallBitRepository = this.repositorys.get(WallBitRepository.name);
          box.setFloor(floorRepository.getCopyByName(floorName));
          box.setScale(this.object3DTrueScaleCalculator.getTrueScale(box.getFloor().getObject(), box.getFloor().getObject().scale));
          box.setFrontFacing(frontFacingRepository.getCopyByName(frontFacingName));
          const wallBits = new Array<BoxWallBit>();
          for(let x = 0; x < 2; x++) {
              wallBits.push(wallBitRepository.getCopyByName("wallBit1"));
          }
          box.setWallBits(wallBits);
          const middleFloor = new Array<BoxMiddleFloor>();
          middleFloor.push(sideMiddleFloorRepository.getCopyByName("side_middleFloor1"));
          for(let x = 0; x < 3; x++) {
              middleFloor.push(middleFloorRepository.getCopyByName("middleFloor1"));
          }
          box.setBoxMiddleFloors(middleFloor);
          for(let x = 0; x < 9; x++) {
              walls.push(wallRepository.getCopyByName(wallName));
          }
          for(let x = 0; x < 3; x++) {
              walls.push(windowRepository.getCopyByName(windowName));
          }
          box.setWalls(walls);
          EventBus.emit(EventType.addPreviewObjectToScene, box)
        }
      }
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(SceneOptions, (options) => { this.options = options });
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.whenSceneIsReady);
    EventBus.on(EventType.firstPersonStarted, () => { this.setShowAimDot(true); });
    EventBus.on(EventType.floorUpdated, this.updateFloorBtns);
    EventBus.on(EventType.firstPersonEnded, () => { this.setShowAimDot(false); });
    EventBus.on(EventType.optionChanged, () => { this.whenOptionChange(false); });
    EventBus.on(EventType.stepChanged, () => { this.currentStep = ProjectManager.getCurrentStep();  });
  },
  mounted () {
    this.hasMounded = true;
    if (this.scene !== undefined) {
      this.updateFloorBtns();
    }
  }
})
export default class SceneUI extends Vue {
  showAimDot = false;
  showDebugInfo = false;
  options!: SceneOptions;
  currentStep = 3;
  scene: CustomScene | undefined = undefined;
  hasMounded = false;
  object3DTrueScaleCalculator: Object3DTrueScaleCalculator = new Object3DTrueScaleCalculator();
}
