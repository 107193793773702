import { RoomType } from "@/CustomEnums/RoomType";
import { BoxHelper, Object3D } from "three";
import { BoxRotationDirection } from "../BoxRotationDirection";
import { BoxPart } from "./BoxPart";

export class BoxRoom extends BoxPart {

    private rotation: BoxRotationDirection;
    private price: number;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string, price: number) {
        super (object, hitbox, name);
        this.rotation = BoxRotationDirection.Sud;
        this.price = price;
    }

    public setRotation(value: BoxRotationDirection) {
        super.setRotation(value);
        this.rotation = value;
    }
    
    public getRotation(): BoxRotationDirection {
        return this.rotation;
    }

    public getPrice(): number {
        return this.price;
    }

    public getCopy(): BoxRoom {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxRoom(parentCopy.getObject(),parentCopy.getHitbox(), this.name, this.price);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }

    public setRoomType (value: RoomType) {
        this.name = value.key;
    }

    public getRoomType (): RoomType {
        return RoomType.getEntryOf(this.name) as RoomType;
    }
}