<template>
    <div class="Conteneur">
        <div class="button">
          <button size="md" id="rapport" class="my-2 my-sm-0" @click="rapports()">Rapports</button>
        </div>
        <div class="table-row heading">
            <div class="row-item"><p>Prénom</p></div>
            <div class="row-item"><p>Nom</p></div>
            <div class="row-item"><p>Adresse courriel</p></div>
            <div class="row-item"><p>Actions</p></div>
        </div>
        <div v-for="(user,i) in users" :key="i" class="table-row" >
            <div class="row-item"><p>{{user.prenom}}</p></div>
            <div class="row-item"><p>{{user.nom}}</p></div>
            <div class="row-item"><p>{{user.email}}</p></div>
            <div class="row-item"><button @click="getUser(user.id)">Voir</button></div>          
        </div>
        <div>
          <modal ref="userModal">
            <template v-slot:header>
              <h1>Utilisateur numéro:{{userSelectionne.id}} </h1>
            </template>
            <template v-slot:body>
              <div class="row-item"><p>Prénom : {{userSelectionne.prenom}}</p></div>
              <div class="row-item"><p>Nom : {{userSelectionne.nom}}</p></div>
              <div class="row-item"><p>Adresse courriel : {{userSelectionne.email}}</p></div>
              <div class="row-item"><p>Catégorie : {{userSelectionne.categorie}}</p></div>
            </template>
            <template v-slot:footer>
              <div>
                <button @click="$refs.userModal.closeModal()">Fermer</button>
              </div>
            </template>
          </modal>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import Modal from '@/components/Modal.vue';

const namespace = "user";

@Options({
  components: {
    Modal
  },
  created() {
    this.getAllUser().then(users => {
      this.users = users.data;
    });
  },
  methods: { 
    getUser(id){
      this.getUserById(id).then(user => {
        this.$refs.userModal.openModal();
        this.userSelectionne = (user.data)[0];
      });
    },
    rapports() {
     this.$router.push("/rapports");  
    }
  },
})
export default class PageAdmin extends Vue {
  @Action("getAllUser", { namespace: "user" }) getAllUser: any;
  @Action("getUserById", { namespace: "user" }) getUserById: any;

  users = {}; 
  userSelectionne = {};
  
}
</script>


<style scoped>
/* just a reset of the css */
body {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica';
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.table-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #DADADA;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.heading {
  background-color: #ececec;
  color: #3e3e3e;
  font-weight: bold;
}

.row-item {
  display: flex;
  flex: 1;
  font-size: 14px;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.row-item:hover {
  cursor: pointer;
  background-color: #F0F0F0;
/*   box-shadow: 0px 1px 4px rgba(0, 0, 0, .08); */
}

.row-sub-container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.row-sub-container .row-item {
  padding: 8px 0;
  border-bottom: 1px solid #dadada;
}

.table-row:last-child,
.row-sub-container .row-item:last-child {
  border-bottom: 0;
}

div {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

div button {
  width: 20%;
}

.button{
  text-align: center;
}

.button button {
  width: 5%;
  margin: 10px;
}
</style>











