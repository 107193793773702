import { ProjectBox } from '../project/ProjectBox';
import { Repository } from '@/repositorys/Repository';
import { Project } from '../project/Project';
import { CustomScene } from '@/scene/CustomScene';
import { Box } from '@/Box/Box';
import { BoxMiddleFloor } from '@/Box/BoxParts/BoxMiddleFloor';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { BoxRepository } from '@/repositorys/BoxRepository';
import { FloorRepository } from '@/repositorys/FloorRepository';
import { FrontFacingRepository } from '@/repositorys/FrontFacingRepository';
import { MiddleFloorRepository } from '@/repositorys/MiddleFloorRepository';
import { SideMiddleFloorRepository } from '@/repositorys/SideMiddleFloorRepository';
import { WallRepository } from '@/repositorys/WallRepository';
import { WindowsRepository } from '@/repositorys/WindowsRepository';
import { WallBitRepository } from '@/repositorys/WallBitRepository';
import { BoxWallBit } from '@/Box/BoxParts/BoxWall/BoxWallBit';
import { Object3DTrueScaleCalculator } from '@/calculators/Object3DTrueScaleCalculator';
import { ProjectManager } from '@/Managers/ProjectManager';
import { Vector3 } from 'three';
import { ProjectBoxWallGroup } from '@/project/ProjectBoxWallGroup';
import { ProjectBoxWallGroupWall } from '@/project/ProjectBoxWallGroupWall';
import { BoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { FrontFacingMaterial } from '@/CustomEnums/FrontFacingMaterial';
import { BoxWindow } from '@/Box/BoxParts/BoxWall/BoxWindow';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { ToastNotificationsBus } from '@/ToastNotifications/ToastNotificationsBus';
import { ToastEmitterOptionsType } from '@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType';


export class LoadSaveHelper {
    private object3DTrueScaleCalculator: Object3DTrueScaleCalculator;
    private scene!: CustomScene;
    private repositorys!: Map<string,Repository<any>>;
    private nbObjectTotal?: number;
    private loadedObject: Array<[Box, Vector3]>;

    public constructor(){
        this.loadedObject = new Array<[Box, Vector3]>();
        this.object3DTrueScaleCalculator = new Object3DTrueScaleCalculator();
    }

    public setScene (scene: CustomScene) {
        this.scene = scene;
    }

    public setRepositorys (repositorys: Map<string,Repository<any>>) {
        this.repositorys = repositorys;
    }

    public getLoadingPercentage(): number{
        if(this.nbObjectTotal !== undefined && this.nbObjectTotal !== 0) {
            return this.loadedObject.length / this.nbObjectTotal * 100;
        }
        else if(this.nbObjectTotal === 0){
            return 100;
        }
        else {
            return 0;
        }
    }

    private verifyIfEndLoading () {
        if (this.loadedObject.length == this.nbObjectTotal) {
            EventBus.emit(EventType.endLoading);
        }
    }

    public async initialise(projet: Project) {
        EventBus.emit(EventType.beginLoading);
        this.loadSave(projet);
    }

    public saveProject (saveFunction: Function, showMessage = false): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if(showMessage) {
                ToastNotificationsBus.emit(ToastEmitterOptionsType.success, { message: "Sauvegarde en cours" });
            }
            const project = ProjectManager.getProject();
            project.clearBlockList();
            if(this.scene !== undefined){
                this.scene.getSceneObjects().forEach((boxItem: Box) => {
                    const box = new ProjectBox();
                    box.floorName = boxItem.getFloor().getName();
    
                    let frontFacingTrueName = boxItem.getFrontFacing().getName();
                    if (boxItem.getFrontFacing()["material"] != null) {
                        frontFacingTrueName = (boxItem.getFrontFacing()["material"] as FrontFacingMaterial).key + "_" + frontFacingTrueName;
                    }
                    box.frontFacingName = frontFacingTrueName;
                    box.wallName = boxItem.getWallType().key;
                    box.windowName = boxItem.getWindowMaterial().key + "_" + boxItem.getWindowType().key;
                    const boxPosition = this.scene.getGrid().getGridPositionFromBox(boxItem) as Vector3;
                    box.position = boxPosition;
                    box.positionX = boxPosition.x;
                    box.positionY = boxPosition.y;
                    box.positionZ = boxPosition.z;
                    box.rotation = boxItem.getRotation()* 180/ Math.PI;
                    box.internalId = boxItem.getId();
                    box.boxName = boxItem.getName();
                    box.roomType = null;
                    const roomType = boxItem.getRoomType();
                    if(roomType !== undefined) {
                        box.roomType = roomType.key;
                    }
                    const wallGroupArray = new Array<ProjectBoxWallGroup>();
                    const wallGroups = boxItem.getWalls();
                    for(let x = 0; x < wallGroups.length; x++){
                        const wallGroup = wallGroups[x];
                        const wallGroupToInsert = new ProjectBoxWallGroup();
                        const wallsToInsert = new Array<ProjectBoxWallGroupWall>();
                        wallGroupToInsert.position = x;
                        for(let y = 0; y < wallGroup.length; y++){
                            const wall = wallGroup[y];
                            if (wall.hasForcedState()) {
                                const wallToInsert = new ProjectBoxWallGroupWall();
                                let wallTrueName = wall.getName();
                                if (wall["material"] != null) {
                                    wallTrueName = (wall as BoxWindow).getMaterial().key + "_"  + (wall as BoxWindow).getWallType().key;
                                }
                                wallToInsert.name = wallTrueName;
                                switch (wall.getForcedState()) {
                                    case BoxWallState.wall: {
                                        wallToInsert.forcedState = "wall";
                                        break;
                                    }
                                    case BoxWallState.window: {
                                        wallToInsert.forcedState = "window";
                                        break;
                                    }
                                    case BoxWallState.none: {
                                        wallToInsert.forcedState = "none";
                                        break;
                                    }
                                    default: {
                                        wallToInsert.forcedState = null;
                                        break;
                                    }
                                }
                                wallToInsert.position = y;
                                wallsToInsert.push(wallToInsert);
                            }
                        }
                        wallGroupToInsert.walls = wallsToInsert;
                        if(wallGroupToInsert.walls.length > 0) {
                            wallGroupArray.push(wallGroupToInsert);
                        }
                    }
                    // eslint-disable-next-line
                    box.wall_group = wallGroupArray;
                    project.addBoxtoListBox(box);
                });
            }
            saveFunction(project).then(response => {
                project.id = response.data["id"];
                if(showMessage) {
                    ToastNotificationsBus.emit(ToastEmitterOptionsType.success, { message: "Projet Sauvegardé", unique: true });
                }
                resolve(true);
            }).catch(() => {
                if(showMessage) {
                    ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: "Il y a eu un problème avec la sauvegarde, veuillez contacter les administrateurs", unique: true });
                }
                reject(false);
            });
        });
    }

    private loadSave(projet: Project){
        projet.isSet = true;
        this.nbObjectTotal = projet.blocks.length;
        const boxRepository = this.repositorys.get(BoxRepository.name) as BoxRepository;
        const wallRepository = this.repositorys.get(WallRepository.name);
        const windowRepository = this.repositorys.get(WindowsRepository.name);
        const floorRepository = this.repositorys.get(FloorRepository.name);
        const frontFacingRepository = this.repositorys.get(FrontFacingRepository.name);
        const middleFloorRepository = this.repositorys.get(MiddleFloorRepository.name);
        const sideMiddleFloorRepository = this.repositorys.get(SideMiddleFloorRepository.name);
        const wallBitRepository = this.repositorys.get(WallBitRepository.name);
        let newId = -1;
        projet.blocks.forEach(boxDTO => {
            if (boxDTO.internalId !== null && boxDTO.internalId > newId) {
                newId = boxDTO.internalId;
            }
        });
        boxRepository.setCurrentID(newId+1);
        projet.blocks.forEach(boxDTO => {
            const nomObjet = "Cuisine";
            const box = boxRepository.getCopyByName(nomObjet, boxDTO.internalId as number) as Box;
            const walls = new Array<BoxWall>(12);
            box.setFloor(floorRepository!.getCopyByName(boxDTO.floorName as string));
            box.setScale(this.object3DTrueScaleCalculator.getTrueScale(box.getFloor().getObject(), box.getFloor().getObject().scale));
            const test = frontFacingRepository!.getCopyByName(boxDTO.frontFacingName as string);
            box.setFrontFacing(test);
            const wallBits = new Array<BoxWallBit>();
            for(let x = 0; x < 2; x++) {
                wallBits.push(wallBitRepository!.getCopyByName("wallBit1"));
            }
            box.setWallBits(wallBits);
            const middleFloor = new Array<BoxMiddleFloor>();
            middleFloor.push(sideMiddleFloorRepository!.getCopyByName("side_middleFloor1"));
            for(let x = 0; x < 3; x++) {
                middleFloor.push(middleFloorRepository!.getCopyByName("middleFloor1"));
            }
            box.setBoxMiddleFloors(middleFloor);
            if(boxDTO.wall_group !== null) {
                for (let x = 0; x < boxDTO.wall_group.length; x++) {
                    const wallGroup = boxDTO.wall_group[x].walls as ProjectBoxWallGroupWall[];
                    for (let y = 0; y < wallGroup.length; y++) {
                        const wallItem = wallGroup[y];
                        const calculatedPosition = (wallItem.position as number) + (boxDTO.wall_group[x].position as number) * 3;
                        switch (wallItem.forcedState) {
                            case "wall": {
                                walls[calculatedPosition] = (wallRepository!.getCopyByName(wallItem.name as string));
                                break;
                            }
                            case "window": {
                                walls[calculatedPosition] = (windowRepository!.getCopyByName(wallItem.name as string));
                                break;
                            }
                            case "none": {
                                let item = (windowRepository!.getCopyByName(wallItem.name as string));
                                if (item === undefined) {
                                    item = (wallRepository!.getCopyByName(wallItem.name as string));
                                }
                                walls[calculatedPosition] = item;
                                break;
                            }
                        }
                        walls[calculatedPosition].setForcedState(BoxWallState[wallItem.forcedState as string]);
                    }
                    // walls.push(wallRepository!.getCopyByName(boxDTO.wallName as string));
                    // boxDTO.wall_group
                }
            }
            for(let x = 0; x < 9; x++) {
                if (walls[x] === undefined) {
                    walls[x] = (wallRepository!.getCopyByName(boxDTO.wallName as string));
                }
            }
            for(let x = 9; x < 12; x++) {
                if (walls[x] === undefined) {
                    walls[x] = (windowRepository!.getCopyByName(boxDTO.windowName as string));
                }
            }
            box.setWalls(walls);
            this.loadedObject.push([box, boxDTO.position]);
            box.setRotation(boxDTO.rotation as number * Math.PI/180);
            this.verifyIfEndLoading();
        });
        if(this.nbObjectTotal === 0) {
            this.verifyIfEndLoading();
        }
    }

    public addLoadedObjectsToScene () {
        this.loadedObject.forEach((combo: [Box, Vector3]) => {
            this.scene.ajouterBox(combo[0], combo[1]);
        })
    }
}