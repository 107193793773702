import { Box } from '@/Box/Box';
import { ProjectBox } from './ProjectBox';
import { ProjectRapport } from './ProjectRapport';

export class Project {
    
    public isSet: boolean = false;
    public blocks: Array<ProjectBox>;
    public id: number | null = null;
    public rapport: ProjectRapport | null = null;

    public constructor(){
        this.isSet = false;
        this.blocks = new Array<ProjectBox>();
    }

    public updateBoxesFromBoxList(boxList: Box[]){
        this.blocks = new Array<ProjectBox>();
        boxList.forEach(box => {
            const boxDTO = new ProjectBox();
            boxDTO.floorName = box.getFloor().getName();
            boxDTO.frontFacingName = box.getFrontFacing().getName();
            boxDTO.wallName = box.getWallType().key;
            boxDTO.windowName = box.getWindowType().key + "_" + box.getWindowMaterial().key;
            boxDTO.position = box.getPosition();
            this.blocks.push(boxDTO)
        });
    }

    public clearBlockList () {
        this.blocks = new Array<ProjectBox>();
    }

    public addBoxtoListBox(box: ProjectBox) {
        this.blocks.push(box);
    }
}