<template>
  <div>
    <div class="ConteneurParent">
      <div id="ConteneurMillieu">
        <div id="ConteneurGauche" class="element-ConteneurMillieu">
          <img src="@/assets/IMG/ban.png" alt="Logo MYO" id="ban" />
        </div>
        <div id="ConteneurDroite" class="element-ConteneurMillieu">
          <p class="registrer-page-login">
            Vous avez déja un compte ? <a href="/">Connectez-vous</a>
          </p>
          <img src="@/assets/myologo.png" alt="Logo MYO" id="Logo" width="250" />
          <div class="box">
            <h1 class="Welcome">S'inscrire</h1>
            <form id="register-form" @submit.prevent="send" :class="erreurs ? 'erreurs' : false">
              <div id="form-group-name">
                <div class="form-group-name5">
                  <div>
                    <label for="prenom"> Prénom<span class="etoileform"> *</span></label>
                  </div>
                  <input type="prenom" v-model="vv.prenom.$model" id="prenom" name="prenom" class="form-control" :class="{ 'is-invalid': submitted && vv.prenom.$error }" required @invalid="send"/>
                </div>
                <div class="form-group-name5">
                  <div>
                    <label for="nom">Nom<span class="etoileform"> *</span></label>
                  </div>
                  <input type="nom" v-model="vv.nom.$model" id="nom" name="nom" class="form-control" :class="{ 'is-invalid': submitted && vv.nom.$error }" required @invalid="send"/>
                </div>
              </div>
              <div class="form-group">
                <div>
                  <label for="email">Adresse courriel<span class="etoileform"> *</span></label>
                </div>
                <input type="email" v-model="vv.email.$model" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && vv.email.$error }" required @invalid="send"/>
              </div>
              <div id="form-group-motdepasse">
                <div class="form-group-pw1">
                  <label id="passwordlabel" for="password">Mot de passe<span class="etoileform"> *</span></label>
                  <input type="password" v-model="vv.password.$model" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && vv.password.$error }" required @invalid="send"/>
                </div>
                <div class="form-group-pw2">
                  <label for="confirmPassword">Confirmation du mot de passe<span class="etoileform"> *</span></label>
                  <input type="password" v-model="vv.confirmPassword.$model" id="confirmPassword" name="confirmPassword" class="form-control" :class="{'is-invalid': submitted && vv.confirmPassword.$error}" required @invalid="send"/>
                </div>
                <div v-if="errors.length" class="invalid-feedback">
                  <p v-for="error in errors" :key="error">
                    {{ error }}
                  </p>
                </div> 
              </div>
              <div class="form-group">
                <div class="form-group-interieur">
                  <input type="checkbox" v-model="vv.box.$model" id="box" name="box" class="form-control" :class="{ 'is-invalid': submitted && vv.box.$error }" required/>
                    En vous inscrivant, vous acceptez les conditions de politique de confidentialité de M.Y.O.
                 </div>
                </div>
              <div class="form-group btn btn-primary">
                <div class="boutondiv">
                 <button id="button" type="submit">S'INSCRIRE</button>
                </div>
              </div>
            </form>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { load } from 'recaptcha-v3'

const namespace = "auth";

@Options({
  data(){
    return {
      erreurs: false,
    };
  },
  mounted(){
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
    useRecaptchaNet: true
    }).then((recaptcha) => {
      this.recaptcha = recaptcha;
    })
  },
  methods: {
    validation(): boolean {
      this.errors = [];
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.user.confirmPassword != "") {
        if (this.user.password != this.user.confirmPassword) {
          this.errors.push("Les mots de passe doivent être identiques");
          isValid = false;
        }
      }

      if (this.user.box == false) {
        this.errorValidateCondition.push(
          "Veuillez accepter les conditions de politique de confidentialité de M.Y.O"
        );
        isValid = false;
      }

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },
    send() {
      this.recaptcha.execute("send").then((token) => {
        this.erreurs = true;
        if (this.validation()) {
          this.desactiveButton();

          const formData = new FormData();
          formData.append("nom", this.user.nom);
          formData.append("prenom", this.user.prenom);
          formData.append("email", this.user.email);
          formData.append("password", this.user.password);
          formData.append("token", token);

          const object = {};
          formData.forEach((value, key) => {
            object[key] = value;
          });
          const json = object;
          this.register(json)
            .then(() => {
              ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
                message:
                  "Félicitations! Vous êtes maintenant inscrit(e)! Veuillez vous connecter.",
                duration: 5000,
                unique: true
              });
              this.$router.push("/");
            })
            .catch((error) => {
              if(error.response.data.email){
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Cette adresse e-mail est déjà utilisée",
                  duration: 3000,
                  unique: true
                });
              }else{
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Une erreur s'est produite lors de la validation du formulaire, veuillez vérifier chaque champ et réessayez de nouveau.",
                  duration: 3000,
                  unique: true
                });
              }
              this.activeButton();
            });
        }
      });
    },
    clearFields() {
      this.user.prenom = "";
      this.user.nom = "";
      this.user.email = "";
      this.user.password = "";
      this.user.confirmPassword = "";
      this.user.box = "";
    },
  },
})
export default class Inscription extends Vue {
  user = reactive({
    prenom: "",
    nom: "",
    email: "",
    password: "",
    confirmPassword: "",
    box: "",
  });

  @Action("register", { namespace }) register: any;

  rules = {
    email: { required, email },
    nom: { required },
    password: { required },
    prenom: { required },
    confirmPassword: { required },
    box: {},
  };

  vv = useVuelidate(this.rules, {
    email: toRef(this.user, "email"),
    password: toRef(this.user, "password"),
    confirmPassword: toRef(this.user, "confirmPassword"),
    nom: toRef(this.user, "nom"),
    prenom: toRef(this.user, "prenom"),
    box: toRef(this.user, "box"),
  });

  submitted = false;
  errors: string[] = [];
  
  recaptcha = null;
  
}
</script>

<style lang="scss" scoped>
#Logo{
  padding-left:10%;
}
.form-group-interieur
{
  margin-top:10px;
  font-family:roboto;
  font-size:15px;
}
.boutondiv
{
  display:flex;
  justify-content:flex-end;
}
.Welcome{
  color: #535353;
  letter-spacing: 0px;
  font-size: 40px;
  font-family: "Roboto";
  font-weight: 600;
  opacity: 1;
}
.registrer-page-login {
  text-align: right;
  margin-right: 10%;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: roboto !important;
}
.registrer-page-login a {
  color: #a8ce6c !important;
  font-family: roboto !important;
  text-decoration: none;
}

.registrer-page-login a:hover {
  color: black !important;
}

label {
  display: flex;
  font-size: 20px;
  font-family: roboto;
  font-weight: 600;
  color: #535353;
  display: inline;
}
input {
  background: #e8e6e6;
  margin-top:5px;
  margin-bottom:5px;
  border: none;
  padding: 10px;
  display: inline;
}
.invalid-feedback {
  color: red;
  font-family: roboto;
  font-size: 12px;
}
button {
  background-color: #a8ce6c !important;
  color: white !important;
  width: 163px;
  border-radius: 24px;
  margin-top: 50px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  border: none !important;
  font-family: roboto !important;
}
button:hover {
  background-color: #a3a3a3 !important;
  cursor: pointer;
  border: none !important;
}
#form-group-name,
#form-group-motdepasse {
  display: flex;
  width: 100%;
}
.ConteneurParent {
  background-image: url("/wood-vert.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#ConteneurMillieu {
  width: 80%;
  display: flex;
  flex-direction: row;
  height: 80%;
}
.background-gris {
  width: 100%;
  height: 100%;
}
.element-ConteneurMillieu {
  width: 50%;
  height: 100%;
}
#ConteneurDroite {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#ban {
  width: 100%;
  height: 100%;
}
.box
{
  padding-left:10%;
  padding-right:10%;
}
#ConteneurGauche {
  background-color: white;
}
.Welcome {
  color: #535353;
  letter-spacing: 0px;
  font-family: "Roboto";
  font-weight: 600;
  opacity: 1;
}
#prenom {
  margin-right: 20px;
}
#password {
  margin-right: 20px;
}
.form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.form-group-name2 {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.form-group-name5 {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.form-group-pw1
{
  display: flex;
  width: 35%;
  flex-direction: column;
}
.form-group-pw2
{
  display: flex;
  width: 65%;
  flex-direction: column;
}
@media screen and (max-width: 1370px) {
  .form-group-pw1
{
  display: flex;
  width: 50%;
  flex-direction: column;
}
.form-group-pw2
{
  display: flex;
  width: 50%;
  flex-direction: column;
}

#passwordlabel
{
  margin-bottom: 25px;
}
  }

@media screen and (max-width: 1200px) {
  .registrer-page-login {
    font-size:18px;
  }

  }

@media screen and (max-width: 1075px) {
  #ConteneurMillieu {
    flex-direction: column;
  }
  #passwordlabel
{
  margin-bottom: 0px;
}
  .element-ConteneurMillieu {
    width: 100%;
  }
  .Welcome {
    font-size: 30px;
    margin-left: 0;
  }
  button {
margin-top:10px;
    }
    
  label {
    font-size:20px;
  }
  #ConteneurDroite {
    padding: 0px;
    flex-direction: column-reverse;
  }
  #ConteneurGauche {
    width: 100%;
  }
  .ConteneurParent {
    height: auto;
    min-height: 100vh;
  }
  .Welcome {
    text-align: center !important;
  }
  #ban {
    width: 100%;
    height:50vh;
  }
  #Logo {
    margin-left: auto;
    margin-right: auto;
    padding-left:0px;
    width: 40%;
  }
  #div-bouton {
    margin-bottom: 10px;
  }
  .registrer-page-login{
    font-size:15px;
    width:80%;
    margin-right:auto;
    margin-left:auto;
    text-align:center;
    margin-bottom: 20px;
  }
}
form.erreurs {
    :invalid {
      outline: 1px solid red;
    }
}

@media screen and (max-width: 900px) {

  #form-group-motdepasse
{
      flex-direction: column;
}
.form-group-pw1, .form-group-pw2
{
      width:100%;
}
#password
{
      margin-right:0px;
}
#ban {
  width: 100%;
  height:30vh;
}.form-group-interieur{
  font-size:12px;
}

}
  
</style>
