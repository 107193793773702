
import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/Modal.vue';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ObjectParameter } from '@/helpers/ObjectParametersAsMapGetter';
import { getNextClockwiseDirection } from '@/Box/BoxRotationDirection';

@Options({
  props: {
    params: Map,
    selectionManager: SelectionManager
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    nbItemsToShow () {
      return 3;
    }
  },
  methods: {
    rotateInterieur() {
        const room = this.selectionManager.getSelectedBox().getRoom();
        if(room !== undefined) {
            room.setRotation(getNextClockwiseDirection(room.getRotation()));
        }
        EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    getEnumItemsListCaroussel (paramName: string) {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.params.get(paramName).editable.enumItemList.concat(this.params.get(paramName).editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.params.get(paramName).editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    whenSelectedBoxChange () {
      if (this.selectionManager.isThereABoxSelected()) {
        this.renderIt = true;
      }
      else {
        this.renderIt = false;
      }
    },
    interieurChanged (item: ObjectParameter) {
      this.selectionManager.getSelectedBox().setRoomType(item.key);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
    EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
  }
})
export default class SelectionMenuStep6 extends Vue {
  renderIt = false;
}
