<template>
  <div>
    <div v-if="param.value !== undefined && param.value.constructor.name === 'Array'" class="paramGroupContainer">
      <div class="categorieName" @click="toggleCategoryVisibility">{{ param.keyDisplayName }} &#x25BC; </div>
      <div class="categoryContent" style="display:none">
        <SelectedBoxMenuParameter v-for="paramItem in param.value" v-bind:key="paramItem.key" :param="paramItem" />
      </div>
    </div>
    <div v-else class="paramIndividuel">
      <div v-if="param.editable != undefined">{{ param.keyDisplayName }}: 
        <input ref="paramTextPreview" type="text" v-if="param.editable.type === getEnumOptions()" :value="getParamPreviewText(param)"><button v-if="param.editable.type == getEnumOptions()" @click="openModal">Changer</button>
        <!-- <select ref="enumSelectBox" v-if="param.editable.type == getEnumOptions()" @change="(event) => valueChanged(event, param.editable)" >
            <option v-for="optionItem in param.editable.enumItemList" v-bind:key="optionItem.key" :value="optionItem.value">{{ optionItem.displayedValue }}</option>
        </select> -->
        <input v-else :type="param.editable.type" :value="param.value" @change="(event) => valueChanged(event, event.target.value)">
      </div>
      <div v-else>{{ param.keyDisplayName }}: {{ param.value }}</div>
    </div>
    <Modal ref="modalEditParam" v-if="param.editable != undefined">
        <template v-slot:header>
          {{ param.keyDisplayName }}
        </template>

        <template v-slot:body>
          <carousel :items-to-show="nbItemsToShow" :wrap-around="true">
            <slide v-for="optionItem in enumItemsListCaroussel" v-bind:key="optionItem.carousselId">
              <div class="carousselItem">
                <img class="carousselItemPreview" draggable="false" :src="'/preview_models/' + optionItem.previewLocation" @click="(event) => valueChanged(event, optionItem)">
                <span class="carousselItemName"> {{ optionItem.displayedValue }} </span>
              </div>
            </slide>
            <slide v-if="param.editable.nullable === true">
              <div class="carousselItem">
                <img class="carousselItemPreview" draggable="false" src="/aucun.png" @click="(event) => valueChanged(event, undefined)">
                <span class="carousselItemName"> Aucun </span>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </template>
         
        <template v-slot:footer>
          <div>
            <button @click="$refs.modalEditParam.closeModal()">Fermer</button>
          </div>
        </template>
      </Modal>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Box } from '@/Box/Box';
import { ObjectParameter } from '../helpers/ObjectParametersAsMapGetter';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import Modal from '@/components/Modal.vue';
import { EditableParameterType } from '@/decorators/EditableParameter/EditableParameterType';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';

@Options({
  props: {
    param: ObjectParameter,
    object3DFactory: Object3DFromBoxFactory,
    selectedBox: Box
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    enumItemsListCaroussel () {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.param.editable.enumItemList.concat(this.param.editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.param.editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    nbItemsToShow () {
      return 3;
    }
  },
  methods:{
    getParamPreviewText (param: ObjectParameter) {
      if (param.value === undefined) {
        return "";
      }
      else {
        return param.value.value;
      }
    },
    openModal () {
      this.$refs.modalEditParam.openModal();
    },
    toggleCategoryVisibility (event: any) {
      const htmlElementToToggle = event.target.parentElement.children[1];
      if (htmlElementToToggle.style.display == "block"){
        htmlElementToToggle.style.display = "none";
      }
      else {
        htmlElementToToggle.style.display = "block";
      }
    },
    getEnumOptions () {
        return EditableParameterType.enum;
    },
    valueChanged (event: any, item: any) {
      event.target.blur();
      if (item.key !== undefined) {
        this.selectedBox[this.param.editable.setter](item.key);
      }
      else {
        this.selectedBox[this.param.editable.setter](item);
      }

      if(this.param.editable.needRefresh) {
        //const newObj3D = this.object3DFactory.getNewObject3DFromBox(this.selectedBox);
        // EventBus.emit(EventType.beforeBoxRefreshed, this.selectedBox);
        // this.selectedBox[editableParameterEntry.refreshMethodName as string](newObj3D);
        EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectedBox);
        this.$forceUpdate();
      }
      if (this.param.editable.type === this.getEnumOptions()) {
        this.param.value = item;
        this.$refs.modalEditParam.closeModal();
      }
    },
  },
  mounted () {
    // this.updateSelectedValue();
    //this.$refs.paramModalSelection.openModal();
  }
})

export default class SelectedBoxMenuParameter extends Vue {
}
</script>

<style scoped>
  .paramIndividuel {
    color: #A8CE6C;
    padding:7px;
    font-size:16px;
  }
  .categorieName:hover{
    background-color:#a3a3a3!important;
    cursor:pointer;
  }
  .categorieName{
    user-select: none;
    background-color:#A8CE6C!important;
    font-family: roboto;
    text-align:center;
    color: white;
    padding-left: 5px;
    font-size:16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .categoryContent {
    border: 1px solid #a3a3a3;
  }
  .carousselItemPreview {
    height: 100px;
  }
  .carousselItem {
    display: flex;
    flex-direction: column;
  }
</style>
