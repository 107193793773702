import { BoxWindow } from "@/Box/BoxParts/BoxWall/BoxWindow";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { Repository } from "./Repository";
import windows from '../../configs/fenetres.json';
import materials from '../../configs/materials.json';
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { BoxHelper } from "three";
import { Material } from "@/CustomEnums/Material";


export class WindowsRepository extends Repository<BoxWindow> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (windows as any[]).length * materials.length;
        materials.forEach(mat => {
            windows.forEach(window => {
                let path = 'models/Fenetre/' + mat.name + "/" + window.filename; 
                if(mat.name == "Anthracite") {
                    const pathSplited = path.split(".");
                    pathSplited[0] += " anthracite";
                    path = pathSplited[0] + "." + pathSplited[1];
                }
                loader.loadObj(path).then(wallInstance => {
                    wallInstance.position.copy(this.object3DTruePositionCalculator.getTruePosition(wallInstance));
                    const hitbox = new BoxHelper(wallInstance, 0xff0000);
                    hitbox.update();
                    this.objectMap.set(mat.name + "_" + window.name, new BoxWindow(wallInstance, hitbox, window.name, Material.getEntryOf(mat.name) as Material, window.size));
                    this.verifyIfEndLoading();
                });
            });
        });
    }

    public getCopyByName(name: string): BoxWindow | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}