const TRUE_FIELDS_NAMES_BY_CLASS = new Map<string,Map<string,DisplayOptions>>();

export function getTrueFieldName(object: any ,fieldName: string): DisplayOptions {
    if(TRUE_FIELDS_NAMES_BY_CLASS.has(object.constructor.name)){
        const map = TRUE_FIELDS_NAMES_BY_CLASS.get(object.constructor.name as string) as Map<string,DisplayOptions>;
        if (map.has(fieldName)) {
            return map.get(fieldName) as DisplayOptions;
        }
    }
    let classToSearch = Object.getPrototypeOf(object);

    while(classToSearch != null) {
        const classHasTrueFieldsNames = TRUE_FIELDS_NAMES_BY_CLASS.has(classToSearch.constructor.name)
        if(classHasTrueFieldsNames) {
            const map = TRUE_FIELDS_NAMES_BY_CLASS.get(classToSearch.constructor.name as string) as Map<string,DisplayOptions>;
            if (map.has(fieldName)) {
                return map.get(fieldName) as DisplayOptions;
            }
        }
        classToSearch = Object.getPrototypeOf(classToSearch);
    }
    return { name: fieldName, stepsVisible: [] };

}

export function ParameterDisplay(displayOptions: DisplayOptions){
    return function (target: any, fieldName: string) {
        const clsName = target.constructor.name;
        let map: Map<string,DisplayOptions>;
        if (TRUE_FIELDS_NAMES_BY_CLASS.has(clsName)) {
            map = TRUE_FIELDS_NAMES_BY_CLASS.get(clsName) as Map<string,DisplayOptions>;
        } else {
            map = new Map<string,DisplayOptions>();
            TRUE_FIELDS_NAMES_BY_CLASS.set(clsName, map);
        }
        map.set(fieldName, displayOptions);
    };
}

interface DisplayOptions {
    name: string;
    stepsVisible: number[];
}