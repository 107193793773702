<template>
  <div class="UIContainerInternal">
    <div class="btnsPannel">
      <div ref="btnfloor2" class="etageBtn" @click="floorUp"><span class="etageMot">Étage</span><span class="noEtage">2</span></div>
      <div ref="btnfloor1" class="etageBtn" @click="floorDown"><span class="etageMot">Étage</span><span class="noEtage">1</span></div>
      <img draggable="false" v-show="currentStep === 3" src="/icones/ajouter-piece.svg" @click="addPreviewToMouse(1)" />
      <img draggable="false" src="/icones/zoom-in.svg" />
      <img draggable="false" src="/icones/zoom-out.svg" />
      <!-- <img draggable="false" v-show="currentStep === 3" src="/icones/rotate.svg" />
      <img class="reverseImgX" v-show="currentStep === 3" draggable="false" src="/icones/rotate.svg" /> -->
    </div>
    <div class="debugInfo" v-if="showDebugInfo">
      FPS: {{ debugParams.frameRate }}<br>
      AngleCameraX:{{ debugParams.cameraAngle.x.toFixed(2) }}<br>
      AngleCameraY:{{ debugParams.cameraAngle.y.toFixed(2) }}<br>
      AngleCameraZ:{{ debugParams.cameraAngle.z.toFixed(2) }}<br>
    </div>
    <div class="btnsFloors">
      
    </div>
    <div class="aimDot" v-if="showAimDot">
      +
    </div>
    <!-- <div ref="firstPersonInfo" @click="startFirstPerson" class="firstPersonInfo">
      <span>Cliquez pour controler</span>
    </div> -->
    <div class="containerSliderSun"><input class="sunSlider" type="range" min="1" max="100" value="25" @input="setLight" ></div>
  </div>
</template>

<script lang="ts">
import { Box } from '@/Box/Box';
import { BoxMiddleFloor } from '@/Box/BoxParts/BoxMiddleFloor';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { BoxWallBit } from '@/Box/BoxParts/BoxWall/BoxWallBit';
import { Object3DTrueScaleCalculator } from '@/calculators/Object3DTrueScaleCalculator';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { ProjectManager } from '@/Managers/ProjectManager';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { BoxRepository } from '@/repositorys/BoxRepository';
import { FloorRepository } from '@/repositorys/FloorRepository';
import { FrontFacingRepository } from '@/repositorys/FrontFacingRepository';
import { MiddleFloorRepository } from '@/repositorys/MiddleFloorRepository';
import { SideMiddleFloorRepository } from '@/repositorys/SideMiddleFloorRepository';
import { WallBitRepository } from '@/repositorys/WallBitRepository';
import { WallRepository } from '@/repositorys/WallRepository';
import { WindowsRepository } from '@/repositorys/WindowsRepository';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneOptions } from '@/scene/SceneOptions';
import { ToastEmitterOptionsType } from '@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType';
import { ToastNotificationsBus } from '@/ToastNotifications/ToastNotificationsBus';
import { Options, Vue } from 'vue-class-component';
import { UIDebugParams } from '../debugTools/UIDebugParams';

@Options({
  props: {
    debugParams: UIDebugParams,
    repositorys: Map,
    selectionManager: SelectionManager
  },
  methods: {
    floorUp(){
      EventBus.emit(EventType.floorUp);
    },
    floorDown(){
      EventBus.emit(EventType.floorDown);
    },
    setLight(event: any) {
      const valeur: number = event.target.value;
      EventBus.emit(EventType.changeLight, valeur);
    },
    setShowAimDot (value: boolean) {
      this.showAimDot = value;
    },
    whenOptionChange () {
      this.showDebugInfo = this.options.debugMode;
    },
    updateFloorBtns () {
      this.$refs.btnfloor1.classList.remove("etageBtn-active");
      this.$refs.btnfloor2.classList.remove("etageBtn-active");
      const currentFloor = this.scene.getGrid().getEtageActif();
      if(currentFloor === 0) {
        this.$refs.btnfloor1.classList.add("etageBtn-active");
      }
      else {
        this.$refs.btnfloor2.classList.add("etageBtn-active");
      }
    },
    whenSceneIsReady (scene: CustomScene) {
      this.scene = scene;
      if(this.hasMounded) {
        this.updateFloorBtns();
        this.hasMounded = false;
      }
    },
    addPreviewToMouse (idPreset) {
        const nomObjet = "Cuisine";
        let floorName = "";
        let frontFacingName = "";
        let wallName = "";
        let windowName = "";
        switch (idPreset){
          case 0: {
              floorName = "floor1";
              frontFacingName = "Blanc_frontFacing1";
              wallName = "RC404";
              windowName = "Aluminium_fenetre 1 pièce";
              break;
          }
          case 1: {
              floorName = "floor1";
              frontFacingName = "Anthracite_frontFacing1";
              wallName = "RC862";
              windowName = "Anthracite_fenetre 3 pièce";
              break;
          }
          case 2: {
              floorName = "floor1";
              frontFacingName = "Anthracite_frontFacing1";
              wallName = "RT1432";
              windowName = "Aluminium_fenetre 1 pièce";
              break;
          }
        }
        if (!this.selectionManager.getIfCanChangeSelectedBox()) {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: "Il faut placer la boite avant" });
        }
        else {
          if(this.selectionManager.isThereABoxSelected()) {
              this.selectionManager.clearSelectedBox();
          }
          const box = this.repositorys.get(BoxRepository.name).getCopyByName(nomObjet, true) as Box;
          const walls = new Array<BoxWall>();
          const wallRepository = this.repositorys.get(WallRepository.name);
          const windowRepository = this.repositorys.get(WindowsRepository.name);
          const floorRepository = this.repositorys.get(FloorRepository.name);
          const frontFacingRepository = this.repositorys.get(FrontFacingRepository.name);
          const middleFloorRepository = this.repositorys.get(MiddleFloorRepository.name);
          const sideMiddleFloorRepository = this.repositorys.get(SideMiddleFloorRepository.name);
          const wallBitRepository = this.repositorys.get(WallBitRepository.name);
          box.setFloor(floorRepository.getCopyByName(floorName));
          box.setScale(this.object3DTrueScaleCalculator.getTrueScale(box.getFloor().getObject(), box.getFloor().getObject().scale));
          box.setFrontFacing(frontFacingRepository.getCopyByName(frontFacingName));
          const wallBits = new Array<BoxWallBit>();
          for(let x = 0; x < 2; x++) {
              wallBits.push(wallBitRepository.getCopyByName("wallBit1"));
          }
          box.setWallBits(wallBits);
          const middleFloor = new Array<BoxMiddleFloor>();
          middleFloor.push(sideMiddleFloorRepository.getCopyByName("side_middleFloor1"));
          for(let x = 0; x < 3; x++) {
              middleFloor.push(middleFloorRepository.getCopyByName("middleFloor1"));
          }
          box.setBoxMiddleFloors(middleFloor);
          for(let x = 0; x < 9; x++) {
              walls.push(wallRepository.getCopyByName(wallName));
          }
          for(let x = 0; x < 3; x++) {
              walls.push(windowRepository.getCopyByName(windowName));
          }
          box.setWalls(walls);
          EventBus.emit(EventType.addPreviewObjectToScene, box)
        }
      }
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(SceneOptions, (options) => { this.options = options });
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.whenSceneIsReady);
    EventBus.on(EventType.firstPersonStarted, () => { this.setShowAimDot(true); });
    EventBus.on(EventType.floorUpdated, this.updateFloorBtns);
    EventBus.on(EventType.firstPersonEnded, () => { this.setShowAimDot(false); });
    EventBus.on(EventType.optionChanged, () => { this.whenOptionChange(false); });
    EventBus.on(EventType.stepChanged, () => { this.currentStep = ProjectManager.getCurrentStep();  });
  },
  mounted () {
    this.hasMounded = true;
    if (this.scene !== undefined) {
      this.updateFloorBtns();
    }
  }
})
export default class SceneUI extends Vue {
  showAimDot = false;
  showDebugInfo = false;
  options!: SceneOptions;
  currentStep = 3;
  scene: CustomScene | undefined = undefined;
  hasMounded = false;
  object3DTrueScaleCalculator: Object3DTrueScaleCalculator = new Object3DTrueScaleCalculator();
}
</script>

<style scoped>
.etageBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #707070;
  width: 100%;
  height: 8.5%;
  color: white;
  cursor: pointer;
}
.etageBtn-active {
  color: #A8CE6C !important;
  background-color: #535353;
}
.etageMot {
  font: normal normal bold 15px/20px Roboto;
}
.noEtage {
  font: normal normal bold 30px/39px Roboto;
}
.btnsPannel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #F7F7F7;
  width: 5%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
.btnsPannel img{
  width: 70%;
  display: block;
  margin: 10% 0px;
}
.btnsPannel img:hover{
  cursor: pointer;
  /* src: url("arrow-hover.png"); */
}
.ajouterPiece {
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: all;
  height: 8vh;
  width: 8vh;
  margin: 10px;
  background: url('/new_doc_blanc.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 1vh;
}
.ajouterPiece:hover {
  cursor: pointer;
  background: url('/new_doc_vert.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: #a8ce6c;
}
.aimDot {
  top:50%;
  left:50%;
  font-size: xx-large;
  position: absolute;
  color: red;
}
.UIContainerInternal {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: relative;
}
.debugInfo {
  color: black;
  position: absolute;
  bottom:0;
  right:0;
  pointer-events: all;
}
.btnsFloors{
  pointer-events: all;
  position: absolute;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right:0;
}

.reverseImgY {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.reverseImgX {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.containerSliderSun {
  position: absolute;
  pointer-events: all;
  top:0;
  right: 4%;
  z-index: 3;
}
.sunSlider{
  -webkit-appearance: none;
  width: 100%;
  height: 12px;
  border-radius: 5px;
  background: #747474;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin:1.5vw 1.5vh;
}
.sunSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  border: 0;
  background: url('/icones/soleil-blanc.svg');
  cursor: pointer;
}
.sunSlider::-moz-range-thumb {
  width: 50px;
  height: 50px;
  border: 0;
  background: url('/icones/soleil-blanc.svg');
  cursor: pointer;
}
</style>
