import { CustomEnum } from "./CustomEnum";

export class CustomEnumLibrairyClass {
    private enumsMap: Map<Function, Map<any,CustomEnum>>;

    public constructor () {
        this.enumsMap = new Map<Function, Map<any,CustomEnum>>();
    }

    public registerNewEnum (enumClass: Function, enumPossiblesEntrys: CustomEnum[]) {
        const newMap = new Map<any,CustomEnum>();
        enumPossiblesEntrys.forEach(entry => {
            newMap.set(entry.key, entry);
        })
        this.enumsMap.set(enumClass, newMap);
    }

    public getEnumPossiblesEntry (enumClass: Function): CustomEnum[] | undefined {
        const possiblesValues = this.enumsMap.get(enumClass);
        if (possiblesValues != undefined) {
            return [...possiblesValues.values()];
        }
        return undefined;
    }

    public findEnumByKey (enumClass: Function, key: any): CustomEnum | undefined {
        return this.enumsMap.get(enumClass)?.get(key);
    }
}

export const CustomEnumLibrairy = new CustomEnumLibrairyClass();