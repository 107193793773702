
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { Options, Vue } from 'vue-class-component';


@Options({
    props: {
        infoProjet: SceneInfoProjet
    },
    methods: {
        reserver () {
            // this.loadSaveHelper.saveProject(this.save).then((success) => {
            //     if (success) {
            //         ToastNotificationsBus.emit(ToastEmitterOptionsType.success, { message: "Projet Sauvegardé" });
            //     }
            //     else {
            //         ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: "Il y a eu un problème avec la sauvegarde, veuillez contacter les administrateurs" });
            //     }
            // });
        },
        setupSceneWhenReady (scene: CustomScene) {
            this.scene = scene;
            this.isReady = true;
        }
    },
    created () {
        SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady)
    }
})
export default class SyntheseMenu extends Vue {
    scene!: CustomScene;
    isReady = false;
}
