
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { load } from 'recaptcha-v3'

const namespace = "auth";

@Options({
  data(){
    return {
      erreurs: false,
    };
  },
  mounted(){
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
    useRecaptchaNet: true
    }).then((recaptcha) => {
      this.recaptcha = recaptcha;
    })
  },
  methods: {
    validation(): boolean {
      this.errors = [];
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.user.confirmPassword != "") {
        if (this.user.password != this.user.confirmPassword) {
          this.errors.push("Les mots de passe doivent être identiques");
          isValid = false;
        }
      }

      if (this.user.box == false) {
        this.errorValidateCondition.push(
          "Veuillez accepter les conditions de politique de confidentialité de M.Y.O"
        );
        isValid = false;
      }

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },
    send() {
      this.recaptcha.execute("send").then((token) => {
        this.erreurs = true;
        if (this.validation()) {
          this.desactiveButton();

          const formData = new FormData();
          formData.append("nom", this.user.nom);
          formData.append("prenom", this.user.prenom);
          formData.append("email", this.user.email);
          formData.append("password", this.user.password);
          formData.append("token", token);

          const object = {};
          formData.forEach((value, key) => {
            object[key] = value;
          });
          const json = object;
          this.register(json)
            .then(() => {
              ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
                message:
                  "Félicitations! Vous êtes maintenant inscrit(e)! Veuillez vous connecter.",
                duration: 5000,
                unique: true
              });
              this.$router.push("/");
            })
            .catch((error) => {
              if(error.response.data.email){
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Cette adresse e-mail est déjà utilisée",
                  duration: 3000,
                  unique: true
                });
              }else{
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Une erreur s'est produite lors de la validation du formulaire, veuillez vérifier chaque champ et réessayez de nouveau.",
                  duration: 3000,
                  unique: true
                });
              }
              this.activeButton();
            });
        }
      });
    },
    clearFields() {
      this.user.prenom = "";
      this.user.nom = "";
      this.user.email = "";
      this.user.password = "";
      this.user.confirmPassword = "";
      this.user.box = "";
    },
  },
})
export default class Inscription extends Vue {
  user = reactive({
    prenom: "",
    nom: "",
    email: "",
    password: "",
    confirmPassword: "",
    box: "",
  });

  @Action("register", { namespace }) register: any;

  rules = {
    email: { required, email },
    nom: { required },
    password: { required },
    prenom: { required },
    confirmPassword: { required },
    box: {},
  };

  vv = useVuelidate(this.rules, {
    email: toRef(this.user, "email"),
    password: toRef(this.user, "password"),
    confirmPassword: toRef(this.user, "confirmPassword"),
    nom: toRef(this.user, "nom"),
    prenom: toRef(this.user, "prenom"),
    box: toRef(this.user, "box"),
  });

  submitted = false;
  errors: string[] = [];
  
  recaptcha = null;
  
}
