import { ProjectBoxWallGroupWall } from "@/project/ProjectBoxWallGroupWall";
import { Desereliazer } from "./Desereliazer";

export class ProjectBoxWallGroupDeseresiazer extends Desereliazer{

    protected static keyComportement<ProjectBoxWallGroup>(key: string, obj: ProjectBoxWallGroup, json: any) {
        if(key === "walls") {
            const walls = new Array<ProjectBoxWallGroupWall>();
            json[key].forEach(element => {
                walls.push(Desereliazer.toInstance(new ProjectBoxWallGroupWall(), element));
            });
            obj[key] = walls;
        }
        else {
            obj[key] = json[key];
        }
    }
}
