import fenetres from './../../configs/fenetres.json';
import { CustomEnum } from './CustomEnum';
import { CustomEnumLibrairy } from './CustomEnumLibrairy';

export class WindowType extends CustomEnum {
    public static _initialize() {
        CustomEnumLibrairy.registerNewEnum(WindowType, WindowType.getMaterialsPossibleEntrys());
    }

    private static getMaterialsPossibleEntrys (): WindowType[] {
        const newList = new Array<WindowType>();
        fenetres.forEach((window) => {newList.push(new WindowType(window.name, window.name, window.preview));});
        return newList;
    }
}
WindowType._initialize();