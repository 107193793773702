import { Box } from '@/Box/Box';
import { BoxDefaultValues } from '@/Box/BoxDefaultValues';
import { Object3DTruePositionCalculator } from '@/calculators/Object3DTruePositionCalculator';
import { Collision } from '@/physic/Collision';

export class BoxFactory {
    
    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    public create(values: BoxDefaultValues): Promise<Box> {
        return new Promise<Box>((resolve) => {
            const newBox = new Box(-1, this.object3DTruePositionCalculator, new Collision(), values);
            resolve(newBox);
        });
    }
}