class SceneCommunicationBusClass {
    private events: Map<Function, Function[]> = new Map<Function, Function[]>();
    private instancesReady: Map<Function, any> = new Map<Function, any>();
    
    public whenInstanceIsReady (type: Function, actionToDo: Function){
        if(this.events.has(type)) {
            (this.events.get(type) as Function[]).push(actionToDo);
        }
        else {
            const newArray: Function[] = [];
            newArray.push(actionToDo);
            this.events.set(type, newArray);
        }
        if (this.instancesReady.has(type)) {
            actionToDo(this.instancesReady.get(type));
        }
    }

    public emitReady (type: Function, instance: any) {
        const array = this.events.get(type);
        this.instancesReady.set(type, instance);
        if (array != null) {
            array.forEach(func => {
                func(instance);
            })
        }
    }
}

export const SceneCommunicationBus: SceneCommunicationBusClass = new SceneCommunicationBusClass();