
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import Modal from '@/components/Modal.vue';

@Options({
  components: {
    Modal
  },
  created() {
    this.getAllRapport().then(rapports => {
      this.rapports = rapports.data;
    });
  },
  methods: { 
    getRapport(id){
      this.getRapportById(id).then(rapport => {
        this.$refs.rapportModal.openModal();
        this.projetSelectionnee = rapport;
      });
    }
  },
})
export default class Rapports extends Vue {
  @Action("getAllSaves", { namespace: "save" }) getAllRapport: any;
  @Action("getSave", { namespace: "save" }) getRapportById: any;

  rapports = {}; 
  projetSelectionnee = {};
}
