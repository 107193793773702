<template>
  <div class="FrameContainer">
    <div class="loadingContainer" v-if="!loadingCompleted">
        <Loading :loadSaveHelper="loadSaveHelper" :repositorys="repositorys" />
    </div>
    <div class="sceneContainer" ref="sceneContainer" v-if="loadingCompleted">
      <Scene :loadSaveHelper="loadSaveHelper" :infoProjet="infoProjet" :object3DFactory="object3DFactory" :repositorys="repositorys" :selectionManager="selectionManager" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Scene from '@/components/Scene.vue';
import Loading from '@/components/Loading.vue';
import { BoxRepository } from '@/repositorys/BoxRepository';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { BoxFactory } from '@/factorys/BoxFactory';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import { WallRepository } from '../repositorys/WallRepository';
import { WindowsRepository } from '../repositorys/WindowsRepository';
import { Repository } from '@/repositorys/Repository';
import { FloorRepository } from '@/repositorys/FloorRepository';
import { FrontFacingRepository } from '@/repositorys/FrontFacingRepository';
import { MiddleFloorRepository } from '@/repositorys/MiddleFloorRepository';
import { SideMiddleFloorRepository } from '@/repositorys/SideMiddleFloorRepository';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { StairsRepository } from '@/repositorys/StairsRepository';
import { WallBitRepository } from '@/repositorys/WallBitRepository';
import { RoomRepository } from '@/repositorys/RoomRepository';
import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { ProjectManager } from '@/Managers/ProjectManager';
import { ProjectDesereliazer } from '@/Desereasizer/ProjectDesereliazer';
import { Project } from '@/project/Project';
import { Action } from 'vuex-class';

@Options({
  components: {
    Scene,
    Loading
  },
  methods: {
    finishedLoading () {
      this.nbItemsLoaded++;
      if (this.nbItemsLoaded == this.repositorys.size) {
        if((!this.saveLoaded && this.saveToLoad !== null) || ProjectManager.getProject().id !== null) {
          this.loadSave(ProjectManager.getProject().id === null);
        }
      }
      else if (this.nbItemsLoaded == this.repositorys.size+1) {
        this.loadingCompleted = true;
      }
    },
    loadSave (needToReloadProject = true) {
      this.saveLoaded = true;
      if(needToReloadProject) {
        ProjectManager.setProject(ProjectDesereliazer.toInstance(new Project(), this.saveToLoad));
        ProjectManager.getProject().id = Number.parseInt(this.$route.params.projectId);
        ProjectManager.getProject().isSet = true;
      }
      this.loadSaveHelper.initialise(ProjectManager.getProject());
    }
  },
  mounted () {
    EventBus.on(EventType.endLoading, this.finishedLoading);
    this.repositorys.forEach(repo => {
      repo.initialise();
    });
  },
  created () {
    this.sceneObjectFactory = new BoxFactory();
    this.repositorys = new Map<string,Repository<any>>();
    this.repositorys.set(WallRepository.name, new WallRepository());
    this.repositorys.set(WindowsRepository.name, new WindowsRepository());
    this.repositorys.set(BoxRepository.name, new BoxRepository(this.sceneObjectFactory));
    this.repositorys.set(FloorRepository.name, new FloorRepository());
    this.repositorys.set(FrontFacingRepository.name, new FrontFacingRepository());
    this.repositorys.set(MiddleFloorRepository.name, new MiddleFloorRepository());
    this.repositorys.set(SideMiddleFloorRepository.name, new SideMiddleFloorRepository());
    this.repositorys.set(StairsRepository.name, new StairsRepository());
    this.repositorys.set(WallBitRepository.name, new WallBitRepository());
    this.repositorys.set(RoomRepository.name, new RoomRepository());
    this.object3DFactory = new Object3DFromBoxFactory(this.repositorys);
    this.selectionManager = new SelectionManager();
    this.loadSaveHelper = new LoadSaveHelper();
    this.loadSaveHelper.setRepositorys(this.repositorys);
    if(ProjectManager.getProject().id === null && this.$route.params.projectId !== "") {
      this.getSave(Number.parseInt(this.$route.params.projectId)).then(response => {
        this.saveToLoad = response;
        if(this.nbItemsLoaded === this.repositorys.size && !this.saveLoaded) {
          this.loadSave();
        }
      });
    }
    else if(this.$route.params.projectId === "") {
      this.canLoadSaves = true;
    }
    else {
      ProjectManager.getProject().id = Number.parseInt(this.$route.params.projectId);
    }
  }
})
export default class MainPage extends Vue {
  sceneObjectFactory!: BoxFactory;
  loadingCompleted = false;
  object3DFactory!: Object3DFromBoxFactory;
  repositorys!: Map<string,Repository<any>>;
  nbItemsLoaded = 0;
  selectionManager!: SelectionManager;
  infoProjet: SceneInfoProjet = new SceneInfoProjet();
  loadSaveHelper!: LoadSaveHelper;
  saveToLoad = null;
  saveLoaded = false;
  @Action("save/getSave") getSave!: any;
}
</script>

<style scoped>
.FrameContainer{    
    background-image: url("/wood-gris.jpg");
    display: flex;
    flex-direction: row;
    height: 95vh;
}
.loadingContainer{
  margin: auto;
  width:30vh;
  vertical-align: middle;
  height: 30vh;
}
</style>