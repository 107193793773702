import axios from 'axios'
import { Module } from 'vuex'


const user: Module<any, any> = {

    namespaced: true,
    
    state: {
        users: []
    },
  
    getters: {
       
    },
    
    actions: {
        getAllUser({ commit }) {
          return new Promise((resolve, reject) => {
            axios.request({
              url: '/listUsers',
              method: 'get',
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
              },
            }).then(response => {
              resolve(response)
            }).catch(err => {
              reject(err)
            })
          })
        },
        getUserById({ commit },id) {
          return new Promise((resolve, reject) => {
            axios.request({
              url: '/listUsers/'+id,
              method: 'get',
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
              },
            }).then(response => {
              resolve(response)
            }).catch(err => {
              reject(err)
            })
          });
        },
        requestUserDetails({ commit }) {
          return new Promise((resolve, reject) => {
            axios.request({
              url: '/details',
              method: 'get',
              baseURL: process.env.VUE_APP_API_URL,
              headers: {
                Authorization: axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
              }
            }).then(response => {
              localStorage.setItem('categorie', response.data.user.categorie_id)
              resolve(response)
            }).catch(err => {
              reject(err)
            })
          });
        },  
    },

    
    
}

export default user
