export enum EventType {
    addObjectToScene = "addObjectToScene",
    addPreviewObjectToScene = "addPreviewObjectToScene",
    newSelectedBox = "newSelectedBox",
    removedSelectedBox = "removedSelectedBox",
    floorUp = "floorUp",
    floorDown = "floorDown",
    floorUpdated = "floorUpdated",
    beginLoading = "beginLoading",
    endLoading = "endLoading",
    changeLight = "changeLight",
    boxDeleted = "boxDeleted",
    selectedBoxNeedRefresh = "selectedBoxNeedRefresh",
    fermerModal = "fermerModal",
    selectedBoxMovingChanged = "selectedBoxMovingChanged",
    selectedBoxInSideMenuHasChanged = "selectedBoxInSideMenuHasChanged",
    firstPersonStarted = "firstPersonStarted",
    firstPersonEnded = "firstPersonEnded",
    rendererChanged = "rendererChanged",
    optionChanged = "optionChanged",
    stepChanged = "stepChanged",
    sceneLoadCompleted = "sceneLoadCompleted",
    avalableStepsUpdated = "avalableStepsUpdated"
}