import { Box3, BoxHelper, Object3D, Vector3 } from 'three';

export class Object3DTrueScaleCalculator {

    private static SIZE_WANTED = 7;
    private static SCALE_WANTED: number;

    public static getScaleWanted (): number {
        return this.SCALE_WANTED;
    }

    public getTrueScale (object: Object3D, currentScale: Vector3): number{
        const hitbox = new BoxHelper(object);
        hitbox.geometry.computeBoundingBox();
        const hitboxValue = hitbox.geometry.boundingBox ?? new Box3();
        const size = hitboxValue.max.sub(hitboxValue.min);
        Object3DTrueScaleCalculator.SCALE_WANTED = currentScale.x/size.x*Object3DTrueScaleCalculator.SIZE_WANTED;
        return Object3DTrueScaleCalculator.SCALE_WANTED;
    }
}