import { Box } from "@/Box/Box";
import { BoxFloor } from "@/Box/BoxParts/BoxFloor";
import { BoxFrontFacing } from "@/Box/BoxParts/BoxFrontFacing";
import { BoxMiddleFloor } from "@/Box/BoxParts/BoxMiddleFloor";
import { BoxRoom } from "@/Box/BoxParts/BoxRoom";
import { BoxStairs } from "@/Box/BoxParts/BoxStairs";
import { BoxWall } from "@/Box/BoxParts/BoxWall/BoxWall";
import { BoxWallBit } from "@/Box/BoxParts/BoxWall/BoxWallBit";
import { BoxWallState } from "@/Box/BoxParts/BoxWall/BoxWallState";
import { BoxWindow } from "@/Box/BoxParts/BoxWall/BoxWindow";
import { FrontFacingMaterial } from "@/CustomEnums/FrontFacingMaterial";
import { RoomType } from "@/CustomEnums/RoomType";
import { Collision } from "@/physic/Collision";
import { BoxRepository } from "@/repositorys/BoxRepository";
import { FloorRepository } from "@/repositorys/FloorRepository";
import { FrontFacingRepository } from "@/repositorys/FrontFacingRepository";
import { MiddleFloorRepository } from "@/repositorys/MiddleFloorRepository";
import { Repository } from "@/repositorys/Repository";
import { RoomRepository } from "@/repositorys/RoomRepository";
import { SideMiddleFloorRepository } from "@/repositorys/SideMiddleFloorRepository";
import { StairsRepository } from "@/repositorys/StairsRepository";
import { WallBitRepository } from "@/repositorys/WallBitRepository";
import { WallRepository } from "@/repositorys/WallRepository";
import { WindowsRepository } from "@/repositorys/WindowsRepository";
import { Object3D } from "three";

export class Object3DFromBoxFactory {

    private repositorys: Map<string,Repository<any>>;

    public constructor (repositorys: Map<string,Repository<any>>) {
        this.repositorys = repositorys;
    }

    public getNewObject3DFromBox(box: Box): BoxsObject3D | undefined {
        const boxInstance = (this.selectGoodRepositoryDependingObject(box) as BoxRepository).getCopyByName(box.getName(), box.getId());
        if(boxInstance != undefined) {
            const invisiblesWalls = new Array<Array<boolean>>();
            const walls = new Array<Array<[Function,Object3D]>>();
            for(let x = 0; x < box.getWalls().length; x++) {
                const wallGroup = box.getWalls()[x];
                const wallGroupObjects = new Array<[Function,Object3D]>();
                const invisiblesWallsItem = new Array<boolean>();
                for(let y = 0; y < wallGroup.length; y++) {
                    let ifWallVisible = true;
                    let wallTrueName = wallGroup[y].getName();
                    let wallClass: Function = BoxWall;
                    if (wallGroup[y].hasForcedState()) {
                        let repoTrueName = "";
                        switch (wallGroup[y].getForcedState()){
                            case BoxWallState.wall: {
                                if(wallGroup[y].constructor.name === BoxWall.name) {
                                    wallTrueName = wallGroup[y].getName();
                                }
                                else {
                                    wallTrueName = box.getWallType().key;
                                }
                                repoTrueName = WallRepository.name;
                                break;
                            }
                            case BoxWallState.window: {
                                if(wallGroup[y].constructor.name === BoxWindow.name) {
                                    wallTrueName = (wallGroup[y] as BoxWindow).getMaterial().key + "_" + wallGroup[y].getName();
                                }
                                else {
                                    wallTrueName = box.getWindowMaterial().key + "_" + box.getWindowType().key;
                                }
                                repoTrueName = WindowsRepository.name;
                                wallClass = BoxWindow;
                                break;
                            }
                            case BoxWallState.none: {
                                if(wallGroup[y].getDefaultState() === BoxWallState.wall) {
                                    wallTrueName = box.getWallType().key;
                                    repoTrueName = WallRepository.name;
                                }
                                else {
                                    wallTrueName = box.getWindowMaterial().key + "_" + box.getWindowType().key;
                                    repoTrueName = WindowsRepository.name;
                                    wallClass = BoxWindow;
                                }
                                wallGroup[y].setForcedState(undefined);
                                ifWallVisible = false;
                                break;
                            }
                        }
                        const obj = ((this.repositorys.get(repoTrueName) as Repository<BoxWall|BoxWindow>).getCopyByName(wallTrueName) as BoxWall).getObject();
                        wallGroupObjects.push([wallClass, obj]);
                    }
                    else {
                        if (wallGroup[y]["material"] != null) {
                            wallTrueName = (wallGroup[y] as BoxWindow).getMaterial().key + "_" + wallGroup[y].getWallType().key;
                            wallClass = BoxWindow;
                        }
                        wallGroupObjects.push([wallClass, (this.selectGoodRepositoryDependingObject(wallGroup[y]).getCopyByName(wallTrueName) as BoxWall).getObject()]);
                    }
                    invisiblesWallsItem.push(ifWallVisible);
                }
                invisiblesWalls.push(invisiblesWallsItem);
                walls.push(wallGroupObjects);
            }
            const floor = this.selectGoodRepositoryDependingObject(box.getFloor()).getCopyByName(box.getFloor().getName()).getObject();

            let frontFacingTrueName = box.getFrontFacing().getName()
            if (box.getFrontFacing()["material"] != null) {
                frontFacingTrueName = (box.getFrontFacing()["material"] as FrontFacingMaterial).key + "_" + frontFacingTrueName;
            }
            const frontFacing = this.selectGoodRepositoryDependingObject(box.getFrontFacing()).getCopyByName(frontFacingTrueName).getObject();
            const middleFloors = new Array<Object3D>();
            const oldStairs = box.getStairs();
            let stairs = undefined;
            if (oldStairs != undefined) {
                stairs = this.selectGoodRepositoryDependingObject(oldStairs).getCopyByName(oldStairs.getName()).getObject();
            }
            for(let x = 0; x < box.getMiddleFloors().length; x++) {
                middleFloors.push(this.selectGoodRepositoryDependingObject(box.getMiddleFloors()[x]).getCopyByName(box.getMiddleFloors()[x].getName()).getObject());
            }
            const wallBits = new Array<Object3D>();
            for(let x = 0; x < box.getWallBits().length; x++) {
                wallBits.push(this.selectGoodRepositoryDependingObject(box.getWallBits()[x]).getCopyByName(box.getWallBits()[x].getName()).getObject());
            }
            let room = undefined;
            let roomPrice = undefined;
            if (box.getRoomType() !== undefined) {
                const boxRoom = this.selectGoodRepositoryDependingObject(box.getRoom()).getCopyByName((box.getRoomType() as RoomType).key);
                room = boxRoom.getObject();
                roomPrice = boxRoom.getPrice();
            }
            return { walls: walls, invisiblesWalls: invisiblesWalls, floor: floor, frontFacing: frontFacing, middleFloors: middleFloors, collision: box.getCollision(), stairs: stairs, wallBits: wallBits, room: room, roomPrice: roomPrice };
        }
        return undefined;
    }

    private selectGoodRepositoryDependingObject (obj: any): Repository<any> {
        let goodRepoName = "";
        switch (obj.constructor.name) {
            case BoxWall.name: {
                goodRepoName = WallRepository.name;
                break;
            }
            case BoxWindow.name: {
                goodRepoName = WindowsRepository.name;
                break;
            }
            case BoxFloor.name: {
                goodRepoName = FloorRepository.name;
                break;
            }
            case BoxFrontFacing.name: {
                goodRepoName = FrontFacingRepository.name;
                break;
            }
            case BoxStairs.name: {
                goodRepoName = StairsRepository.name;
                break;
            }
            case BoxWallBit.name: {
                goodRepoName = WallBitRepository.name;
                break;
            }
            case BoxRoom.name: {
                goodRepoName = RoomRepository.name;
                break;
            }
            case BoxMiddleFloor.name: {
                if ((obj as BoxMiddleFloor).getName().includes("side_")) {
                    goodRepoName = SideMiddleFloorRepository.name;
                }
                else {
                    goodRepoName = MiddleFloorRepository.name;
                }
                break;
            }
            default: {
                goodRepoName = BoxRepository.name;
            }
        }
        return this.repositorys.get(goodRepoName) as Repository<any>;
    }
}

export interface BoxsObject3D {
    walls: [Function, Object3D][][];
    invisiblesWalls: Array<Array<boolean>>;
    wallBits: Object3D[];
    floor: Object3D;
    frontFacing: Object3D;
    middleFloors: Object3D[];
    room?: Object3D;
    roomPrice?: number;
    collision: Collision;
    stairs?: Object3D;
}