
export enum BoxRotationDirection {
    Sud = 0, 
    Est = Math.PI/2, 
    Nord = Math.PI, 
    Ouest = Math.PI*3/2
}

const rotationInOrder= [BoxRotationDirection.Sud, BoxRotationDirection.Est, BoxRotationDirection.Nord, BoxRotationDirection.Ouest];

export function findDirectionInRotationInOrder (direction: BoxRotationDirection): number {
    return rotationInOrder.findIndex(directionItem => directionItem.toString()  === direction.toString())
}

export function getNextClockwiseDirection(direction: BoxRotationDirection, incrementation = 1): BoxRotationDirection | null {
    const directionId = findDirectionInRotationInOrder(direction);
    if(directionId == -1) {
        return null;
    }
    else {
        return rotationInOrder[(directionId + incrementation)%rotationInOrder.length];
    }
}

export function getNextCounterClockwiseDirection(direction: BoxRotationDirection, decrementation = 1): BoxRotationDirection | null {
    const directionId = findDirectionInRotationInOrder(direction);
    if(directionId == -1) {
        return null;
    }
    else {
        const newDecrementation = decrementation % rotationInOrder.length;
        let newId = directionId - newDecrementation;
        if (newId < 0) {
            newId = rotationInOrder.length + newId;
        }
        return rotationInOrder[newId];
    }
}