import { Box } from "@/Box/Box";
import { BoxStairs } from "@/Box/BoxParts/BoxStairs";
import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { Object3DColorSetter } from "@/helpers/Object3DColorSetter";
import { CustomScene } from "@/scene/CustomScene";
import { SceneRayCaster } from "@/scene/raycaster/SceneRayCaster";
import { Vector3 } from "three";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls";
import { SelectionManager } from "./SelectionManager/SelectionManager";

export class FirstPersonViewManager {
    private lastCameraPosition!: Vector3;
    private isMovingFront: boolean;
    private isMovingBack: boolean;
    private isMovingRight: boolean;
    private isMovingLeft: boolean;
    private velocity: Vector3;
    private speed: number;
    private scene: CustomScene;
    private selectionManager: SelectionManager;
    private isInFirstPerson: boolean;
    private raycaster: SceneRayCaster;
    private colorSetter: Object3DColorSetter;
    private stairsSelected?: BoxStairs;

    public constructor (scene: CustomScene, selectionManager: SelectionManager) {
        this.isMovingFront = false;
        this.isMovingBack = false;
        this.isMovingRight = false;
        this.isMovingLeft = false;
        this.velocity = new Vector3();
        this.speed = 50;
        this.scene = scene;
        this.selectionManager = selectionManager;
        this.isInFirstPerson = false;
        this.raycaster = new SceneRayCaster(1);
        this.colorSetter = new Object3DColorSetter();
    }

    public seeInsideBox (box: Box) {
        this.lastCameraPosition = this.scene.getCamera().position.clone();
        const newCameraPosition = box.getPosition().clone().add(new Vector3(0,3,0));
        this.scene.getCamera().position.copy(newCameraPosition);
        this.selectionManager.clearSelectedBox();
        this.startFirstPerson();
    }
    

    private startFirstPerson () {
        this.isInFirstPerson = true;
        (this.scene.getControls() as PointerLockControls).addEventListener('unlock', this.whenFirstPersonEnd.bind(this) );
        (this.scene.getControls() as PointerLockControls).addEventListener('change', this.onMouseMove.bind(this) );
        (this.scene.getControls() as PointerLockControls).lock();
        EventBus.emit(EventType.firstPersonStarted);
    }

    private whenFirstPersonEnd () {
        this.isInFirstPerson = false;
        this.scene.getCamera().position.copy(this.lastCameraPosition);
        if (this.stairsSelected !== undefined){
            this.scene.refreshBox(this.stairsSelected.getBoxOnBottom() as Box)
            this.stairsSelected = undefined;
        }
        EventBus.emit(EventType.firstPersonEnded);
    }

    public isFirstPersonActivaded (): boolean {
        return this.isInFirstPerson;
    }

    private onMouseMove () {
        this.raycaster.setFromCameraIgnoringMouse((this.scene.getControls() as PointerLockControls).getObject());
        const stairsPointedTo = this.raycaster.getSceneObjectFromIntersectedObjectOfAMesh(this.scene.getAllStairsFromBoxesInCurrentFloor());
        if (stairsPointedTo !== null && this.stairsSelected === undefined) {
            this.stairsSelected = stairsPointedTo as BoxStairs
            this.colorSetter.setEmissiveOfObjectsAndHisChildrens(stairsPointedTo.getObject(), "blue");
        }
        else if (stairsPointedTo === null && this.stairsSelected !== undefined){
            this.scene.refreshBox(this.stairsSelected.getBoxOnBottom() as Box)
            this.stairsSelected = undefined;
        }
    }

    public onMouseClick() {
        if (this.isInFirstPerson) {
            if (this.stairsSelected !== undefined){
                this.scene.refreshBox(this.stairsSelected.getBoxOnBottom() as Box)
                this.stairsSelected = undefined;
                if (this.scene.getGrid().getEtageActif() === 0) {
                    EventBus.emit(EventType.floorUp);
                }
                else {
                    EventBus.emit(EventType.floorDown);
                }
            }
        }
    }
    
    public onKeyDown (event: KeyboardEvent) {
        if (this.isInFirstPerson) {
            switch ( event.code ) {
                case 'ArrowUp':
                case 'KeyW':
                    this.isMovingFront = true;
                    break;

                case 'ArrowLeft':
                case 'KeyA':
                    this.isMovingLeft = true;
                    break;

                case 'ArrowDown':
                case 'KeyS':
                    this.isMovingBack = true;
                    break;

                case 'ArrowRight':
                case 'KeyD':
                    this.isMovingRight = true;
                    break;
            }
        }
    }

    public onKeyUp (event: KeyboardEvent) {
        if (this.isInFirstPerson) {
            switch ( event.code ) {
                case 'ArrowUp':
                case 'KeyW':
                    this.isMovingFront = false;
                    break;

                case 'ArrowLeft':
                case 'KeyA':
                    this.isMovingLeft = false;
                    break;

                case 'ArrowDown':
                case 'KeyS':
                    this.isMovingBack = false;
                    break;

                case 'ArrowRight':
                case 'KeyD':
                    this.isMovingRight = false;
                    break;

            }
        }
    }

    public move(delta: number) {
        this.velocity.x -= this.velocity.x * 10 * delta;
        this.velocity.z -= this.velocity.z * 10 * delta;
      
        const direction = new Vector3();
        direction.z = Number( this.isMovingFront ) - Number( this.isMovingBack );
        direction.x = Number( this.isMovingRight ) - Number( this.isMovingLeft );
        direction.normalize(); 
      
        if ( this.isMovingFront || this.isMovingBack ) this.velocity.z -= direction.z * this.speed * delta;
        if ( this.isMovingLeft || this.isMovingRight ) this.velocity.x -= direction.x * this.speed * delta;
        (this.scene.getControls() as PointerLockControls).moveRight( - this.velocity.x * delta );
        (this.scene.getControls() as PointerLockControls).moveForward( - this.velocity.z * delta );
    }
}