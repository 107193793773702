
import { Options, Vue } from 'vue-class-component';
import { Box } from '@/Box/Box';
import { ObjectParameter } from '../helpers/ObjectParametersAsMapGetter';
import { Object3DFromBoxFactory } from '@/factorys/Object3DFromBoxFactory';
import Modal from '@/components/Modal.vue';
import { EditableParameterType } from '@/decorators/EditableParameter/EditableParameterType';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';

@Options({
  props: {
    param: ObjectParameter,
    object3DFactory: Object3DFromBoxFactory,
    selectedBox: Box
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    enumItemsListCaroussel () {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.param.editable.enumItemList.concat(this.param.editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.param.editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    nbItemsToShow () {
      return 3;
    }
  },
  methods:{
    getParamPreviewText (param: ObjectParameter) {
      if (param.value === undefined) {
        return "";
      }
      else {
        return param.value.value;
      }
    },
    openModal () {
      this.$refs.modalEditParam.openModal();
    },
    toggleCategoryVisibility (event: any) {
      const htmlElementToToggle = event.target.parentElement.children[1];
      if (htmlElementToToggle.style.display == "block"){
        htmlElementToToggle.style.display = "none";
      }
      else {
        htmlElementToToggle.style.display = "block";
      }
    },
    getEnumOptions () {
        return EditableParameterType.enum;
    },
    valueChanged (event: any, item: any) {
      event.target.blur();
      if (item.key !== undefined) {
        this.selectedBox[this.param.editable.setter](item.key);
      }
      else {
        this.selectedBox[this.param.editable.setter](item);
      }

      if(this.param.editable.needRefresh) {
        //const newObj3D = this.object3DFactory.getNewObject3DFromBox(this.selectedBox);
        // EventBus.emit(EventType.beforeBoxRefreshed, this.selectedBox);
        // this.selectedBox[editableParameterEntry.refreshMethodName as string](newObj3D);
        EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectedBox);
        this.$forceUpdate();
      }
      if (this.param.editable.type === this.getEnumOptions()) {
        this.param.value = item;
        this.$refs.modalEditParam.closeModal();
      }
    },
  },
  mounted () {
    // this.updateSelectedValue();
    //this.$refs.paramModalSelection.openModal();
  }
})

export default class SelectedBoxMenuParameter extends Vue {
}
