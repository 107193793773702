import { BoxHelper, Object3D } from "three";
import { BoxPart } from "../BoxPart";

export class BoxWallBit extends BoxPart {
    private unitSize: number;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string, unitSize: number){
        super(object, hitbox, name);
        this.unitSize = unitSize;
    }

    public getCopy(): BoxWallBit {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxWallBit(parentCopy.getObject(),parentCopy.getHitbox(), this.name, this.unitSize);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }

    public getUnitSize(): number {
        return this.unitSize;
    }
}