<template>
  <div id="app" class="bg-light h-screen">
    <Header v-if="isLoggedIn" />
    <router-view />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { ToastNotificationManager } from "./ToastNotifications/ToastNotificationManager";
import Header from "./components/Header.vue";
import { Getter } from "vuex-class";

@Options({
  data() {
    return {
    };
  },
  components: {
    Header,
   
  },
  computed: {
    getRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.toastNotificationManager = new ToastNotificationManager(this.$toast);
  },
})
export default class App extends Vue {
  toastNotificationManager!: ToastNotificationManager;
  @Getter("auth/isLoggedIn") isLoggedIn: any;
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@400;700&display=swap");
body {
  margin: 0px;
  height: 100%;
}
.etoileform
{
  color:#a8ce6c;
}
h1, h2, h3, h4, h5, h6, p, label {
  font-family: roboto;
}
#appInternalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#app {
  height: 100%;
}
.carousel__prev{
    background-color: #a8ce6c !important;
}
.carousel__next{
    background-color: #a8ce6c !important;
}
.carousel__pagination-button{
    background-color: #a8ce6c !important;
}
</style>
