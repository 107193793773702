import { Vector3 } from "three";

export class Desereliazer {
    public static toInstance<T>(obj: T, json: any): T  {
        for (const key of Object.keys(obj)) {
            if (json[key] !== undefined) {
                if (this.isVector3(json[key])) {
                    obj[key] = new Vector3(json[key]["x"], json[key]["y"], json[key]["z"])
                }
                else {
                    this.keyComportement(key, obj, json);
                }
            }
        }
        return obj;
    }

    private static isVector3 (obj: any) {
        return obj != null && obj["x"] !== undefined && obj["y"] !== undefined && obj["z"] !== undefined;
    }

    protected static keyComportement<T>(key: string, obj: T, json: any) {
        obj[key] = json[key];
    }
}