import { AddingStairsRuleChecker } from "@/ActionsRulesCheckers/AddingStairsRuleChecker";
import { Box } from "@/Box/Box";
import { BoxStairs } from "@/Box/BoxParts/BoxStairs";
import { CustomScene } from "@/scene/CustomScene";
import { StairsValidationObject } from "@/validationObjects/StairsValidationObject";

export class StairsManager {

    private scene: CustomScene;
    private addingStairsRuleChecker: AddingStairsRuleChecker;

    public constructor (scene: CustomScene) {
        this.scene = scene;
        this.addingStairsRuleChecker = new AddingStairsRuleChecker(scene.getSceneObjects(), scene.getGrid());
    }

    public checkIfStairsCanBePlaced (stairsValidationObject: StairsValidationObject, showErrorMessage: boolean) {
        return this.addingStairsRuleChecker.isActionPermitted(stairsValidationObject, showErrorMessage);
    }

    public prepareStairs (box: Box, stairs: BoxStairs) {
        const boxUnder = this.scene.getGrid().getBoxBellowThisBox(box);
        const boxOver = this.scene.getGrid().getBoxOverThisBox(box);
        if(boxUnder != null) {
            stairs.setBoxes(box, boxUnder);
            //stairs.setScaleFromBox(boxUnder.getScale());
            //stairs.setPositionAndRotationFromBox(boxUnder);
        }
        else if (boxOver != null) {
            stairs.setBoxes(boxOver, box);
            //stairs.setScaleFromBox(box.getScale());
            //stairs.setPositionAndRotationFromBox(box);
        }
    }

    public prepareStairsValidationObject (box: Box, stairs: StairsValidationObject) {
        const boxUnder = this.scene.getGrid().getBoxBellowThisBox(box);
        const boxOver = this.scene.getGrid().getBoxOverThisBox(box);
        if(boxUnder != null) {
            stairs.boxOnTop = box;
            stairs.boxOnBottom = boxUnder;
            //stairs.setScaleFromBox(boxUnder.getScale());
            //stairs.setPositionAndRotationFromBox(boxUnder);
        }
        else if (boxOver != null) {
            stairs.boxOnTop = boxOver;
            stairs.boxOnBottom = box;
            //stairs.setScaleFromBox(box.getScale());
            //stairs.setPositionAndRotationFromBox(box);
        }
    }
}