export enum BoxWallState {
    wall = "/mur.png",
    window = "/fenetre.png",
    none = "/aucun.png"
}

export function GetNextBoxWallState (currentWallState: BoxWallState) {
    switch (currentWallState) {
        case BoxWallState.wall: {
            return BoxWallState.window;
        }
        case BoxWallState.window: {
            return BoxWallState.none;
        }
        case BoxWallState.none: {
            return BoxWallState.wall;
        }
    }
}