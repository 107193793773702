<template>
  <div v-if="renderIt">
      <div class ="paramIndividuel">Facade: <button @click="$refs.modalFacade.openModal()">Changer</button></div>
      <div class ="paramIndividuel">Fenêtres: <button @click="openWindowModal">Changer</button></div>
      <Modal ref="modalWindow">
        <template v-slot:header>
          Fenêtres
        </template>

        <template v-slot:body>
          <div class="paramSelection">
            <p>Cliquez sur l'image pour modifier la fenêtre</p>
            <div class="boussole"><img src="/boussole.png" :style="`transform: rotate(${(4 - decalageCamera) * 90}deg);`"></div>
            <div class="paramSelectionContainer">
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(1)][0]" @click="changeFenetreIndividuel(getNewRelativePosition(1),0)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(1)][1]" @click="changeFenetreIndividuel(getNewRelativePosition(1),1)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(1)][2]" @click="changeFenetreIndividuel(getNewRelativePosition(1),2)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
              <div class="paramSelectionContainerRow middleRow">
                <div class="paramSelectionContainerRowItemColumn">
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(0)][2]" @click="changeFenetreIndividuel(getNewRelativePosition(0),2)"><img :src="lienImageFenetre"></div>
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(0)][1]" @click="changeFenetreIndividuel(getNewRelativePosition(0),1)"><img :src="lienImageFenetre"></div>
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(0)][0]" @click="changeFenetreIndividuel(getNewRelativePosition(0),0)"><img :src="lienImageFenetre"></div>
                </div>
                <div class="paramSelectionContainerRowItemCenter2">Boite</div>
                <div class="paramSelectionContainerRowItemColumn">
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(2)][0]" @click="changeFenetreIndividuel(getNewRelativePosition(2),0)"><img :src="lienImageFenetre"></div>
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(2)][1]" @click="changeFenetreIndividuel(getNewRelativePosition(2),1)"><img :src="lienImageFenetre"></div>
                  <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(2)][2]" @click="changeFenetreIndividuel(getNewRelativePosition(2),2)"><img :src="lienImageFenetre"></div>
                </div>
              </div>
              <div class="paramSelectionContainerRow">
                <div class="paramSelectionContainerRowEmptyItem"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(3)][2]" @click="changeFenetreIndividuel(getNewRelativePosition(3),2)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(3)][1]" @click="changeFenetreIndividuel(getNewRelativePosition(3),1)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowItem" v-show="siMontreFenetre[getNewRelativePosition(3)][0]" @click="changeFenetreIndividuel(getNewRelativePosition(3),0)"><img :src="lienImageFenetre"></div>
                <div class="paramSelectionContainerRowEmptyItem"></div>
              </div>
            </div>
          </div>
        </template>
      </Modal>
      <Modal ref="modalWindowIndividuel">
        <template v-slot:header>
          {{ params.get('wallType').keyDisplayName }}
        </template>

        <template v-slot:body>
          <carousel :items-to-show="nbItemsToShow" :wrap-around="true">
            <slide v-for="optionItem in getEnumItemsListCaroussel('windowType')" v-bind:key="optionItem.carousselId">
              <div class="carousselItem">
                <img class="carousselItemPreview" draggable="false" :src="'/preview_models/' + optionItem.previewLocation" @click="changeType(optionItem)">
                <span class="carousselItemName"> {{ optionItem.displayedValue }} </span>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
          <carousel :items-to-show="nbItemsToShow" :wrap-around="true">
            <slide v-for="optionItem in getEnumItemsListCaroussel('windowMaterial')" v-bind:key="optionItem.carousselId">
              <div class="carousselItem">
                <img class="carousselItemPreview" draggable="false" :src="'/preview_models/' + optionItem.previewLocation" @click="changeMateriel(optionItem)">
                <span class="carousselItemName"> {{ optionItem.displayedValue }} </span>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </template>
      </Modal>
      <Modal ref="modalFacade">
        <template v-slot:header>
          Facade
        </template>
        <template v-slot:body>
          <carousel :items-to-show="nbItemsToShow" :wrap-around="true">
            <slide v-for="optionItem in getEnumItemsListCaroussel('frontFacingMaterial')" v-bind:key="optionItem.carousselId">
              <div class="carousselItem">
                <img class="carousselItemPreview" draggable="false" :src="'/preview_models/' + optionItem.previewLocation" @click="facadeChanged(optionItem)">
                <span class="carousselItemName"> {{ optionItem.displayedValue }} </span>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </template>
      </Modal>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/Modal.vue';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { BoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { ObjectParameter } from '@/helpers/ObjectParametersAsMapGetter';
import { OrbitControls } from 'node_modules/@types/three/examples/jsm/controls/OrbitControls';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { BoxWindow } from '@/Box/BoxParts/BoxWall/BoxWindow';
import { Material } from '@/CustomEnums/Material';
import { WindowType } from '@/CustomEnums/WindowType';

@Options({
  props: {
    params: Map,
    selectionManager: SelectionManager
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  computed: {
    nbItemsToShow () {
      return 3;
    }
  },
  methods: {
    facadeChanged (item: ObjectParameter) {
      this.selectionManager.getSelectedBox().setFrontFacingMaterial(item.key);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    getNewRelativePosition(oldPosition): number {
      return (oldPosition + this.decalageCamera) % 4;
    },
    getParam (name: string): ObjectParameter {
      return this.params.get(name);
    },
    changeFenetreIndividuel (wallGroupNumber: number, wallNb: number) {
      this.currentChangingWindowPosition = [wallGroupNumber, wallNb];
      this.$refs.modalWindowIndividuel.openModal();
    },
    getEnumItemsListCaroussel (paramName: string) {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.params.get(paramName).editable.enumItemList.concat(this.params.get(paramName).editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.params.get(paramName).editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    openWindowModal () {
      this.$refs.modalWindow.openModal();
    },
    whenSelectedBoxChange () {
      if (this.selectionManager.isThereABoxSelected()) {
        this.updateSiMontreFenetre();
        this.renderIt = true;
      }
      else {
        this.renderIt = false;
      }
    },
    updateSiMontreFenetre () {
      const wallsGroup = this.selectionManager.getSelectedBox().getWalls();
      this.siMontreFenetre = new Array<Array<boolean>>(wallsGroup.length);
      for(let x = 0; x < wallsGroup.length; x++) {
        this.siMontreFenetre[x] = new Array<boolean>(wallsGroup[x].length);
        for(let y = 0; y < wallsGroup[x].length; y++) {
          this.siMontreFenetre[x][y] = wallsGroup[x][y].constructor.name === BoxWindow.name;
        }
      }
    },
    updateDecalageFromCamera () {
      const cameraAngle = Math.round(this.scene.getCamera().rotation.z/Math.PI*2);
      if (cameraAngle >= 0) {
        this.decalageCamera = 4 - cameraAngle;
      }
      else {
        this.decalageCamera = -cameraAngle;
      }
    },
    changeMateriel (item: ObjectParameter) {
      const wall = this.selectionManager.getSelectedBox().getWalls()[this.currentChangingWindowPosition[0]][this.currentChangingWindowPosition[1]] as BoxWindow;
      wall.setMaterial(Material.getEntryOf(item.key) as Material);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    changeType (item: ObjectParameter) {
      const wall = this.selectionManager.getSelectedBox().getWalls()[this.currentChangingWindowPosition[0]][this.currentChangingWindowPosition[1]] as BoxWindow;
      wall.setType(WindowType.getEntryOf(item.key) as WindowType);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox());
    },
    whenSceneLoadIsCompleted () {
      if(this.scene.getControls() != undefined) {
        (this.scene.getControls() as OrbitControls).addEventListener('change', this.updateDecalageFromCamera );
      }
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
    EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, (scene) => { this.scene = scene });
    EventBus.on(EventType.sceneLoadCompleted, this.whenSceneLoadIsCompleted);
    this.whenSceneLoadIsCompleted();
  }
})
export default class SelectionMenuStep5 extends Vue {
  decalageCamera: number = 0;
  renderIt = false;
  currentChangingWindowPosition = [-1, -1];
  lienImageFenetre = BoxWallState.window;
  scene!: CustomScene;
  siMontreFenetre!: Array<Array<boolean>>;
}
</script>

<style scoped>
  
</style>
