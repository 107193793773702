<template>
  <div class="menuContainer">
      <h1 class="menuTitle">Projet</h1>
      <div class="menuContent" v-if="isReady">
          <MenuGroup groupTitle="Visualisation"> 
            <template v-slot:content>
                <div class="paramIndividuel">Autre étage visible: <ToggleSwitch :onChangeFunction="(evt) => setOption(evt, 'isOtherFloorVisible', true, false)" :checked="options.isOtherFloorVisible" /></div>
                <div class="paramIndividuel">Mode debug: <ToggleSwitch :onChangeFunction="(evt) => setOption(evt, 'debugMode', true, false)" :checked="options.debugMode" /></div>
                <div class="paramIndividuel">Antialiasing: <ToggleSwitch :onChangeFunction="(evt) => setOption(evt, 'antialiasing', false, true)" :checked="options.antialiasing" /></div>
            </template>
          </MenuGroup>
      </div>
  </div>
</template>

<script lang="ts">
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneOptions } from '@/scene/SceneOptions';
import { Action } from "vuex-class";
import { Options, Vue } from 'vue-class-component';
import { ProjectManager } from '@/Managers/ProjectManager';
import MenuGroup from './MenuGroup.vue';
import ToggleSwitch from './ToggleSwitch.vue';


@Options({
    components: {
        MenuGroup,
        ToggleSwitch
    },
    methods: {
        setupSceneWhenReady (scene: CustomScene) {
            this.scene = scene;
            this.options = this.scene.getOptions();
            this.isReady = true;
        },
        setOption (event, varName: string, needBoxesRefreshed: boolean, needRendererRefreshed: boolean) {
            if (event.target.value != undefined && typeof this.options[varName] === typeof event.target.value) {
                this.options.setOption(varName, event.target.value, needBoxesRefreshed, needRendererRefreshed);
            }
            else if (event.target.checked != undefined && typeof this.options[varName] === typeof event.target.checked) {
                this.options.setOption(varName, event.target.checked, needBoxesRefreshed, needRendererRefreshed);
            }
        }
    },
    created () {
        SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady)
    }
})
export default class MenuOptionsProjet extends Vue {
    scene!: CustomScene;
    options!: SceneOptions;
    isReady = false;
}
</script>

<style scoped>
    .menuContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    /* .menuTitle {
        user-select: none;
        background-color:#A8CE6C!important;
        font-family: roboto;
        text-align:center;
        color: white;
        padding-left: 5px;
        font-size:26px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        margin-bottom: 1vh;
    } */
    .menuContainer {
        margin-top: 10vh;
        margin: 5%;
        font-family: roboto;
        width: 90%;
    }
    .btnMenuSelection {
        margin-top: 5px;
    }
</style>
