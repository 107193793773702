import { Material, Mesh, MeshLambertMaterial, MeshStandardMaterial, Object3D } from "three";

export class Object3DColorSetter {
    public setEmissiveOfObjectsAndHisChildrens(rootObject: Object3D, color: string) {
        const childrenArray = rootObject.children as Object3D[];
        childrenArray.forEach((obj) => {
            if (obj.type == "Mesh") {
              const mesh = obj as Mesh;
              if (Array.isArray(mesh.material)){"MeshBasicMaterial"
                const materials = mesh.material as Array<Material>;
                materials.forEach((mat) => {
                  this.colorMaterial(mat, color);
                });
              }
              else {
                this.colorMaterial(mesh.material, color);
              }
            }
            else {
                this.setEmissiveOfObjectsAndHisChildrens(obj, color);
            }
        });
    }

    public setTransparentOfObjectAndHisChildren (rootObject: Object3D) {
        const childrenArray = rootObject.children as Object3D[];
        childrenArray.forEach((obj) => {
            if (obj.type == "Mesh") {
              const mesh = obj as Mesh;
              if (Array.isArray(mesh.material)){"MeshBasicMaterial"
                const materials = mesh.material as Array<Material>;
                materials.forEach((mat) => {
                  this.setMaterialTransparent(mat);
                });
              }
              else {
                this.setMaterialTransparent(mesh.material);
              }
            }
            else {
                this.setTransparentOfObjectAndHisChildren(obj);
            }
        });
    }

    private removeColorMaterial (material: Material) {
        switch (material.type) {
            case "MeshLambertMaterial": {
                const mat = material as MeshLambertMaterial;
                mat.emissive.set("black");
                mat.needsUpdate = true;
                mat.emissiveIntensity = 0;
                mat.vertexColors = false;
                break;
            }
            case "MeshStandardMaterial": {
                (material as MeshStandardMaterial).emissive.set("black");
                break;
            }
            default: {
                console.log(material.type);
            }
        }
    }
    
    private colorMaterial (material: Material, color: string) {
        switch (material.type) {
            case "MeshLambertMaterial": {
                const mat = material as MeshLambertMaterial;
                mat.emissive.set(color);
                mat.needsUpdate = true;
                mat.emissiveIntensity = 1;
                // mat.color.b += 0.3;
                // mat.color.r -= 0.1;
                // mat.color.g -= 0.1;
                break;
            }
            case "MeshStandardMaterial": {
                (material as MeshStandardMaterial).emissive.set(color);
                break;
            }
            default: {
                console.log(material.type);
            }
        }
    }

    private setMaterialTransparent (material: Material) {
        const opacity = 0.1;
        this.removeColorMaterial(material)
        material.transparent = true;
        material.opacity = opacity;
    }
}