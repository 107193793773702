import { BoxWallBit } from "@/Box/BoxParts/BoxWall/BoxWallBit";
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { BoxHelper } from "three";
import wallBit from '../../configs/wallBit.json';
import { Repository } from "./Repository";

export class WallBitRepository extends Repository<BoxWallBit> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (wallBit as any[]).length;
        wallBit.forEach(wallBitItem => {
            loader.loadObj('models/'+wallBitItem.location).then(instance => {
                instance.position.copy(this.object3DTruePositionCalculator.getTruePosition(instance));
                const hitbox = new BoxHelper(instance, 0xff0000);
                hitbox.update();
                this.objectMap.set(wallBitItem.name, new BoxWallBit(instance, hitbox, wallBitItem.name, wallBitItem.unitSize));
                this.verifyIfEndLoading();
            })
        })
    }

    public getCopyByName(name: string): BoxWallBit | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}