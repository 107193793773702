<template>
  <div class="ConteneurParent">
    <div id="ConteneurMillieu">
      <img src="@/assets/myo-logo-blanc.png" alt="Logo MYO" id="logo" width="150"/>
      <h1>On travaille sur quoi aujourd'hui ?</h1>
      <div id="conteneurButton">
        <a href="/FormCreation">
          <div id="button1">
            <img src="/new_doc_vert.png" alt="nouveau projet icone" id="new" />
            <img src="/new_doc_blanc.png" alt="nouveau projet icone" id="new-blanc" />
            Créer un nouveau projet
          </div>
        </a>
        <a href="#" @click="$refs.loadSaveModal.openModal()">
          <div id="button2">
            <img src="/edit_vert.png" alt="modifier un projet icone" id="mod" />
            <img src="/edit_blanc.png" alt="modifier un projet icone" id="mod-blanc" />
            Modifier un projet existant
          </div>
        </a>
      </div>
    </div>
    <modal ref="loadSaveModal">
      <template v-slot:header>
        Sauvegardes précédentes
      </template>
      <template v-slot:body>
        <div class="table-row heading">
            <div class="row-item"><p>Id du Projet</p></div>
            <div class="row-item"><p>Adresse</p></div>
            <div class="row-item"><p>Ville</p></div>
            <div class="row-item"><p>Province</p></div>
            <div class="row-item"><p>Dernière sauvegarde</p></div>
        </div>
        <div class="table-row" v-for="saveItem in saveList" v-bind:key="saveItem.id" @click="loadSave(saveItem.id)">
          <div v-if="saveItem.rapport !== null" class="row-item">{{ saveItem.id }}</div>
          <div v-if="saveItem.rapport !== null" class="row-item">{{ saveItem.rapport.adresse }}</div>
          <div v-if="saveItem.rapport !== null" class="row-item">{{ saveItem.rapport.ville }}</div>
          <div v-if="saveItem.rapport !== null" class="row-item">{{ saveItem.rapport.province }}</div>
          <div v-if="saveItem.rapport !== null" class="row-item">{{ new Date(saveItem.updated_at).toLocaleString() }}</div>
        </div>
      </template>
    </modal>
    <modal ref="chargementSauvegarde" :userCanCloseItHimself="false">
      <template v-slot:body>
        Chargement sauvegarde...
      </template>
    </modal>
     <div id="ConteneurCOPY">
        <p>© Gaïaa Sources inc. 2021.</p>
      </div>
  </div>
</template>    

<script lang="ts">
import { Action } from "vuex-class";
import { Options, Vue } from "vue-class-component";
import { ProjectManager } from "@/Managers/ProjectManager";
import Modal from '@/components/Modal.vue';
import { Project } from "@/project/Project";
import { ProjectDesereliazer } from "../Desereasizer/ProjectDesereliazer";

@Options({
  components: {
    Modal
  },
  methods: {
    assignToObject<T>(target: T, params: any) {
      for (const key of Object.keys(target)) {
          if (params[key] !== undefined) {
            target[key] = params[key];
          }
      }
    },
    loadSave (id: number) {
      this.$refs.chargementSauvegarde.openModal();
      this.getSave(id).then(response => {
        ProjectManager.setProject(ProjectDesereliazer.toInstance(new Project(), response));
        // response.data["blocks"].boxList.forEach(boxItem => {
        //   const box = new ProjectBox();
        //   box.floorName = boxItem.floorName;
        //   box.frontFacingName = boxItem.frontFacingName;
        //   box.wallName = boxItem.wallName;
        //   box.windowName = boxItem.windowName;
        //   box.position = new Vector3(boxItem.position.x, boxItem.position.y, boxItem.position.z);
        //   ProjectManager.getProject().addBoxtoListBox(box);
        // });
        this.$refs.chargementSauvegarde.closeModal();
        ProjectManager.setCurrentStep(3);
        ProjectManager.getProject().isSet = true;
        ProjectManager.changePageDependingCurrentStep(this.$router);
      });
    }
  },
  created () {
    this.loadAllSaves().then(response => {
      this.saveList = response.data;
    });
  }
})
export default class HomeClient extends Vue {
  @Action("save/getAllSaves") loadAllSaves: any;
  @Action("save/getSave") getSave!: any;
  saveList = [];
}
</script>

<style scoped>

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.heading {
  background-color: #ececec;
  color: #3e3e3e;
  font-weight: bold;
}

.row-item {
  display: flex;
  flex: 1;
  font-size: 14px;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.table-row:hover {
  cursor: pointer;
  background-color: #F0F0F0;
/*   box-shadow: 0px 1px 4px rgba(0, 0, 0, .08); */
}
#new, #mod
{
  width:40px;
  margin-right:30px;
}
#mod-blanc, #new-blanc
{
  display:none;
  width:40px;
  margin-right:30px;
}
#ConteneurCOPY
{
  display:flex;
  height:10vh;
  width:80%;
  text-align:right;
  margin-left:10%;
  margin-right:10%;
  align-items: flex-end;
  justify-content: center;
}
#logo
{
  margin-top:20px;
}
p
{
  color:#A3A3A3;
  Font-size:20px;
  font-family: roboto;
  font-weight:200;
}
.ConteneurParent
{
  background-image: url("/wood-gris.jpg");
  display:flex;
  flex-direction:column;
  align-items: center;
  height:95vh;
}
#ConteneurMillieu h1
{
  color:#A8CE6C;
  Font-size:60px;
  font-family: roboto;
  text-align:center;
  font-weight:200;
}
#ConteneurMillieu 
{
  width:80%;
  margin-right:10%;
  height:80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left:10%;
}
#conteneurButton
{
  display:flex;
  flex-direction:row;
  justify-content: center;
}
#button1
{
  background-color:white;
  color:#A8CE6C;
  font-size:25px;
  width:500px;
  display:flex;
  align-items:center;
  justify-content: center;
  height:60px;
  border-radius:50px;
}
a
{
  color:#A8CE6C;
  font-size:25px;
  text-decoration:none;
}

#button1:hover
{
  background-color:#A8CE6C;
  color:white;
  cursor:pointer;
}
#button2
{
  background-color:white;
  color:#A8CE6C;
  font-sizE:25px;
  margin-left:50px;
  display:flex;
  align-items:center;
  justify-content: center;
  width:500px;
  height:60px;
  border-radius:50px;
}
#button2:hover
{
  background-color:#A8CE6C;
  color:white;
  cursor:pointer;
}
#button2:hover #mod-blanc
{
  display:block;
}
#button2:hover #mod
{
  display:none;
}
#button1:hover #new-blanc
{
  display:block;
}
#button1:hover #new
{
  display:none;
}
@media screen and (max-width: 1200px) {
  #conteneurButton {
   flex-direction:column;
  }
}
</style>