import { Box } from '@/Box/Box';

export class PriceCalculator {

    public calculate(boxes: Box[]): number {
        let prix: number = 0;

        for (let i = 0; i < (boxes).length; i++) {
            const box = boxes[i];
            prix += box.getPrice();
            if(box.getRoom() !== undefined) {
                prix += box.getRoom()!.getPrice();
            }
        }
        return prix;
    }

}
