import { ProjectBoxWallGroup } from "@/project/ProjectBoxWallGroup";
import { Desereliazer } from "./Desereliazer";
import { ProjectBoxWallGroupDeseresiazer } from "./ProjectBoxWallGroupDeseresiazer";

export class ProjectBoxDeseresiazer extends Desereliazer{

    protected static keyComportement<ProjectBox>(key: string, obj: ProjectBox, json: any) {
        if(key === "wall_group") {
            const wallGroup = new Array<ProjectBoxWallGroup>();
            json[key].forEach(element => {
                wallGroup.push(ProjectBoxWallGroupDeseresiazer.toInstance(new ProjectBoxWallGroup(), element));
            });
            obj[key] = wallGroup;
        }
        else {
            obj[key] = json[key];
        }
    }
}
