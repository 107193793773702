
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    groupTitle: {
      type: String,
      require: true
    }
  },
  methods: {
    toggleCategoryVisibility (event: any) {
      const htmlElementToToggle = event.target.parentElement.children[1];
      if (htmlElementToToggle.style.display == "block"){
        htmlElementToToggle.style.display = "none";
      }
      else {
        htmlElementToToggle.style.display = "block";
      }
    },
    forceReRender () {
      this.$forceUpdate();
    }
  }
})
export default class MenuGroup extends Vue {
    show = false;
    symbole = "-";
}
