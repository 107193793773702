import { CustomEnum } from "@/CustomEnums/CustomEnum";
import { ifFieldEditable } from "@/decorators/EditableParameter/EditableParameter";
import { EditableParameterEntry } from "@/decorators/EditableParameter/EditableParameterEntry";
import { ifFieldIgnored } from "@/decorators/ObjectSearchIgnore";
import { getTrueFieldName } from "@/decorators/ParameterDisplay";
import { ifFieldHaveChild } from "@/decorators/ParameterHasChild";

export class ObjectParameter {
    public key: string;
    public value: any;
    public keyDisplayName: string;
    public editable: undefined | EditableParameterEntry;
    public stepsVisible: number[];

    public constructor (key: string, value: any, keyDisplayName: string, stepsVisible: number[], editable: undefined | EditableParameterEntry) {
        this.key = key;
        this.value = value;
        this.keyDisplayName = keyDisplayName;
        this.editable = editable
        this.stepsVisible = stepsVisible;
    }
}

export class ObjectParametersAsMapGetter {
    public getParameters(obj: any): Map<string, ObjectParameter> {
        const paramMap = new Map<string, ObjectParameter>();
        const names = Object.getOwnPropertyNames(obj).filter((name) => !ifFieldIgnored(obj,name));
        names.forEach((name) => {
            const key = name;
            let objParam: any = obj[name];
            if(ifFieldHaveChild(obj,name)){
                objParam = this.getParameters(objParam);
            }
            if(objParam !== undefined && objParam["entry"]) {
                objParam = (objParam as CustomEnum).value;
            }
            const displayOptions = getTrueFieldName(obj,name);
            paramMap.set(key, new ObjectParameter(key, objParam, displayOptions.name, displayOptions.stepsVisible, ifFieldEditable(obj,name)));
        });
        return paramMap;
    }
}