import { EditableParameterType } from "./EditableParameterType";

export class EditableParameterEntry {
    public type: EditableParameterType;
    public name: string;
    public setter: string;
    public needRefresh: boolean;
    public refreshMethodName?: string;
    public nullable: boolean;

    public constructor(type: EditableParameterType, name: string, setter: string, nullable: boolean, needRefresh: boolean, refreshMethodName?: string){
        this.type = type;
        this.name = name;
        this.setter = setter;
        this.needRefresh = needRefresh;
        this.refreshMethodName = refreshMethodName;
        this.nullable = nullable;
    }
}
