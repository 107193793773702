
import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { ProjectManager } from "@/Managers/ProjectManager";
import { Project } from "@/project/Project";
import { Options, Vue } from "vue-class-component";
import StepBanner from "../components/StepBanner.vue";

@Options({
    components: {
        StepBanner
    },
    methods: {
        updateProject () {
            if(ProjectManager.getProject().id !== null) {
                console.log()
                this.projet = ProjectManager.getProject();
                this.canRender = true;
            }
        }
    },
    created () {
        if(ProjectManager.getCurrentStep() === -1) {
            ProjectManager.setCurrentStep(7);
        }
        else {
            EventBus.on(EventType.avalableStepsUpdated, this.updateProject);
        }
    }
})

export default class Synthese extends Vue {
    projet!: Project;
    canRender = false;
}
