<template>
    <div class="Conteneur">
        <div class="table-row heading">
            <div class="row-item"><p>Numéro du Projet</p></div>
            <div class="row-item"><p>Prénom</p></div>
            <div class="row-item"><p>Nom</p></div>
            <div class="row-item"><p>Adresse courriel</p></div>
            <div class="row-item"><p>Budget</p></div>
            <div class="row-item"><p>Date de création</p></div>
            <div class="row-item"><p>Actions</p></div>
        </div>
        <div v-for="(rapport,i) in rapports" :key="i" class="table-row" >
            <div class="row-item"><p>{{rapport.id}}</p></div>
            <div class="row-item"><p>{{rapport.prenom}}</p></div>
            <div class="row-item"><p>{{rapport.nom}}</p></div>
            <div class="row-item"><p>{{rapport.email}}</p></div>
            <div class="row-item"><p>{{rapport.budget}} $</p></div>
            <div class="row-item"><p>{{rapport.created_at}}</p></div>
            <div class="row-item"><button @click="getRapport(rapport.id)">Voir</button></div>            
        </div>
        <div>
          <modal ref="rapportModal">
            <template v-slot:header>
              <h1>Projet numéro:{{projetSelectionnee.id}} </h1>
            </template>
            <template v-slot:body>
              <div class="row-item"><p>Prénom : {{projetSelectionnee.prenom}}</p></div>
              <div class="row-item"><p>Nom : {{projetSelectionnee.nom}}</p></div>
              <div class="row-item"><p>Adresse courriel : {{projetSelectionnee.email}}</p></div>
              <div class="row-item"><p>Budget : {{projetSelectionnee.rapport.budget}} $</p></div>
              <div class="row-item"><p>Finance : {{projetSelectionnee.rapport.finance}}</p></div>
              <div class="row-item"><p>Terrain : {{projetSelectionnee.rapport.terrain}}</p></div>
              <div class="row-item"><p>Date de création : {{projetSelectionnee.rapport.created_at}}</p></div>
              <div class="row-item"><p>Numéro de Téléphone : {{projetSelectionnee.rapport.telephone}}</p></div>
              <div class="row-item"><p>Adresse : {{projetSelectionnee.rapport.adresse}}</p></div>
              <div class="row-item"><p>Ville : {{projetSelectionnee.rapport.ville}}</p></div>
              <div class="row-item"><p>Province : {{projetSelectionnee.rapport.province}}</p></div>
              <div class="row-item"><p>Description : {{projetSelectionnee.rapport.presentation}}</p></div>
            </template>
            <template v-slot:footer>
              <div>
                <button @click="$refs.rapportModal.closeModal()">Fermer</button>
              </div>
            </template>
          </modal>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import Modal from '@/components/Modal.vue';

@Options({
  components: {
    Modal
  },
  created() {
    this.getAllRapport().then(rapports => {
      this.rapports = rapports.data;
    });
  },
  methods: { 
    getRapport(id){
      this.getRapportById(id).then(rapport => {
        this.$refs.rapportModal.openModal();
        this.projetSelectionnee = rapport;
      });
    }
  },
})
export default class Rapports extends Vue {
  @Action("getAllSaves", { namespace: "save" }) getAllRapport: any;
  @Action("getSave", { namespace: "save" }) getRapportById: any;

  rapports = {}; 
  projetSelectionnee = {};
}
</script>


<style scoped>
/* just a reset of the css */
body {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica';
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
}

.table-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 80%;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #DADADA;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.heading {
  background-color: #ececec;
  color: #3e3e3e;
  font-weight: bold;
}

.row-item {
  display: flex;
  flex: 1;
  font-size: 14px;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
}

.row-item:hover {
  cursor: pointer;
  background-color: #F0F0F0;
/*   box-shadow: 0px 1px 4px rgba(0, 0, 0, .08); */
}

.row-sub-container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.row-sub-container .row-item {
  padding: 8px 0;
  border-bottom: 1px solid #dadada;
}

.table-row:last-child,
.row-sub-container .row-item:last-child {
  border-bottom: 0;
}
</style>





