
import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/Modal.vue';
import { EventType } from '@/events/EventType';
import { EventBus } from '@/events/EventBus';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { EditableParameterEntryEnumItem } from '@/decorators/EditableParameter/EditableParameterEntryEnum';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { BoxWallState } from '@/Box/BoxParts/BoxWall/BoxWallState';
import { ObjectParameter } from '@/helpers/ObjectParametersAsMapGetter';
import { BoxWall } from '@/Box/BoxParts/BoxWall/BoxWall';
import { WallType } from '@/CustomEnums/WallType';
import { OrbitControls } from 'node_modules/@types/three/examples/jsm/controls/OrbitControls';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import MenuGroup from '@/components/MenuGroup.vue';

@Options({
  props: {
    params: Map,
    selectionManager: SelectionManager
  },
  components: {
    Modal,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    MenuGroup
  },
  computed: {
    nbItemsToShow () {
      return 3;
    }
  },
  methods: {
    getNewRelativePosition(oldPosition): number {
      return (oldPosition + this.decalageCamera) % 4;
    },
    getParam (name: string): ObjectParameter {
      return this.params.get(name);
    },
    changeMaterielIndividuel (wallGroupNumber: number, wallNb: number) {
      this.currentChangingMaterialPosition = [wallGroupNumber, wallNb];
      this.$refs.modalMaterialWallIndividuel.openModal();  
    },
    fermerModal () {
      this.$refs.modalMaterialWallIndividuel.closeModal();  
    },
    getEnumItemsListCaroussel (paramName: string) {
      let x = 0;
      const nullValue = new EditableParameterEntryEnumItem(undefined, undefined, "Aucun", "../aucun.png");
      const carousselItem = (this.params.get(paramName).editable.enumItemList.concat(this.params.get(paramName).editable.enumItemList) as Array<EditableParameterEntryEnumItem>).map(entry => { return { carousselId: x++, ...entry } });
      if (this.params.get(paramName).editable.nullable === true) {
        carousselItem.push({ carousselId: x++, ...nullValue });
      }
      return carousselItem;
    },
    openMaterialModal () {
      this.$refs.modalMaterialWall.openModal();
    },
    whenSelectedBoxChange () {
      if (this.selectionManager.isThereABoxSelected()) {
        this.updateSiMontreMurs();
        this.renderIt = true;
      }
      else {
        this.renderIt = false;
      }
    },
    updateSiMontreMurs () {
      const wallsGroup = this.selectionManager.getSelectedBox().getWalls();
      this.siMontreMurs = new Array<Array<boolean>>(wallsGroup.length);
      for(let x = 0; x < wallsGroup.length; x++) {
        this.siMontreMurs[x] = new Array<boolean>(wallsGroup[x].length);
        for(let y = 0; y < wallsGroup[x].length; y++) {
          this.siMontreMurs[x][y] = wallsGroup[x][y].constructor.name === BoxWall.name;
        }
      }
    },
    updateDecalageFromCamera () {
      const cameraAngle = Math.round(this.scene.getCamera().rotation.z/Math.PI*2);
      if (cameraAngle >= 0) {
        this.decalageCamera = 4 - cameraAngle;
      }
      else {
        this.decalageCamera = -cameraAngle;
      }
    },
    changeMateriel (item: ObjectParameter) {
      const wall = this.selectionManager.getSelectedBox().getWalls()[this.currentChangingMaterialPosition[0]][this.currentChangingMaterialPosition[1]] as BoxWall;
      wall.setWallType(WallType.getEntryOf(item.key) as WallType);
      EventBus.emit(EventType.selectedBoxNeedRefresh, this.selectionManager.getSelectedBox())
      EventBus.emit(EventType.fermerModal, this.fermerModal())
    },
    whenSceneLoadIsCompleted () {
      if(this.scene.getControls() != undefined) {
        (this.scene.getControls() as OrbitControls).addEventListener('change', this.updateDecalageFromCamera );
      }
    }
  },
  mounted () {
    EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
    EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
  },
  created () {
    SceneCommunicationBus.whenInstanceIsReady(CustomScene, (scene) => { this.scene = scene });
    EventBus.on(EventType.sceneLoadCompleted, this.whenSceneLoadIsCompleted);
    this.whenSceneLoadIsCompleted();
  }
})
export default class SelectionMenuStep4 extends Vue {
  decalageCamera: number = 0;
  renderIt = false;
  currentChangingMaterialPosition = [-1, -1];
  lienImageMur = BoxWallState.wall;
  scene!: CustomScene;
  siMontreMurs!: Array<Array<boolean>>;
}
