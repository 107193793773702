const IGNORE_FIELDS_BY_CLASS = new Map<string,string[]>();

export function ifFieldIgnored(object: any ,fieldName: string): boolean {
    if(IGNORE_FIELDS_BY_CLASS.has(object.constructor.name)) {
        if ((IGNORE_FIELDS_BY_CLASS.get(object.constructor.name as string) as string[]).indexOf(fieldName) > -1) {
            return true;
        }
    }
    let classToSearch = Object.getPrototypeOf(object);
    while(classToSearch != null) {
        const classHasIgnoredFields = IGNORE_FIELDS_BY_CLASS.has(classToSearch.constructor.name)
        if(classHasIgnoredFields) {
            if ((IGNORE_FIELDS_BY_CLASS.get(classToSearch.constructor.name as string) as string[]).indexOf(fieldName) > -1){
                return true;
            }
        }
        classToSearch = Object.getPrototypeOf(classToSearch);
    }
    return false;
}

export function ObjectSearchIgnore(target: any, name: string) {
    const clsName = target.constructor.name;
    let list: string[];

    if (IGNORE_FIELDS_BY_CLASS.has(clsName)) {
        list = IGNORE_FIELDS_BY_CLASS.get(clsName) as [];
    } else {
        list = [];
        IGNORE_FIELDS_BY_CLASS.set(clsName, list);
    }

    list.push(name);
}