<template>
  <div id="corpsPage">
    <div id="conteneurMillieu">
      <div id="conteneurHaut">
          <div id="conteneurHaut-gauche">
              <div id="conteneurHaut-gauche-2">
                <div id="conteneurHaut-1">
                   <h1>Votre profil</h1>
                </div> 
              </div> 
          </div> 
          <div id="conteneurHaut-3">
            <img src="@/assets/myo-logo-blanc.png" alt="Logo MYO" id="logo" height="50" width="150"/>
          </div>
      </div> 
      <div id="conteneurBas">
        <div id="conteneurGauche">
          <div id="GaucheHaut">
            <h2>Modifier vos informations</h2>
          </div>
          <form @submit.prevent="send" :class="erreurs ? 'erreurs' : false">
            <div class="Ligne1">
              <div class="LigneGauche">
                <label for="prenom">Prénom<span class="etoileform"> *</span></label>
                <input type="text" id="prenom" v-model="vv.prenom.$model" name="prenom" required @invalid="send">
              </div>
              <div class="LigneDroit">
                <label for="nom">Nom<span class="etoileform"> *</span></label>
                <input type="text" id="nom" v-model="vv.nom.$model" name="nom" required @invalid="send"> 
              </div>
            </div>
            <div id="Ligne4">
              <div class="LigneDroit">
                <label for="email">Courriel<span class="etoileform"> *</span></label>
                <input type="email" id="email" v-model="vv.email.$model" name="email" required @invalid="send">
              </div>
            </div>
            <div id="Ligne8">
              <button id="button">Modifier</button>
            </div>
          </form>
          <br><hr>
          <div id="GaucheHaut">
            <h2>Modifier votre mot de passe</h2>
          </div>
          <form @submit.prevent="sendPassword" :class="erreurs_ ? 'erreurs_' : false">
            <div id="Lignemotdepasse">
              <div class="LigneGauche3">
                <label for="ancienpassword">Ancien<span class="etoileform"> *</span></label>
                <input type="password" id="ancienpassword" v-model="v.ancienpassword.$model" name="ancienpassword" required @invalid="sendPassword">
              </div>
              <div class="LigneMillieu3">
                <label for="password">Nouveau<span class="etoileform"> *</span></label>
                <input type="password" id="password" v-model="v.password.$model" name="password" required @invalid="sendPassword"> 
              </div>
              <div class="LigneDroit3">
                <label for="password_confirmation">Confirmation<span class="etoileform"> *</span></label>
                <input type="password" id="password_confirmation" v-model="v.passwordconfirmation.$model" name="passwordconfirmation" required @invalid="sendPassword"> 
            </div>
            </div>
            <div id="Ligne8">
              <button id="button_">Modifier</button>
            </div>
          </form>
        </div>
        <div id="conteneurDroit">
        </div> 
      </div>   
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";

const namespace = "auth";


@Options({
  created() {
    this.requestUserDetails().then(utilisateur => {
      this.currentUser = ((utilisateur.data).user);
      this.user.prenom = this.currentUser.prenom;
      this.user.nom = this.currentUser.nom;
      this.user.email = this.currentUser.email;
    });
 },
  methods: {
    desactiveButton(id: string) {
      (document.getElementById(id) as HTMLButtonElement).disabled = true;
    },
    activeButton(id: string){
      (document.getElementById(id) as HTMLButtonElement).disabled = false;
    },
    validation(): boolean {
      this.submitted = true;
      this.vv.$touch();
      let isValid = true;

      if (this.vv.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    validationMdp(): boolean {
      this.submitted = true;
      this.v.$touch();
      let isValid = true;

      if (this.v.$invalid) {
        isValid = false;
      }

      return isValid;
    },
    send() {
      this.erreurs = true;
      if (this.validation()) {
        this.desactiveButton("button");

        const formData = new FormData();

        formData.append("prenom", this.user.prenom);
        formData.append("nom", this.user.nom);
        formData.append("email", this.user.email);
       

        const object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        const json = object;

        this.updateUser(json)
          .then(() => {
            this.requestUserDetails().then(() => {
              location.reload();
            });
            ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
              message:
                "Félicitations! Formulaire enregistré avec succès",
              duration: 5000,
              unique: true
            });
          })
          .catch((error) => {
            if(error.response.data.password){
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
                  message: "Le mot de passe de confirmation est différent du mot de passe saisi",
                  duration: 3000,
                  unique: true
                });
            }else{
              ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Cette adresse e-mail est déjà utilisée",
              duration: 3000,
              unique: true
              });
            }
            this.activeButton("button");     
          });
      }
    },
    sendPassword() {
      this.erreurs_ = true;
      if (this.validationMdp()) {
        this.erreurs_ = true;
        this.desactiveButton("button_");
        const formData = new FormData();
        formData.append("password", this.user_.password);
        formData.append("password_confirmation", this.user_.passwordconfirmation)
        formData.append("ancienpassword", this.user_.ancienpassword)

        const object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        const json = object;
        this.getPassword(json).then(() => {
          this.updatePassword(json)
          .then(() => {
            this.requestUserDetails().then(() => {
              location.reload();
            });
            ToastNotificationsBus.emit(ToastEmitterOptionsType.success, {
              message:
                "Félicitations! Formulaire enregistré avec succès",
              duration: 5000,
              unique : true
            });
          })
          .catch(() => {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Veuillez utiler un mot de passe différent de l'ancien",
              duration: 3000,
              unique : true
            });
            this.activeButton("button_");
          });
        })
        .catch(() => {
            ToastNotificationsBus.emit(ToastEmitterOptionsType.error, {
              message: "Mot de passe saisi incorrect",
              duration: 3000,
              unique: true
            });
          this.activeButton("button_");
        });
      }
    },
  },
})
export default class Profil extends Vue {

    erreurs = false;
    erreurs_= false;

    currentUser = {};

    rules = {
      prenom: {required},
      nom: {required},
      email: {required, email},
    };

    user = reactive({
      prenom: "",
      nom: "",
      email: "",
    });

    vv = useVuelidate(this.rules, {
      prenom: toRef(this.user, "prenom"),
      nom: toRef(this.user, "nom"),
      email: toRef(this.user, "email"),
    });

    rules_ = {
      ancienpassword: {required},
      password: {required},
      passwordconfirmation: {required}
    }

    user_ = reactive({
      ancienpassword: "",
      password: "",
      passwordconfirmation: "",
    });

    v = useVuelidate(this.rules_, {
        password: toRef(this.user_, "password"),
        passwordconfirmation: toRef(this.user_, "passwordconfirmation"),
        ancienpassword: toRef(this.user_, "ancienpassword"),
    });

    @Action("updateUser", { namespace }) updateUser: any;
    @Action("updatePassword", { namespace }) updatePassword: any;
    @Action("getPassword", { namespace }) getPassword: any;
    @Action("requestUserDetails", { namespace }) requestUserDetails: any;

    submitted = false;
   

}

</script>

<style lang="scss" scoped>
#GaucheHaut
{
  width:80%;
  margin-right:auto;
  margin-left:auto;
}
#GaucheHaut h2
{
  margin-top:20px;
  padding:0px;
  font-size:30px;
  color:#535353;
}
form,hr
{
  display:flex;
  flex-direction:column;
  width:80%;
  margin-right:auto;
  margin-left:auto;
}
label {
  font-size: 20px;
  margin-bottom:10px;
  margin-top:10px;
  font-weight: 600;
  color: #535353;
}
input {
  background: #f7f7f7;
  border: none;
  padding: 10px;
}

.Ligne1, #Ligne4
{
  display:flex;
  flex-direction:row;
}
#email
{
  margin-right:20px;
}
#Lignemotdepasse
{
  display:flex;
  flex-direction:row;
  width:100%;
}
.LigneGauche3, .LigneMillieu3, .LigneDroit3
{
  display:flex;
  flex-direction:column;
  width:33.33%;
}
 .LigneMillieu3
 {
  margin-right:20px;
  margin-left:20px;
}
#Ligne8
{
  display:flex;
  margin-top: 20px;
  justify-content: flex-end;
}
.LigneGauche, .LigneDroit
{
  display:flex;
  flex-direction:column;
  width:50%;
}
.LigneGauche
{
  margin-right:20px;
}

#conteneurHaut
{
  width:100%;
  background-image: url("/feuilles-fond.png");
  background-size: auto;
  background-position: center;
  height:10vh;
  display:flex;
  flex-direction:row;
}
#conteneurHaut-1
{
  width:70%;
  display:flex;
  align-items:center;
}
#conteneurHaut-gauche
{
  width:50%;
  display:flex;
  align-items:center;
}
#conteneurHaut-gauche-2
{
  width:80%;
  margin-right:auto;
  margin-left:auto;
  display:flex;
  align-items:center;
}
#conteneurHaut-2
{
  width:15%;
  display:flex;
  align-items:center;
  justify-content: flex-end;
  color:#456610;
}
#conteneurHaut-3
{
  width:50%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
#conteneurHaut h1
{
  color:white;
  font-weight:200;
}
#conteneurBas
{
  width:100%;
  display:flex;
  flex-direction:row;
  height:100%;
}

button {
  background-color: #a8ce6c !important;
  color: white !important;
  width: 163px;
  border-radius: 24px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  border: none !important;
  font-family: roboto !important;
}
button:hover {
  background-color: #a3a3a3 !important;
  cursor: pointer;
  border: none !important;
}
#conteneurDroit, #conteneurGauche
{
  width:50%;
}
#conteneurDroit
{
  background-image: url("/interior-house.jpg");
  background-size:cover;
  background-position: center;
}
#conteneurGauche
{
  background-color:white;
}
#corpsPage
{
  background-image: url("/wood-grispale.jpg");
  display:flex;
  flex-direction:column;
  mix-blend-mode: luminosity;
  justify-content: center;
  align-items: center;
  height:95vh;
}
#conteneurMillieu
{
  background-color:white;
  width:80%;
  display:flex;
  flex-direction:column;
  margin-right:auto;
  margin-left:auto;
  height:85%;
}
.invalid-feedback {
  color: red;
  font-family: roboto;
  font-size: 12px;
}

form.erreurs,form.erreurs_ {
    :invalid {
      outline: 2px solid red;
    }
}

</style>





