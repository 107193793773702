import materials from './../../configs/frontFacingMaterials.json';
import { CustomEnum } from './CustomEnum';
import { CustomEnumLibrairy } from './CustomEnumLibrairy';

export class FrontFacingMaterial extends CustomEnum {
    public static _initialize() {
        CustomEnumLibrairy.registerNewEnum(FrontFacingMaterial, FrontFacingMaterial.getMaterialsPossibleEntrys());
    }

    private static getMaterialsPossibleEntrys (): FrontFacingMaterial[] {
        const newList = new Array<FrontFacingMaterial>();
        materials.forEach((mat) => {newList.push(new FrontFacingMaterial(mat.name, mat.name, mat.preview));});
        return newList;
    }
}
FrontFacingMaterial._initialize();