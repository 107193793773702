import { SceneInfoProjet } from '@/scene/SceneInfoProjet';
import { Box } from "@/Box/Box";
import { PriceCalculator } from '@/calculators/PriceCalculator';

export class PriceManager {
    private infoProjet: SceneInfoProjet;
    private boxes: Box[];
    private priceCalculator: PriceCalculator;

    public constructor(infosUI: SceneInfoProjet, boxes: Box[]){
        this.infoProjet = infosUI;
        this.boxes = boxes;
        this.priceCalculator = new PriceCalculator();
    }

    public update(){
        this.infoProjet.prix = (this.priceCalculator.calculate(this.boxes))!;
    }

   public getPrice (): number {
       return this.infoProjet.prix;
   }
}