import { ObjectSearchIgnore } from '@/decorators/ObjectSearchIgnore';
import { Collision } from '@/physic/Collision';
import { Box3, BoxHelper, Material, Mesh, Object3D, Vector3 } from 'three';

export class SceneObject {
    @ObjectSearchIgnore
    private object: Object3D;
    @ObjectSearchIgnore
    private hitbox: Collision;

    public constructor (object: Object3D, hitbox: BoxHelper){
        this.object = object;
        this.hitbox = new Collision(hitbox);
    }

    public getObject(): Object3D {
        return this.object;
    }

    public getHitbox(): BoxHelper {
        this.hitbox.update();
        return this.hitbox.getHitbox();
    }

    public deplacer(deplacement: Vector3){
        this.object.position.add(deplacement);
        this.hitbox.update();
    }

    public setPosition(nouvellePosition: Vector3){
        this.object.position.copy(nouvellePosition)
        this.setTruePosition();
        this.hitbox.update();
    }

    public getSize (): Vector3 {
        return this.hitbox.getSize();
    }

    private setTruePosition(){
        const center = this.getTruePosition();
        this.object.position.x += ( this.object.position.x - center.x );
        this.object.position.y += ( this.object.position.y - center.y );
        this.object.position.z += ( this.object.position.z - center.z );
    }

    public getTruePosition(): Vector3 {
        const box = new Box3().setFromObject( this.object );
        return box.getCenter( new Vector3() );
    }

    public updateCollisionPosition () {
        this.hitbox.setPosition(this.getTruePosition());
    }

    public setRotation(angle: number) {
        const oldPosition = this.getTruePosition();
        oldPosition.y = 0;
        this.object.rotation.z = angle;
        this.setPosition(oldPosition);
    }

    public refreshObject3D(obj: Object3D){
        const oldObj = this.object;
        const oldPosition = this.getTruePosition();
        this.object = obj;
        this.object.rotation.z = oldObj.rotation.z;
        this.object.scale.set(oldObj.scale.x,oldObj.scale.y,oldObj.scale.z);
        this.hitbox.setFromObject(obj);
        this.setPosition(oldPosition);
        this.deplacer(new Vector3(0,-this.getSize().y/2,0));
    }

    public getCollision (): Collision {
        return this.hitbox;
    }

    public getCopy(): SceneObject {
        const newObj3D = this.getObject().clone(true);
        const hitbox = new BoxHelper(newObj3D, 0xff0000);
        hitbox.update();
        newObj3D.traverse(obj => {
            if(obj.type == "Mesh") {
                const mesh = obj as Mesh;
                if(Array.isArray(mesh.material)){
                    const matArray: Material[] = [];
                    mesh.material.forEach((mat) => {
                        matArray.push(mat.clone());
                    })
                    mesh.material = matArray;
                }
                else {
                    mesh.material = mesh.material.clone();
                }
            }
        });
        return new SceneObject(newObj3D,hitbox);
    }

    
}