<template>
  <div class="container">
    <div class="loadingBlocksContainer">
        <div class="loading">
        </div>
        <div :style="'height: ' + loadingPercentage +'%'" class="loadingCompleted"></div>
        <!-- <div class="loadingCompleted"></div> -->
    </div>
    <span>{{loadingPercentage }}%</span>
  </div>
</template>

<script lang="ts">
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    repositorys: Map,
    loadSaveHelper: LoadSaveHelper
  },
  methods: {
    updateProgress () {
      let totalProgress = 0;
      this.repositorys.forEach(repo => {
        totalProgress += repo.getLoadingPercentage();
      });
      totalProgress+=this.loadSaveHelper.getLoadingPercentage();
      this.loadingPercentage = (totalProgress/(this.repositorys.size+1)).toFixed(0);
      if (this.loadingPercentage >= 100) {
        clearInterval(this.updateProgressInterval);
      }
    }
  },
  mounted () {
    this.updateProgressInterval = setInterval(this.updateProgress, 100);
  }
})
export default class Loading extends Vue {
    loadingPercentage = 0;
    updateProgressInterval!: number;
}
</script>

<style scoped>
.loadingBlocksContainer {
  width: 30vh;
  height: 30vh;
  position: relative;
} 
.loading {
  background-image: url("/loading/loading block.svg");
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
}
.loadingCompleted {
  background-image: url("/loading/loading block completed.svg");
  position: absolute;
  background-size: 30vh 30vh;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-position-y: bottom;
}
.container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.container span{
  color: #a8ce6c;
  font-size: 3vh;
  font-family: roboto;
}
/* .container{
  margin: auto;
  width:30vh;
  text-align: center;
  vertical-align: middle;
  height: 30vh;
  line-height: 30vh; 
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #A8CE6C!important;
  -webkit-animation: spin 2s linear infinite; 
  animation: spin 2s linear infinite;
} */


/* .loading{
  -webkit-animation: test 2s linear infinite; 
  animation: test 2s linear infinite;
  font-size: 30px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}  


@-webkit-keyframes test {
  0% { -webkit-transform: rotate(360deg); }
 
}

@keyframes test {
  0% { transform: rotate(360deg); }
  
}   */



</style>