
import { Options, Vue } from "vue-class-component";
import { ToastNotificationManager } from "./ToastNotifications/ToastNotificationManager";
import Header from "./components/Header.vue";
import { Getter } from "vuex-class";

@Options({
  data() {
    return {
    };
  },
  components: {
    Header,
   
  },
  computed: {
    getRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.toastNotificationManager = new ToastNotificationManager(this.$toast);
  },
})
export default class App extends Vue {
  toastNotificationManager!: ToastNotificationManager;
  @Getter("auth/isLoggedIn") isLoggedIn: any;
}
