import { Box } from '@/Box/Box';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { GridHelper, Mesh, MeshBasicMaterial, PlaneBufferGeometry, Vector3 } from 'three';

export class SceneGrid {
    private gridObject: GridHelper;
    private size: number;
    private divisions: number;
    private positionByGrid: Vector3[][][];
    private sceneObjectsPlacementOnGrid: Map<Box,Vector3>;
    private gridHitbox: Mesh;
    private gazonMesh!: Mesh;
    private nbEtage: number;
    private etageActif: number;
    private hauteurEtage: number;

    public constructor (size: number, divisions: number, nbEtage: number, hauteurEtage: number){
        this.divisions = divisions;
        this.size = size;
        this.gridObject = new GridHelper(size, divisions, "white", "white");
        this.positionByGrid = [];
        this.nbEtage = nbEtage;
        this.hauteurEtage = hauteurEtage;
        this.etageActif = 0;
        this.sceneObjectsPlacementOnGrid = new Map<Box,Vector3>();
        this.generatePositionsGrid();
        this.gridObject.geometry.computeBoundingBox();
        this.gridHitbox = new Mesh();
        this.generateHitbox(size);
    }

    private generateHitbox(size: number) {
        const geometryHitbox = new PlaneBufferGeometry(size, size);
        geometryHitbox.rotateX( - Math.PI / 2 );
        this.gridHitbox = new Mesh( geometryHitbox, new MeshBasicMaterial( { visible: false } ) );

        const geometryGazon = new PlaneBufferGeometry(size*4, size*4);
        geometryGazon.rotateX( - Math.PI / 2 );
        this.gazonMesh = new Mesh( geometryGazon, new MeshBasicMaterial( { color: "#898989" } ) );
        this.gazonMesh.position.y-=0.01;
    }

    private generatePositionsGrid () {
        this.positionByGrid = [];
        const gridSquareSize = this.getSquareSize();
        const gridInitionXZPosition = -(this.size/2);
        for(let x = 0; x < this.divisions; x++){
            this.positionByGrid.push([]);
            for(let y = 0; y < this.nbEtage; y++){  
                this.positionByGrid[x].push([]);
                for(let z = 0; z < this.divisions; z++){  
                    const posX = gridInitionXZPosition + (x*gridSquareSize);
                    const posY = (y - this.etageActif) * this.hauteurEtage;
                    const posZ = gridInitionXZPosition + (z*gridSquareSize);
                    this.positionByGrid[x][y].push(new Vector3(posX,posY,posZ));
                }
            }
        }
    }

    public getGridMiddlePosition (): Vector3 {
        const xzMiddle = this.divisions / 2;
        return this.positionByGrid[xzMiddle][this.etageActif][xzMiddle];
    }

    public addBoxOnGrid(obj: Box, gridPosition: Vector3) {
        this.sceneObjectsPlacementOnGrid.set(obj,gridPosition);
    }

    public removeBoxOnGrid(obj: Box) {
        this.sceneObjectsPlacementOnGrid.delete(obj);
    }

    public getGridPositionFromBox (obj: Box): Vector3 | undefined {
        return this.sceneObjectsPlacementOnGrid.get(obj);
    }

    public getTruePositionFromBox(obj: Box): Vector3 | undefined {
        if (this.sceneObjectsPlacementOnGrid.has(obj)){
            return this.getPositionFromGridSquare(this.sceneObjectsPlacementOnGrid.get(obj) as Vector3);
        }
        return undefined;
    }

    public setEtageActif (nouvelEtage: number){
        this.etageActif = nouvelEtage;
        this.regenerateYPosForGrid();
        this.gazonMesh.position.y = -nouvelEtage*this.hauteurEtage;
        this.gazonMesh.position.y-=0.01;
        EventBus.emit(EventType.floorUpdated);
    }

    public getEtageActif (): number {
        return this.etageActif;
    }

    private regenerateYPosForGrid(){
        for(let x = 0; x < this.divisions; x++){
            for(let y = 0; y < this.nbEtage; y++){
                for(let z = 0; z < this.divisions; z++){  
                    const posY = (y - this.etageActif) * this.hauteurEtage;
                    this.positionByGrid[x][y][z].y = posY;
                }
            }
        }
    }

    public getGrid() {
        return this.gridObject;
    }

    public getBoxBellowThisBox(targetBox: Box): Box | null {
        return this.getBoxOnBoxPositionTargetWithFloorDifference(targetBox, -1);
    }

    private getBoxOnBoxPositionTargetWithFloorDifference(targetBox: Box, floorDifference: number){
        let returnedBox: Box | null = null;
        this.sceneObjectsPlacementOnGrid.forEach((position, box) => {
            if(box != targetBox) {
                if(this.getFloorOfBox(targetBox) as number + floorDifference === this.getFloorOfBox(box)) {
                    const inX = Math.abs(targetBox.getPosition().x - box.getPosition().x) <= 1; 
                    const inZ = Math.abs(targetBox.getPosition().z - box.getPosition().z) <= 1; 
                    if (inX && inZ){
                        returnedBox = box;
                    }
                }
            }
        });
        return returnedBox;
    }

    public getBoxOverThisBox(targetBox: Box): Box | null {
        return this.getBoxOnBoxPositionTargetWithFloorDifference(targetBox, 1);
    }

    public getGridHitBox() {
        return this.gridHitbox;
    }

    public getGazon() {
        return this.gazonMesh;
    }

    public getSquareSize () {
        return this.size/this.divisions;
    }

    public getFloorOfBox (obj: Box): number | null {
        if (this.sceneObjectsPlacementOnGrid.has(obj)) {
            return (this.sceneObjectsPlacementOnGrid.get(obj) as Vector3).y;
        }
        else {
            return null;
        }
    }

    public getGridPositionDiferenceBetweenTwoObjects (obj1: Box, obj2: Box): Vector3 {
        let pos1 = this.sceneObjectsPlacementOnGrid.get(obj1) as Vector3;
        if(pos1 == null) {
            pos1 = this.getGridSquareContainingPosition(obj1.getPosition()) as Vector3;
        }
        const pos2 = this.sceneObjectsPlacementOnGrid.get(obj2) as Vector3;
        return pos2.clone().sub(pos1);
    }

    public getGridSquareContainingPosition (position: Vector3): Vector3 | null {
        const positionWithDecalage = new Vector3().copy(position).addScalar(this.getSquareSize()/2);
        positionWithDecalage.y = 0;
        const squareSize = this.getSquareSize();
        const decalage = this.divisions/2;
        const gridSquare = new Vector3(Math.floor(positionWithDecalage.x/squareSize)+decalage, this.etageActif, Math.floor(positionWithDecalage.z/squareSize)+decalage);
        if( gridSquare.x >= this.divisions || gridSquare.z >= this.divisions) {
            return null;
        }
        return gridSquare;
    }

    public getPositionFromGridSquare(position: Vector3): Vector3 {
        return this.positionByGrid[position.x][position.y][position.z];
    }
}