import { ToastEmitter } from "./ToastEmitter/ToastEmitter";
import { ToastEmitterOptionsType } from "./ToastEmitter/ToastEmitterOptionsType";
import { ToastNotificationsBus } from "./ToastNotificationsBus";

export class ToastNotificationManager {

    private toastEmitter: ToastEmitter;

    public constructor (toastEmitter: ToastEmitter) {
        this.toastEmitter = toastEmitter;
        ToastNotificationsBus.on(ToastEmitterOptionsType.error, this.generateErrorToast.bind(this));
        ToastNotificationsBus.on(ToastEmitterOptionsType.success, this.generateSuccessToast.bind(this));
    }

    private generateErrorToast(options: any) {
        if(options.unique !== undefined && options.unique == true ){
            this.toastEmitter.clear();
        }
        this.toastEmitter.error(options.message, options);
    }

    private generateSuccessToast(options: any) {
        if(options.unique !== undefined && options.unique == true ){
            this.toastEmitter.clear();
        }
        this.toastEmitter.success(options.message, options);
    }
}