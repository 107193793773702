import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { Repository } from "./Repository";
import frontFacing from '../../configs/frontFacing.json';
import frontFacingMaterials from '../../configs/frontFacingMaterials.json';
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { BoxHelper } from "three";
import { BoxFrontFacing } from "@/Box/BoxParts/BoxFrontFacing";
import { FrontFacingMaterial } from "@/CustomEnums/FrontFacingMaterial";

export class FrontFacingRepository extends Repository<BoxFrontFacing> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (frontFacing as any[]).length * frontFacingMaterials.length;
        frontFacingMaterials.forEach(mat => {
            frontFacing.forEach(window => {
                let path = 'models/Facades Avant/' + mat.name + "/" + window.filename; 
                if(mat.name == "Anthracite") {
                    const pathSplited = path.split(".");
                    pathSplited[0] += " anthracite";
                    path = pathSplited[0] + "." + pathSplited[1];
                }
                loader.loadObj(path).then(wallInstance => {
                    wallInstance.position.copy(this.object3DTruePositionCalculator.getTruePosition(wallInstance));
                    const hitbox = new BoxHelper(wallInstance, 0xff0000);
                    hitbox.update();
                    this.objectMap.set(mat.name + "_" + window.name, new BoxFrontFacing(wallInstance, hitbox, window.name, FrontFacingMaterial.getEntryOf(mat.name) as FrontFacingMaterial));
                    this.verifyIfEndLoading();
                });
            });
        });
    }

    public getCopyByName(name: string): BoxFrontFacing | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}