import { Box } from '@/Box/Box';
import { RayCastMousePositionCalculator } from '@/calculators/RayCastMousePositionCalculator';
import { ArrowHelper, Camera, Intersection, Object3D, Raycaster, Renderer, Vector3 } from 'three';
import { SceneObject } from '../SceneObject/SceneObject';
import { SceneRayCasterObjectType } from './SceneRayCasterObjectType';

export class SceneRayCaster {
    private raycaster: Raycaster;
    private rayCastMousePositionCalculator: RayCastMousePositionCalculator;

    public constructor (raycasterPrecision: number){
        this.raycaster = new Raycaster();
        this.rayCastMousePositionCalculator = new RayCastMousePositionCalculator();
        this.raycaster.params =
        {
            Line: { threshold: raycasterPrecision },
            Points: { threshold: raycasterPrecision },
        }
    }

    public setFromCameraConsideringMouse(mouseEvent: MouseEvent, renderer: Renderer, camera: Camera) {
      this.raycaster.setFromCamera( this.rayCastMousePositionCalculator.calculate(mouseEvent,renderer), camera );
    }

    public setFromCameraIgnoringMouse(camera: Camera) {
        //this.raycaster.setFromCamera(camera.position, camera );
        this.raycaster.ray.origin.copy(camera.position);
        this.raycaster.ray.direction.copy(camera.getWorldDirection( new Vector3()));
      }

    public getIntersectedsObjects(objects: Object3D[]) {
        return this.raycaster.intersectObjects(objects,true);
    }

    public getBoxFromIntersectedObjectOfAMesh(boxs: Box[]): Box | null {
        let targetBox;
        let lastDistance: number | null = null;
        boxs.forEach(box => {
            const intersectedObject = this.getFirstIntersectedObject(box.getAllObjects3dOfBox(), SceneRayCasterObjectType.Mesh);
            if (intersectedObject != null && (lastDistance == null || lastDistance > intersectedObject.distance) ) {
                lastDistance = intersectedObject.distance;
                targetBox = box;
            }
        });
        return targetBox;
    }

    public getSceneObjectFromIntersectedObjectOfAMesh(objects: SceneObject[]): SceneObject | null {
        let target: SceneObject | null = null;
        let lastDistance: number | null = null;
        objects.forEach(item => {
            const intersectedObject = this.getFirstIntersectedObject([item.getObject()], SceneRayCasterObjectType.Mesh);
            if (intersectedObject != null && (lastDistance == null || lastDistance > intersectedObject.distance) ) {
                lastDistance = intersectedObject.distance;
                target = item;
            }
        });
        return target;
    }

    public getFirstIntersectedObject(objects: Object3D[], objectType: SceneRayCasterObjectType): Intersection | null {
        const intersects = this.raycaster.intersectObjects(objects,true);
        if ( intersects.length > 0 ) {
            let i = 0;
            while (intersects.length > i && intersects[i].object.type != objectType){
              i++;
            }
            if (i != intersects.length) {
              return intersects[ i ];
            }
        }
        return null;
    }
}