
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    repositorys: Map,
    loadSaveHelper: LoadSaveHelper
  },
  methods: {
    updateProgress () {
      let totalProgress = 0;
      this.repositorys.forEach(repo => {
        totalProgress += repo.getLoadingPercentage();
      });
      totalProgress+=this.loadSaveHelper.getLoadingPercentage();
      this.loadingPercentage = (totalProgress/(this.repositorys.size+1)).toFixed(0);
      if (this.loadingPercentage >= 100) {
        clearInterval(this.updateProgressInterval);
      }
    }
  },
  mounted () {
    this.updateProgressInterval = setInterval(this.updateProgress, 100);
  }
})
export default class Loading extends Vue {
    loadingPercentage = 0;
    updateProgressInterval!: number;
}
