<template>
        <div id="conteneurHaut">
            <div id="conteneurHaut-gauche">
                <div id="conteneurHaut-1">
                    <div class="ligneSteps">
                        <button id="btnLastStep" class="btnStep" v-if="showLastStepButtom" @click="decrementSteps">&lt;</button>
                        <div style="background-color: transparent;" v-for="[step, isCompleted] in stepsNumbers" v-bind:key="step">
                            <span v-if="currentStep === step" class="stepItem stepItem-currentStep" @click="changeStep(step)">{{ step }}</span>
                            <span v-else-if="isCompleted" class="stepItem" @click="changeStep(step)">{{ step }}</span>
                            <span v-else class="stepItem stepItem-disabled">{{ step }}</span>
                        </div>
                        <button id="btnNextStep" class="btnStep" v-if="showNextStepButtom" @click="incrementSteps">&gt;</button>
                    </div>
                </div>
                <div id="conteneurHaut-2">
                    <h1> {{ bannerText }}</h1>
                </div>
            </div> 
            <div id="conteneurHaut-droite">
                <button class="btnBanner" v-if="showNextStepButtom" @click="incrementSteps">ÉTAPE SUIVANTE</button>
                <button class="btnBanner" v-if="showNextStepButtom" @click="saveProject()">SAUVEGARDER</button>
            </div>
        </div>
</template>

<script lang="ts">
import { ProjectDesereliazer } from '@/Desereasizer/ProjectDesereliazer';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { ProjectManager } from '@/Managers/ProjectManager';
import { Project } from '@/project/Project';
import { Action } from 'vuex-class';
import { Options, Vue } from 'vue-class-component';
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';

@Options({
    computed: {
        maxSteps () {
            return ProjectManager.getMaximumStep();
        }
    },
    methods: {
        onChangedStep (infoChangeStep) {
            ProjectManager.calculateAvalablesSteps(infoChangeStep.oldStep === -1);
            ProjectManager.changePageDependingCurrentStep(this.$router);
            this.updateStepsShowed();
        },
        incrementSteps () {
            ProjectManager.incrementSteps();
        },
        decrementSteps () {
            ProjectManager.decrementSteps();
        },
        changeStep (newStep: number) {
            ProjectManager.setCurrentStep(newStep);
        },
        saveProject () {
            this.loadSaveHelper.saveProject(this.save, true).then(() => {
                ProjectManager.calculateAvalablesSteps(true);
            });
        },
        updateStepsShowed () {
            this.currentStep = ProjectManager.getCurrentStep();
            this.showNextStepButtom = !ProjectManager.isCurrentStepAtMaximumStep() && ProjectManager.getIfStepAvalable(this.currentStep+1) && !ProjectManager.getIfIsChanging();
            this.showLastStepButtom = !ProjectManager.isCurrentStepAtMinimumStep() && !ProjectManager.getIfIsChanging();
            this.bannerText = ProjectManager.getCurrentStepBannerText();
            this.stepsNumbers = ProjectManager.getAllStepsNumbersIfCompleted();
        }
    },
    created () {
        EventBus.on(EventType.stepChanged, this.onChangedStep);
        EventBus.on(EventType.avalableStepsUpdated, this.updateStepsShowed);
        SceneCommunicationBus.whenInstanceIsReady(LoadSaveHelper, (loadSaveHelper) => { this.loadSaveHelper = loadSaveHelper; });
        if(ProjectManager.getProject().id === null && this.$route.params.projectId !== "") {
            this.getSave(Number.parseInt(this.$route.params.projectId)).then(response => {
                ProjectManager.setProject(ProjectDesereliazer.toInstance(new Project(), response));
                ProjectManager.getProject().isSet = true;
                ProjectManager.calculateAvalablesSteps(true);
            });
        }
    },
    mounted () {
        if(ProjectManager.getProject().id !== null) {
            ProjectManager.calculateAvalablesSteps(true);
        }
    }
})
export default class StepBanner extends Vue {
  currentStep: number = 0;
  showNextStepButtom = true;
  showLastStepButtom = true;
  bannerText = "";
  stepsNumbers: [number, boolean] [] = ProjectManager.getAllStepsNumbersIfCompleted();
  @Action("save/getSave") getSave!: any;
  @Action("save/saveProject") save: any;
  loadSaveHelper!: LoadSaveHelper;
}
</script>

<style scoped>
.btnBanner {
    background-color: white;
    font: normal normal medium 15px/20px Roboto;
    padding: 0.1em 1.1em;
    border-radius: 25px;
    border-color: transparent;
    letter-spacing: 0px;
    color: #202224;
    text-transform: uppercase;
    opacity: 1;
}
.btnBanner:hover {
    color: white;
    background-color: #A8CE6C;
}
#conteneurHaut
{
  width:100vw;
  background-image: url("/feuilles-fond.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:10vh;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
#conteneurHaut button {
    margin: 5px;
}
#btnNextStep {
    float: right;
}
#conteneurHaut-1
{
    display:flex;
    align-items:center;
    width: 24vw;
    justify-content: center;
}
#conteneurHaut-2
{
    display:flex;
    align-items:center;
    justify-content: flex-end;
    color:#456610;
}
#conteneurHaut-gauche
{
    display:flex;
    align-items:center;
}
#conteneurHaut-gauche-2
{
    width:80%;
    margin-right:auto;
    margin-left:auto;
    display:flex;
    align-items:center;
}
#conteneurHaut-droite
{
    display:flex;
    justify-content: center;
    align-items:center;
}
#conteneurHaut h1
{
    color:white;
    font-weight:200;
}
.conteneurHaut-droite-interne {
    display: flex;
    flex-direction: column;
    align-items:center;
}
.ligneSteps {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.stepItem {
    background-color: black;
    color: #A8CE6C;
    width: 2.2vh;
    height: 2.2vh;
    margin: 0 0.8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    font-size: 1.5vh;
    border-radius: 50%;
    padding: 0.1vh;
    cursor: pointer;
    
}
.stepItem-disabled {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    width: calc(2.2vh - 2px);
    height: calc(2.2vh - 2px);
}
.stepItem-currentStep {
    background-color: white;
    color: black;
}
.btnStep {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    width: 2.2vh;
    height: 2.2vh;
    padding: 0.1vh;
    margin: 0px 0px !important;
    font-size: 2vh;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}
</style>
