import { FrontFacingMaterial } from "@/CustomEnums/FrontFacingMaterial";
import { BoxHelper, Object3D } from "three";
import { BoxPart } from "./BoxPart";

export class BoxFrontFacing extends BoxPart{
    private material: FrontFacingMaterial;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string, material: FrontFacingMaterial){
        super(object, hitbox, name);
        this.material = material;
    }
    
    public getCopy(): BoxFrontFacing {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxFrontFacing(parentCopy.getObject(),parentCopy.getHitbox(), this.name, this.material);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }

    public setMaterial (mat: FrontFacingMaterial) {
        this.material = mat;
    }

    public getMaterial (): FrontFacingMaterial {
        return this.material;
    }
}