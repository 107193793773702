import { CircleGeometry, Light, Mesh, MeshBasicMaterial, Object3D, Vector3 } from "three";

export class SceneSun {

    private sunObject: Object3D;
    private lightSource: Light;

    public constructor(lightSource: Light) {
        this.lightSource = lightSource;
        const geometry = new CircleGeometry( 3, 32 );
        const material = new MeshBasicMaterial( { color: 0xffffff } );
        this.sunObject = new Mesh( geometry, material );
    }

    public getSunLightSource(): Object3D {
        return this.lightSource;
    }

    public getSunObject(): Object3D {
        return this.sunObject;
    }

    public setSunPosition(newPosition: Vector3) {
        this.lightSource.position.set(newPosition.x, newPosition.y, newPosition.z);
        this.sunObject.position.set(newPosition.x, newPosition.y, newPosition.z);
    }

    public setSunRotation(newRotation: Vector3) {
        this.sunObject.rotation.set(newRotation.x, newRotation.y, newRotation.z);
    }
}