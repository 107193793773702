import { Box } from "@/Box/Box";
import { SceneGrid } from "@/scene/SceneGrid";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ActionErrorMessage } from "./ActionErrorMessage";
import { ActionRuleChecker } from "./ActionRuleChecker";

export class MovingRuleChecker extends ActionRuleChecker {

    private rulesToCheck: Function[];
    private sceneObjects: Array<Box>;
    private grid: SceneGrid;

    public constructor (sceneObjects: Array<Box>, grid: SceneGrid) {
        super();
        this.rulesToCheck = [];
        this.sceneObjects = sceneObjects;
        this.grid = grid;
        this.addAllRulesToCheck();
    }

    protected addAllRulesToCheck () {
        this.rulesToCheck.push(this.doesBoxHaveStairs.bind(this));
        this.rulesToCheck.push(this.isBoxUnderAnotherBox.bind(this));
    }

    public isActionPermitted (deletedBox: Box): boolean {
        let actionErrorMessage: ActionErrorMessage | null = null;
        let ifActionPermitted = true;
        this.rulesToCheck.forEach(rule => {
            actionErrorMessage = rule(deletedBox);
            if (actionErrorMessage != null) {
                ifActionPermitted = false;
                ToastNotificationsBus.emit(ToastEmitterOptionsType.error, actionErrorMessage.toToastNotificationsMessage());
            }
        });
        return ifActionPermitted;
    }

    private isBoxUnderAnotherBox (movedBox: Box):  ActionErrorMessage | null {
        let actionErrorMessage:  ActionErrorMessage | null = null;
        this.sceneObjects.forEach(box => {
            if(box != movedBox) {
                if(this.grid.getFloorOfBox(movedBox) != null) {
                    if(this.grid.getFloorOfBox(movedBox) as number + 1 == this.grid.getFloorOfBox(box)) {
                        const inX = Math.abs(movedBox.getPosition().x - box.getPosition().x) <= 1; 
                        const inZ = Math.abs(movedBox.getPosition().z - box.getPosition().z) <= 1; 
                        if (inX && inZ){
                            actionErrorMessage = new ActionErrorMessage("La pièce est en dessous d'une autre pièce");
                        }
                    }
                }
            }
        });
        return actionErrorMessage;
    }

    private doesBoxHaveStairs (movedBox: Box):  ActionErrorMessage | null {
        if (movedBox.getStairs() != undefined) {
            return new ActionErrorMessage("La pièce est attachée à des escaliers. Détachez les avant")
        }
        else {
            return null;
        }
    }
}