import { ToastEmitterOptions } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptions";
import { ToastEmitterOptionsPosition } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsPosition";

export class ActionErrorMessage {
    private message: string;

    public constructor (message: string) {
        this.message = message;
    }

    public toToastNotificationsMessage (): ToastEmitterOptions {
        return { message: this.message, position: ToastEmitterOptionsPosition.top, duration: 5000 };
    }
}