<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:700,600"
      rel="stylesheet"
      type="text/css"
    />
    <div class="ConteneurParent">
      <div id="ConteneurMillieu">
        <div id="ConteneurGauche" class="element-ConteneurMillieu">
          <img src="@/assets/IMG/ban.png" alt="Logo MYO" id="ban" />
        </div>
        <div id="ConteneurDroite" class="element-ConteneurMillieu">
          <p class="registrer-page-login">
            Vous n'avez pas de compte? <a href="/inscription">Inscrivez-vous</a>
          </p>
          <img src="@/assets/myologo.png" alt="Logo MYO" id="logo" width="250"/>
          <div class="box">
            <h1 class="Welcome">Connexion</h1>
            <form @submit.prevent="login" :class="errors ? 'errors' : false">
              <div class="form-group">
                <div>
                  <label for="email">Adresse courriel<span class="etoileform"> *</span></label>
                </div>
                <input type="email" v-model="vv.email.$model" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && vv.email.$error }" required @invalid="login" />
              </div>
              <div class="form-group">
                <label for="password">Mot de passe<span class="etoileform"> *</span></label>
                <input type="password" v-model="vv.password.$model" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && vv.password.$error }" required @invalid="login"/>
              </div>
              <div id="div-bouton" class="form-group">
                <button id="button" class="btn btn-primary">CONNEXION</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" type="text/x-template" id="modal-template">
import { Options, Vue } from "vue-class-component";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, toRef } from "vue";
import { Action } from "vuex-class";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { ToastEmitterOptionsPosition } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsPosition";
import Modal from '@/components/Modal.vue';
import { load } from 'recaptcha-v3'


const namespace = "auth";


@Options({
  components: {
    Modal
  },
  mounted(){
    load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
    useRecaptchaNet: true
    }).then((recaptcha) => {
      this.recaptcha = recaptcha;
    })
  },
  methods: {
    desactiveButton() {
      (document.getElementById("button") as HTMLButtonElement).disabled = true;
    },
    activeButton(){
      (document.getElementById("button") as HTMLButtonElement).disabled = false;
    },

    login() { 
        this.recaptcha.execute("login").then((token) => {
          this.submitted = true;
          this.errors = true;
          this.vv.$touch();
          if (this.vv.$invalid) {
            return;
          }
          this.desactiveButton();
          this.user.token = token;
          this.actionLogin(this.user)
            .then(() => {
              this.actionRequestUserDetails().then(() => {
                location.reload();
              });
            })
            .catch((erreur) => {
              let message = "Une erreur s'est produite";
              switch (erreur.response.data) {
                case "Unauthorised": {
                  message = "Courriel ou mot de passe invalide. Veuillez réessayer";
                  break;
                }
                case "Not Human": {
                  message = "Il y a un problème avec recaptcha, veuillez vérifier si vous le bloquez.";
                  break;
                }
              }
              ToastNotificationsBus.emit(ToastEmitterOptionsType.error, { message: message, duration: 5000, position: ToastEmitterOptionsPosition.bottom, unique: true});
              this.activeButton();
            });
           
        });
    }
  }
})

export default class Home extends Vue {
  user = reactive({
    email: "",
    password: "",
  });

  errors = false;

  @Action("login", { namespace }) actionLogin: any;
  @Action("requestUserDetails", { namespace }) actionRequestUserDetails: any;

  rules = {
    email: { required, email },
    password: { required },
  };

  vv = useVuelidate(this.rules, {
    email: toRef(this.user, "email"),
    password: toRef(this.user, "password"),
  });

  submitted = false;

  recaptcha = null;
  
}
</script>


<style lang="scss" scoped>
.box
{
  width:80%;
  margin-right:10%;
  margin-left:10%;
}
.registrer-page-login {
  text-align: right;
  margin-right: 10%;
  margin-bottom: 40px;
  font-size: 20px;
  font-family: roboto !important;
}
.registrer-page-login a {
  color: #a8ce6c !important;
  font-family: roboto !important;
  text-decoration: none;
}

#password2,
#email2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
#logo {
  margin-left: 10%;
}
#ban {
  width: 100%;
  height: 100%;
}
.registrer-page-login a:hover {
  color: #202224 !important;
}

label {
  display: flex;
  font-size: 20px;
  font-family: roboto !important;
  font-weight: 600;
  color: #535353;
  display: inline
}
input {
  width:100%;
  background: #e8e6e6;
  margin-top:5px;
  margin-bottom:5px;
  border: none;
  padding-top: 10px;
  padding-bottom:10px;
}
.invalid-feedback {
  color: red;
  padding-left: 10%;
  font-size: 12px;
  font-family: roboto !important;
}
button {
  background-color: #a8ce6c !important;
  color: white !important;
  width: 163px;
  border-radius: 24px;
  margin-top: 50px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  border: none !important;
  font-family: roboto !important;
}
button:hover {
  background-color: #a3a3a3 !important;
  cursor: pointer;
  border: none !important;
}
#div-bouton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.ConteneurParent {
  background-image: url("/wood-vert.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#ConteneurMillieu {
  width: 80%;
  display: flex;
  flex-direction: row;
  height: 80%;
}
.background-gris {
  width: 100%;
  height: 100%;
}
.element-ConteneurMillieu {
  width: 50%;
  height: 100%;
}
#ConteneurDroite {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#ConteneurGauche {
  background-color: white;
}
.spa{
  margin-left: -7%;
}
.spa_{
  margin-left: -3%;
}
.Welcome {
  color: #535353;
  letter-spacing: 0px;
  font-size: 40px;
  font-family: "Roboto";
  font-weight: 600;
  opacity: 1;
}
@media screen and (max-width: 1200px) {
.registrer-page-login {
    font-size:18px;
    width:auto;
  }
  }
@media screen and (max-width: 1075px) {
  #ConteneurMillieu {
    flex-direction: column;
  }
  .element-ConteneurMillieu {
    width: 100%;
  }
  .Welcome {
    font-size: 30px;
    margin-left: 0;
  }
  button {
margin-top:10px;
    }
    
  label {
    font-size:20px;
  }
  #ConteneurDroite {
    padding: 0px;
    flex-direction: column-reverse;
  }
  #ConteneurGauche {
    width: 100%;
  }
  .ConteneurParent {
    height: auto;
    min-height: 100vh;
  }
  .Welcome {
    text-align: center !important;
  }
  #ban {
    width: 100%;
    height:50vh;
  }
  #logo {
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
  #div-bouton {
    margin-bottom: 10px;
  }
  .registrer-page-login{
    font-size:15px;
    width:80%;
    margin-right:auto;
    margin-left:auto;
    text-align:center;
    margin-bottom: 20px;
  }
}
form.errors {
    :invalid {
      outline: 1px solid red;
    }
}
@media screen and (max-width: 500px) {
  #ban {
    width: 100%;
    height:30vh;
  }
}
</style>