import { Collision } from "@/physic/Collision";
import { Object3D, Vector3 } from "three";
import { BoxRotationDirection } from "../../BoxRotationDirection";
import { BoxSideRelativeToWindow } from "../../BoxSideRelativeToWindow";
import { BoxWall } from "./BoxWall";

export class BoxWallGroup extends Array<BoxWall> {
    private rotation: BoxRotationDirection;
    private emplacement: BoxSideRelativeToWindow;
    private position: Vector3;
    private collision: Collision;

    public constructor () {
        super();
        this.emplacement = BoxSideRelativeToWindow.Gauche;
        this.rotation = BoxRotationDirection.Sud;
        this.position = new Vector3();
        this.collision = new Collision();
    }
    public getCopy(): BoxWallGroup {
        const copy = new BoxWallGroup();
        this.forEach(wall => {
            copy.push(wall.getCopy());
        });
        return copy;
    }

    public getLastWall (): BoxWall {
        return this[this.length-1];
    }

    public updateHitbox () {
        this.collision.resetObjectsInHitbox();
        this.collision.addObjectsInHitbox(this.map(wall => wall.getObject()));
        this.collision.update();
    }

    public getAllObjects (): Object3D[] {
        return this.map(wall => wall.getObject());
    }

    public getAllHitboxes (): Object3D[] {
        return this.map(wall => wall.getHitbox());
    }

    public getRotation(): BoxRotationDirection {
        return this.rotation;
    }

    public setPosition (value: Vector3) {
        this.position = value;
        let xToAdd = 0;
        let zToAdd = 0;

        let totalSize = 0;
        let startOnX = false;
        let reverse = 1;
        switch (this.rotation) {
            case BoxRotationDirection.Est: {
                startOnX = true;
                xToAdd = 6;
                zToAdd = 0;
                break;
            }
            case BoxRotationDirection.Ouest:{
                startOnX = true;
                reverse = -1;
                xToAdd = -6;
                //decalegeFromSide -= 6
                zToAdd = 0;
                break;
            }
            case BoxRotationDirection.Sud:{
                xToAdd = 0;
                zToAdd = 6;
                break;
            }
            case BoxRotationDirection.Nord:{
                xToAdd = 0;
                zToAdd = -6;
                reverse = -1;
                break;
            }
        }
        const sizeEpaisseur = this[0].getSize().z;
        if(startOnX) {
            this.forEach(wall => totalSize += wall.getSize().x);
        }
        else {
            this.forEach(wall => totalSize += wall.getSize().z);
        }
        let decalegeFromSide = -totalSize/6 + totalSize/2;

        switch (this.rotation) {
            case BoxRotationDirection.Est: {
                decalegeFromSide -= 4;
                break;
            }
            case BoxRotationDirection.Ouest:{
                decalegeFromSide -= 0;//-totalSize/3 + totalSize;
                break;
            }
            case BoxRotationDirection.Sud:{
                decalegeFromSide -= 4;
                break;
            }
            case BoxRotationDirection.Nord:{
                decalegeFromSide -= 0;
                break;
            }
        }
        let decalageAvant = 0;
        switch (this.emplacement) {
            case BoxSideRelativeToWindow.Gauche: {
                decalegeFromSide += 0.5 * reverse;
                break;
            }
            case BoxSideRelativeToWindow.Derrière: {
                decalegeFromSide += 0 * reverse;
                break;
            }
            case BoxSideRelativeToWindow.Droite: {
                decalegeFromSide += -0.5 * reverse;
                break;
            }
            case BoxSideRelativeToWindow.Avant: {
                decalegeFromSide += 0 * reverse;
                if (startOnX) {
                    decalageAvant += (0.75 - sizeEpaisseur) * reverse;
                }
                else {
                    decalageAvant += 0.75 * -reverse
                }
                break;
            }
        }

        let currentSize = 0;
        for (let x = 0; x < this.length; x++){
            const proportion = currentSize/3;
            currentSize += this[x].getUnitSize();
            if(startOnX) {
                this[x].setPosition(value.clone().add(new Vector3(xToAdd*proportion + decalegeFromSide,0,zToAdd*proportion + decalageAvant)));
            }
            else {
                this[x].setPosition(value.clone().add(new Vector3(xToAdd*proportion + decalageAvant,0,zToAdd*proportion + decalegeFromSide)));
            }
        }
    }

    public setRotation (value: BoxRotationDirection) {
        this.rotation = value;
        this.forEach(wall => wall.setRotation(value));
    }
    
    public setEmplacement (value: BoxSideRelativeToWindow) {
        this.emplacement = value;
    }

    public getEmplacement (): BoxSideRelativeToWindow {
        return this.emplacement;
    }

    public deplacer(value: Vector3) {
        this.forEach(wall => wall.deplacer(value));
    }

    public getSize(): Vector3 {
        this.collision.update();
        return this.collision.getSize(); 
    }

    public refreshObject3D(objs: Object3D[]) {
        for (let x = 0; x < objs.length; x++){
            this[x].refreshObject3D(objs[x]);
        }
    }
}