import { SceneObject } from "@/scene/SceneObject/SceneObject";
import { BoxHelper, Object3D, Vector3 } from "three";

export abstract class BoxPart extends SceneObject {
    protected name;
    protected isVisible: boolean;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string){
        super(object, hitbox);
        this.name = name;
        this.isVisible = true;
    }

    public getName(): string {
        return this.name;
    }
    
    public getCopy(): SceneObject {
        return super.getCopy();
    }

    public refreshObject3D(obj: Object3D){
        obj.visible = this.getObject().visible;
        obj.scale.copy(this.getObject().scale);
        super.refreshObject3D(obj);
        this.deplacer(new Vector3(0,+this.getSize().y/2,0));
    }

    public setVisible(value: boolean) {
        this.isVisible = value;
        this.getObject().visible = value;
    }

    public getIsVisible (): boolean {
        return this.isVisible;
    }

}