<template>
  <div v-if="renderIt">
      <span class="paramIndividuel">Info à propos de la boite: </span>
  </div>
</template>

<script lang="ts">
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { SelectionManager } from '@/Managers/SelectionManager/SelectionManager';
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        selectionManager: SelectionManager
    },
    methods: {
        whenSelectedBoxChange () {
            if (this.selectionManager.isThereABoxSelected()) {
                this.renderIt = true;
            }
            else {
                this.renderIt = false;
            }
        }
    },
    mounted () {
        EventBus.on(EventType.newSelectedBox, this.whenSelectedBoxChange);
        EventBus.on(EventType.removedSelectedBox, this.whenSelectedBoxChange);
    }
})
export default class SelectionMenuStep7 extends Vue {
  renderIt = false;
}
</script>

<style scoped>
  
</style>
