
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";
import Modal from '@/components/Modal.vue';

const namespace = "user";

@Options({
  components: {
    Modal
  },
  created() {
    this.getAllUser().then(users => {
      this.users = users.data;
    });
  },
  methods: { 
    getUser(id){
      this.getUserById(id).then(user => {
        this.$refs.userModal.openModal();
        this.userSelectionne = (user.data)[0];
      });
    },
    rapports() {
     this.$router.push("/rapports");  
    }
  },
})
export default class PageAdmin extends Vue {
  @Action("getAllUser", { namespace: "user" }) getAllUser: any;
  @Action("getUserById", { namespace: "user" }) getUserById: any;

  users = {}; 
  userSelectionne = {};
  
}
