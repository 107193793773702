import { Box } from "@/Box/Box";
import { BoxStairs } from "@/Box/BoxParts/BoxStairs";
import { BoxRotationDirection } from "@/Box/BoxRotationDirection";
import { BoxWallBesideHelper } from "@/helpers/BoxWallBesideHelper";
import { SceneGrid } from "@/scene/SceneGrid";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { ActionErrorMessage } from "./ActionErrorMessage";
import { ActionRuleChecker } from "./ActionRuleChecker";

export class AddingRuleChecker extends ActionRuleChecker {

    private rulesToCheck: Function[];
    private sceneObjects: Array<Box>;
    private grid: SceneGrid;
    private lastBoxCheckedIsAllowed: [Box | undefined,boolean];
    private boxWallHelper: BoxWallBesideHelper;

    public constructor (sceneObjects: Array<Box>, grid: SceneGrid, boxWallHelper: BoxWallBesideHelper) {
        super();
        this.rulesToCheck = [];
        this.sceneObjects = sceneObjects;
        this.boxWallHelper = boxWallHelper;
        this.grid = grid;
        this.lastBoxCheckedIsAllowed = [undefined, false];
        this.addAllRulesToCheck();
    }

    protected addAllRulesToCheck () {
        this.rulesToCheck.push(this.isBoxBesideAnotherBoxOnFirstFloor.bind(this));
        this.rulesToCheck.push(this.isBoxOverAnotherBoxOnHigherFloor.bind(this));
        this.rulesToCheck.push(this.isThereStillABlocWithSouthRotation.bind(this));
        this.rulesToCheck.push(this.isBlocRotationIsNorth.bind(this));
        this.rulesToCheck.push(this.isBoxIsInAnotherOne.bind(this));
        this.rulesToCheck.push(this.isBoxBlocked.bind(this));
        this.rulesToCheck.push(this.isBoxBlockingSomething.bind(this));
        this.rulesToCheck.push(this.doesBoxFollowSidePanels.bind(this));
    }

    public getIfBoxIsLastCheckedBox (box: Box): boolean {
        return this.lastBoxCheckedIsAllowed[0] == box;
    }
    
    public getIfLastBoxWasAllowed (): boolean {
        return this.lastBoxCheckedIsAllowed[1];
    }

    public isActionPermitted (addedBox: Box, showMessage = true): boolean {
        let actionErrorMessage: ActionErrorMessage | null = null;
        let ifActionPermitted = true;
        this.rulesToCheck.forEach(rule => {
            actionErrorMessage = rule(addedBox);
            if (actionErrorMessage != null) {
                ifActionPermitted = false;
                if (showMessage) {
                    ToastNotificationsBus.emit(ToastEmitterOptionsType.error, actionErrorMessage.toToastNotificationsMessage());
                }
            }
        });
        this.lastBoxCheckedIsAllowed = [addedBox, ifActionPermitted];
        return ifActionPermitted;
    }

    private isBoxBesideAnotherBoxOnFirstFloor (addedBox: Box):  ActionErrorMessage | null {
        const actionErrorMessage = new ActionErrorMessage("N'est pas à coté d'une autre boite");
        let ifBesideABox = false;
        if (this.grid.getEtageActif() == 0) {
            let boxCheckedCount = 0;
            this.sceneObjects.forEach(box => {
                if(this.grid.getEtageActif() == this.grid.getFloorOfBox(box)) {
                    boxCheckedCount++;
                    if(box != addedBox) {
                        if(addedBox.getCollision().checkIfBesideWithOtherObject(box.getPosition(), box.getSize())){
                            ifBesideABox = true;
                        }
                    }
                }
            });
            if (ifBesideABox || boxCheckedCount == 0 || (boxCheckedCount == 1 && this.sceneObjects.includes(addedBox))) {
                return null;
            }
            else {
                return actionErrorMessage;
            }
        }
        else {
            return null;
        }
    }

    private isBoxOverAnotherBoxOnHigherFloor (addedBox: Box):  ActionErrorMessage | null  {
        const actionErrorMessage = new ActionErrorMessage("La boite n'est pas allignée sur une autre boite");
        let ifOverAnotherBox = false;
        let addedBoxFloor = this.grid.getFloorOfBox(addedBox);
        if (addedBoxFloor == null) {
            addedBoxFloor = this.grid.getEtageActif();
        }
        if (addedBoxFloor > 0) {
            this.sceneObjects.forEach(box => {
                if(box != addedBox) {
                    if(addedBoxFloor == (this.grid.getFloorOfBox(box) as number) + 1) {
                        const inX = Math.abs(addedBox.getPosition().x - box.getPosition().x) <= 1; 
                        const inZ = Math.abs(addedBox.getPosition().z - box.getPosition().z) <= 1; 
                        if (inX && inZ){
                            ifOverAnotherBox = true;
                        }
                    }
                }
            });
            if (ifOverAnotherBox) {
                return null;
            }
            else {
                return actionErrorMessage;
            }
        }
        else {
            return null;
        }
    }

    private isBoxIsInAnotherOne (addedBox: Box):  ActionErrorMessage | null  {
        const actionErrorMessage = new ActionErrorMessage("La boite est dans une autre boite");
        let ifBoxInAnotherBox = false;
        let addedBoxFloor = this.grid.getFloorOfBox(addedBox);
        if (addedBoxFloor == null) {
            addedBoxFloor = this.grid.getEtageActif();
        }
        this.sceneObjects.forEach(box => {
            if(box != addedBox) {
                if(addedBoxFloor == this.grid.getFloorOfBox(box)) {
                    if(addedBox.getCollision().checkIFCollideWithOtherSceneObjectNotIncludingBorder(box.getPosition(), box.getSize(), { x: true, y: false, z: true })){
                        ifBoxInAnotherBox = true;
                    }
                }
            }
        });
        if (ifBoxInAnotherBox) {
            return actionErrorMessage;
        }
        else {
            return null;
        }
    }

    private isBoxBlockingSomething (addedBox: Box):  ActionErrorMessage | null {
        let block = false;
        let addedBoxFloor = this.grid.getFloorOfBox(addedBox);
        if (addedBoxFloor == null) {
            addedBoxFloor = this.grid.getEtageActif();
        }
        this.sceneObjects.forEach(box => {
            if(box != addedBox) {
                if(addedBoxFloor == this.grid.getFloorOfBox(box)) {
                    const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(addedBox, box);
                    let blockCondition = false;
                    switch (+(box as Box).getRotation()) {
                        case BoxRotationDirection.Est: {
                            blockCondition = dist.x == -14 && Math.abs(dist.z) < 14 && Math.abs(dist.z) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Ouest:{
                            blockCondition = dist.x == 14 && Math.abs(dist.z) < 14 && Math.abs(dist.z) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Sud:{
                            blockCondition = dist.z == -14 && Math.abs(dist.x) < 14 && Math.abs(dist.x) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Nord:{
                            blockCondition = dist.z == 14 && Math.abs(dist.x) < 14 && Math.abs(dist.x) >= 0;
                            break;
                        }
                    }
                    if (blockCondition) {
                        block = true;
                    }
                }
            }
        });
        if (block) {
            return new ActionErrorMessage("La boite bloque une autre boite");
        }
        else {
            return null;
        }
    }
    
    private isBoxBlocked (addedBox: Box):  ActionErrorMessage | null {
        let block = false;
        let addedBoxFloor = this.grid.getFloorOfBox(addedBox);
        if (addedBoxFloor == null) {
            addedBoxFloor = this.grid.getEtageActif();
        }
        this.sceneObjects.forEach(box => {
            if(box != addedBox) {
                if(addedBoxFloor == this.grid.getFloorOfBox(box)) {
                    const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(addedBox, box);
                    let blockCondition = false;
                    switch (addedBox.getRotation()) {
                        case BoxRotationDirection.Est: {
                            blockCondition = dist.x == 14 && Math.abs(dist.z) < 14 && Math.abs(dist.z) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Ouest:{
                            blockCondition = dist.x == -14 && Math.abs(dist.z) < 14 && Math.abs(dist.z) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Sud:{
                            blockCondition = dist.z == 14 && Math.abs(dist.x) < 14 && Math.abs(dist.x) >= 0;
                            break;
                        }
                        case BoxRotationDirection.Nord:{
                            blockCondition = dist.z == -14 && Math.abs(dist.x) < 14 && Math.abs(dist.x) >= 0;
                            break;
                        }
                    }
                    if (blockCondition) {
                        block = true;
                    }
                }
            }
        });
        if (block) {
            return new ActionErrorMessage("La boite est bloqué par une autre boite");
        }
        else {
            return null;
        }
    }

    private isBlocRotationIsNorth (addedBox: Box) {
        if (addedBox.getRotation() == BoxRotationDirection.Nord || addedBox.getWindows().some(window => window.getRotation() === BoxRotationDirection.Est)) {
            return new ActionErrorMessage("La fenêtre ne peut pas être vers le nord");
        }
        else {
            return null;
        }
    }

    private isThereStillABlocWithSouthRotation (addedBox: Box) {
        if (addedBox.getRotation() == BoxRotationDirection.Sud) {
            return null;
        }
        else if (this.sceneObjects.some(box => box.getRotation() == BoxRotationDirection.Sud)) {
            return null;
        }
        else {
            return new ActionErrorMessage("Il n'y a aucune fenêtre qui est vers le sud");
        }
    }

    private doesBoxFollowSidePanels (addedBox: Box):  ActionErrorMessage | null {
        const actionErrorMessage = new ActionErrorMessage("La boite n'est pas alligné avec les autres boites");
        let estPasAlligne = false;
        let addedBoxFloor = this.grid.getFloorOfBox(addedBox);
        if (addedBoxFloor == null) {
            addedBoxFloor = this.grid.getEtageActif();
        }
        this.sceneObjects.forEach(box => {
            if(box != addedBox) {
                if(addedBoxFloor == this.grid.getFloorOfBox(box)) {
                    const dist = this.grid.getGridPositionDiferenceBetweenTwoObjects(addedBox, box);
                    if(Math.abs(dist.z) <= 14 && Math.abs(dist.x) <= 14){
                        if(Math.abs(dist.z) != Math.abs(dist.x)) {
                            const ifAlign = addedBox.getCollision().getIfExactlyAlignWithOtherObject(box.getPosition(), box.getSize());
                            if (!ifAlign) {
                                const ifBeside = !addedBox.getCollision().checkIFCollideWithOtherSceneObjectNotIncludingBorder(box.getPosition(), box.getSize(), { x: true, y: false, z: true });
                                if (!ifBeside || !this.boxWallHelper.getIfBoxWallsAreAlignToAnotherBox(addedBox, box)) {
                                    estPasAlligne = true;
                                }
                            }
                            if (box.getStairs() != undefined && !estPasAlligne) {   
                                const stairs = box.getStairs() as BoxStairs;
                                if(addedBox.getCollision().checkIFCollideWithOtherSceneObjectNotIncludingBorder(stairs.getTruePosition(), stairs.getSize(), { x: true, y: false, z: true })){
                                    estPasAlligne = true;
                                }
                            }

                            // let evaluatedAxys = dist.z;
                            // if (Math.abs(dist.z) == 14) {
                            //     evaluatedAxys = dist.x;
                            // }
                            // const evaluatedNumber = 4;
                            // let distanceVoulue = 0;
                            // const ifNeedZero = false;

                            // if(addedBox.getRotation() == BoxRotationDirection.Est || addedBox.getRotation() == BoxRotationDirection.Ouest) {
                            //     if((box as Box).getRotation() == BoxRotationDirection.Est || (box as Box).getRotation() == BoxRotationDirection.Ouest) {
                            //         if (evaluatedAxys == dist.x && (box as Box).getRotation() != addedBox.getRotation()) {
                            //             distanceVoulue = 2;
                            //         }
                            //         else {
                            //             distanceVoulue = 0;
                            //         }
                            //     }
                            //     else {
                            //         if (addedBox.getRotation() == BoxRotationDirection.Ouest) {
                            //             if (evaluatedAxys > 0) {
                            //                 distanceVoulue = 1; 
                            //             }
                            //             else {
                            //                 distanceVoulue = 3; 
                            //             }
                            //         }
                            //         else {
                            //             if (evaluatedAxys > 0) {
                            //                 distanceVoulue = -1; 
                            //             }
                            //             else {
                            //                 distanceVoulue = 1; 
                            //             }
                            //             if(dist.z == evaluatedAxys) {
                            //                 distanceVoulue = -distanceVoulue;
                            //             }
                            //         }
                            //     }
                            // }
                            // else {
                            //     if((box as Box).getRotation() == BoxRotationDirection.Est || (box as Box).getRotation() == BoxRotationDirection.Ouest) {
                            //         if (box.getRotation() == BoxRotationDirection.Ouest) {
                            //             if (evaluatedAxys > 0) {
                            //                 distanceVoulue = 1; 
                            //             }
                            //             else {
                            //                 distanceVoulue = -1; 
                            //             }
                            //         }
                            //         else {
                            //             if (evaluatedAxys > 0) {
                            //                 distanceVoulue = 3; 
                            //             }
                            //             else {
                            //                 distanceVoulue = 1; 
                            //             }
                            //         }
                            //     }
                            //     else {
                            //         if (evaluatedAxys == dist.x || (box as Box).getRotation() == addedBox.getRotation()) {
                            //             distanceVoulue = 0;
                            //         }
                            //         else {
                            //             distanceVoulue = 2;
                            //         }
                            //     }
                            // }
                            // if (evaluatedAxys < 0) {
                            //     evaluatedAxys = -evaluatedAxys;
                            // }
                            // const buffer = 4 - distanceVoulue;
                            // if (((evaluatedAxys + Math.abs(buffer)) % evaluatedNumber != 0 && !ifNeedZero) || ((evaluatedAxys + Math.abs(buffer)) % evaluatedNumber == 0 && ifNeedZero)) {
                            //     if (!addedBox.getCollision().getIfExactlyAlignWithOtherObject(box.getPosition(), box.getSize())) {
                            //         estPasAlligne = true;
                            //     }
                            // }
                        }
                    }
                }
            }
        });
        if (estPasAlligne) {
            return actionErrorMessage;
        }
        else {
            return null;
        }
    }
}