import { BoxRotationDirection } from "@/Box/BoxRotationDirection";
import { WallType } from "@/CustomEnums/WallType";
import { BoxHelper, Object3D } from "three";
import { BoxPart } from "../BoxPart";
import { BoxWallState } from "./BoxWallState";

export class BoxWall extends BoxPart {

    private unitSize: number;
    private forcedState?: BoxWallState;
    protected defaultState: BoxWallState;
    private rotation: BoxRotationDirection;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string, unitSize: number) {
        super(object, hitbox, name)
        this.defaultState = BoxWallState.wall;
        this.unitSize = unitSize;
        this.rotation = BoxRotationDirection.Sud;
    }

    public setForcedState (value: BoxWallState | undefined) {
        this.forcedState = value;
    }

    public setRotation(value: BoxRotationDirection) {
        super.setRotation(value);
        this.rotation = value;
    }

    public getRotation(): BoxRotationDirection {
        return this.rotation;
    }

    public getForcedState (): BoxWallState | undefined {
        return this.forcedState;
    }

    public hasForcedState (): boolean {
        return this.forcedState !== undefined;
    }

    public getCopy(): BoxWall {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxWall(parentCopy.getObject(),parentCopy.getHitbox(), this.name, this.unitSize);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }

    public getUnitSize (): number {
        return this.unitSize;
    }

    public setWallType (type: WallType) {
        this.name = type.key;
    }

    public getWallType (): WallType {
        return WallType.getEntryOf(this.name) as WallType;
    }

    public getDefaultState(): BoxWallState{
        return this.defaultState;
    }

    public setDefaultState(defaultState: BoxWallState){
        this.defaultState = defaultState;
    }
}