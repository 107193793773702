import { BoxSideRelativeToWindow } from "@/Box/BoxSideRelativeToWindow";
import { BoxHelper, Object3D, Vector3 } from "three";
import { Box } from "../Box";
import { BoxRotationDirection, getNextCounterClockwiseDirection } from "../BoxRotationDirection";
import { BoxPart } from "./BoxPart";
import { BoxWallBit } from "./BoxWall/BoxWallBit";

export class BoxStairs extends BoxPart {

    private boxOnTop: Box | undefined;
    private boxOnBottom: Box | undefined;
    private sideRelativeToBottomBox: BoxSideRelativeToWindow;
    private wallBits: BoxWallBit[];
    private decalageFromBox?: Decalage;

    public constructor (object: Object3D, hitbox: BoxHelper, name: string) {
        super (object, hitbox, name);
        this.sideRelativeToBottomBox = BoxSideRelativeToWindow.Droite;
        this.wallBits = new Array<BoxWallBit>();
    }

    public getCopy(): BoxStairs {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxStairs(parentCopy.getObject(),parentCopy.getHitbox(), this.name);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }

    public setSideRelativeToBottomBox (value: BoxSideRelativeToWindow) {
        this.sideRelativeToBottomBox = value;
    } 

    public isBoxTheBottomBox (box: Box) {
        return this.boxOnBottom == box;
    } 

    public setBoxes(boxOnTop: Box, boxOnBottom: Box) {
        this.boxOnBottom = boxOnBottom;
        this.boxOnTop = boxOnTop;
    }

    public getWallBits (): BoxWallBit [] {
        return this.wallBits;
    }

    public setWallBits (wallbits: BoxWallBit[]) {
        this.wallBits = wallbits;
    }

    public setDecalage (decalageFromBox: Decalage) {
        this.decalageFromBox = decalageFromBox;
    }

    public getStairsRotationRelativeToBox (): BoxRotationDirection {
        let rotationIncrementation = 0
        switch (this.sideRelativeToBottomBox) {
            case BoxSideRelativeToWindow.Droite : {
                rotationIncrementation = 3;
                break;
            }
            case BoxSideRelativeToWindow.Avant : {
                rotationIncrementation = 0;
                break;
            }
            case BoxSideRelativeToWindow.Gauche : {
                rotationIncrementation = 1;
                break;
            }
            case BoxSideRelativeToWindow.Derrière : {
                rotationIncrementation = 2;
                break;
            }
        }
        return getNextCounterClockwiseDirection((this.boxOnBottom as Box).getRotation(), rotationIncrementation) as BoxRotationDirection
    }

    private getRotationConsideringStairsSide (oldRotation: BoxRotationDirection) {
        let rotationIncrementation = 0
        switch (this.sideRelativeToBottomBox) {
            case BoxSideRelativeToWindow.Droite : {
                rotationIncrementation = 0;
                break;
            }
            case BoxSideRelativeToWindow.Avant : {
                rotationIncrementation = 1;
                break;
            }
            case BoxSideRelativeToWindow.Gauche : {
                rotationIncrementation = 2;
                break;
            }
            case BoxSideRelativeToWindow.Derrière : {
                rotationIncrementation = 3;
                break;
            }
        }
        return getNextCounterClockwiseDirection(oldRotation, rotationIncrementation) as BoxRotationDirection
    }

    public setScaleFromBox (scale: number) {
        this.getObject().scale.setScalar(scale);
        this.getHitbox().setFromObject(this.getObject());
    }

    public setPositionAndRotationFromBox(box: Box) {
        const newRotationDirection = this.getRotationConsideringStairsSide(box.getRotation());
        this.setRotation(newRotationDirection);
        const newPosition = box.getPosition().clone();
        const otherBoxPosition = (this.getBoxOnTop() as Box).getPosition();
        const decalage = new Vector3();
        let isDecalageFromBottomBox = false;
        switch (newRotationDirection) {
            case BoxRotationDirection.Est: {
                if (newPosition.z > otherBoxPosition.z) {
                    decalage.z -= newPosition.z - otherBoxPosition.z;
                    isDecalageFromBottomBox = true;
                }
                newPosition.z -= 0.25 + this.getSize().x/2;
                break;
            }
            case BoxRotationDirection.Ouest:{
                if (newPosition.z < otherBoxPosition.z) {
                    decalage.z += otherBoxPosition.z - newPosition.z;
                    isDecalageFromBottomBox = true;
                }
                newPosition.z += 6 - this.getSize().x/2;
                break;
            }
            case BoxRotationDirection.Sud:{
                if (newPosition.x < otherBoxPosition.x) {
                    decalage.x += otherBoxPosition.x - newPosition.x;
                    isDecalageFromBottomBox = true;
                }
                newPosition.x += 6 - this.getSize().x/2;
                break;
            }
            case BoxRotationDirection.Nord:{
                if (newPosition.x > otherBoxPosition.x) {
                    decalage.x -= newPosition.x - otherBoxPosition.x;
                    isDecalageFromBottomBox = true;
                }
                newPosition.x -= 0.5 + this.getSize().x/2;
                break;
            }
        }
        this.setDecalage({ decalageValue: decalage, isSpaceFromBottomBox: isDecalageFromBottomBox });
        newPosition.add(decalage);
        newPosition.y += this.getSize().y/2;
        this.setPosition(newPosition);
    }

    public setPosition (value: Vector3) {
        super.setPosition(value);
    }

    public getBoxOnTop(): Box | undefined {
        return this.boxOnTop;
    }

    public getBoxOnBottom(): Box | undefined {
        return this.boxOnBottom;
    }

    public getSideNumberRelativeToTopBox (): BoxSideRelativeToWindow {
        const rotationBoxBottom = (this.boxOnBottom as Box).getRotation();
        const rotationBoxTop = (this.boxOnTop as Box).getRotation();
        let sideNumber = this.sideRelativeToBottomBox;
        if(rotationBoxBottom > rotationBoxTop) {
            sideNumber -= (rotationBoxBottom - rotationBoxTop) / (Math.PI/2);
        }
        else {
            sideNumber += (rotationBoxTop - rotationBoxBottom) / (Math.PI/2);
        }
        if (sideNumber < 0) {
            sideNumber = 4 + sideNumber;
        }
        sideNumber = sideNumber % 4;
        return sideNumber as BoxSideRelativeToWindow;
    }

    public getSideNumberRelativeToBottomBox (): BoxSideRelativeToWindow {
        return this.sideRelativeToBottomBox;
    }
}

interface Decalage {
    decalageValue: Vector3;
    isSpaceFromBottomBox: boolean;
}