import walls from './../../configs/walls.json';
import { CustomEnum } from './CustomEnum';
import { CustomEnumLibrairy } from './CustomEnumLibrairy';

export class WallType extends CustomEnum {
    public static _initialize() {
        CustomEnumLibrairy.registerNewEnum(WallType, WallType.getMaterialsPossibleEntrys());
    }

    private static getMaterialsPossibleEntrys (): WallType[] {
        const newList = new Array<WallType>();
        walls.forEach((wall) => {newList.push(new WallType(wall.name, wall.value, wall.previewLocation));});
        return newList;
    }
}
WallType._initialize();