import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";

export abstract class Repository<T> {

    protected objectMap: Map<string,T>;
    protected nbObjectTotal!: number;

    public constructor () {
        this.objectMap = new Map<string,T>();
    }

    public async initialise() {
        EventBus.emit(EventType.beginLoading);
        this.loadObjects();
    }

    protected abstract loadObjects();

    public abstract getCopyByName(name: string): T | undefined;

    public getLoadingPercentage(): number{
        return this.objectMap.size / this.nbObjectTotal * 100;
    }

    protected verifyIfEndLoading () {
        if (this.objectMap.size == this.nbObjectTotal) {
            EventBus.emit(EventType.endLoading);
        }
    }
}

