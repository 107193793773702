import { BoxMiddleFloor } from "@/Box/BoxParts/BoxMiddleFloor";
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { BoxHelper } from "three";
import middleFloors from '../../configs/middleFloor.json';
import { Repository } from "./Repository";

export class MiddleFloorRepository extends Repository<BoxMiddleFloor> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (middleFloors as any[]).length;
        middleFloors.forEach(middleFloor => {
            loader.loadObj('models/'+middleFloor.location).then(wallInstance => {
                wallInstance.position.copy(this.object3DTruePositionCalculator.getTruePosition(wallInstance));
                const hitbox = new BoxHelper(wallInstance, 0xff0000);
                hitbox.update();
                this.objectMap.set(middleFloor.name, new BoxMiddleFloor(wallInstance, hitbox, middleFloor.name));
                this.verifyIfEndLoading();
            })
        })
    }

    public getCopyByName(name: string): BoxMiddleFloor | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}