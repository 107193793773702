import { Box } from "@/Box/Box";
import { BoxRotationDirection } from "@/Box/BoxRotationDirection";
import { SceneGrid } from "@/scene/SceneGrid";
import { ToastEmitterOptionsType } from "@/ToastNotifications/ToastEmitter/ToastEmitterOptionsType";
import { ToastNotificationsBus } from "@/ToastNotifications/ToastNotificationsBus";
import { StairsValidationObject } from "@/validationObjects/StairsValidationObject";
import { ActionErrorMessage } from "./ActionErrorMessage";
import { ActionRuleChecker } from "./ActionRuleChecker";

export class AddingStairsRuleChecker extends ActionRuleChecker {

    private rulesToCheck: Function[];
    private sceneObjects: Array<Box>;
    private grid: SceneGrid;

    public constructor (sceneObjects: Array<Box>, grid: SceneGrid) {
        super();
        this.rulesToCheck = [];
        this.sceneObjects = sceneObjects;
        this.grid = grid;
        this.addAllRulesToCheck();
    }

    protected addAllRulesToCheck () {
        this.rulesToCheck.push(this.ifStairsHasTopBox.bind(this));
        this.rulesToCheck.push(this.ifStairsHasBottomBox.bind(this));
        this.rulesToCheck.push(this.ifBoxesAlreadyHaveStairs.bind(this));
        this.rulesToCheck.push(this.isStairsObjectIntoBox.bind(this));
        this.rulesToCheck.push(this.ifStairsAreSouth.bind(this));
    }

    public isActionPermitted (addedStairs: StairsValidationObject, showMessage = true): boolean {
        let actionErrorMessage: ActionErrorMessage | null = null;
        let ifActionPermitted = true;
        this.rulesToCheck.forEach(rule => {
            actionErrorMessage = rule(addedStairs);
            if (actionErrorMessage != null) {
                ifActionPermitted = false;
                if (showMessage) {
                    ToastNotificationsBus.emit(ToastEmitterOptionsType.error, actionErrorMessage.toToastNotificationsMessage());
                }
            }
        });
        return ifActionPermitted;
    }

    private ifStairsHasTopBox (addedStairs: StairsValidationObject): ActionErrorMessage | null {
        if (addedStairs.boxOnTop == undefined) {
            return new ActionErrorMessage("La pièce n'est pas en dessous d'une autre pièce");
        }
        else {
            return null;
        }
    }

    private ifStairsHasBottomBox (addedStairs: StairsValidationObject): ActionErrorMessage | null {
        if (addedStairs.boxOnBottom == undefined) {
            return new ActionErrorMessage("La pièce n'est pas par dessus d'une autre pièce");
        }
        else {
            return null;
        }
    }

    private ifBoxesAlreadyHaveStairs (addedStairs: StairsValidationObject): ActionErrorMessage | null {
        if (addedStairs.boxOnBottom != undefined && (addedStairs.boxOnBottom as Box).getStairs() != null) {
            return new ActionErrorMessage("La pièce à déja un escalier");
        }
        else if (addedStairs.boxOnTop != undefined && (addedStairs.boxOnTop as Box).getStairs() != null) {
            return new ActionErrorMessage("La pièce à déja un escalier");
        }
        else {
            return null;
        }
    }
    
    private ifStairsAreSouth (addedStairs: StairsValidationObject): ActionErrorMessage | null {
        if (addedStairs.boxOnBottom != undefined && addedStairs.getStairsRotationRelativeToBox() === BoxRotationDirection.Sud) {
            return new ActionErrorMessage("L'escalier ne peut pas être au sud");
        }
        else {
            return null;
        }
    }

    private isStairsObjectIntoBox (addedStairs: StairsValidationObject): ActionErrorMessage | null {
        const bottomBox = addedStairs.boxOnBottom
        const topBox = addedStairs.boxOnTop;
        let stairsInABox = false;
        if (bottomBox != null && topBox != null) {
            this.sceneObjects.forEach(box => {
                if(box != bottomBox && box != topBox){
                    box.updateCollisionPosition();
                    if(box.getCollision().checkIFCollideWithOtherSceneObjectNotIncludingBorder(addedStairs.position, addedStairs.position, { x: true, y: false, z: true })){
                        stairsInABox = true;
                    }
                }
            })
        }
        if(stairsInABox) {
            return new ActionErrorMessage("L'escalier va être dans une boite. Veuillez déplacer la boite avant de placer l'escalier");
        }
        else {
            return null;
        }
    }
}