import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";

export class SceneOptions {
    public isOtherFloorVisible: boolean;
    public boxRefreshFunction: Function;
    public rendererRefreshFunction: Function;
    public debugMode: boolean;
    public antialiasing: boolean;


    public constructor (boxRefreshFunction: Function, rendererRefreshFunction: Function) {
        this.isOtherFloorVisible = true;
        this.debugMode = false;
        this.boxRefreshFunction = boxRefreshFunction;
        this.antialiasing = true;
        this.rendererRefreshFunction = rendererRefreshFunction;
    }

    public setOption (optionName: string, value: any, needBoxesRefreshed = false, needRendererRefreshed = false) {
        this[optionName] = value;
        if (needBoxesRefreshed) {
            this.boxRefreshFunction();
        }
        if (needRendererRefreshed) {
            this.rendererRefreshFunction();
        }
        EventBus.emit(EventType.optionChanged);
    }
}