
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { CustomScene } from '@/scene/CustomScene';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';
import { SceneOptions } from '@/scene/SceneOptions';
import { Action } from "vuex-class";
import { Options, Vue } from 'vue-class-component';
import { ProjectManager } from '@/Managers/ProjectManager';
import MenuGroup from './MenuGroup.vue';
import ToggleSwitch from './ToggleSwitch.vue';


@Options({
    components: {
        MenuGroup,
        ToggleSwitch
    },
    methods: {
        setupSceneWhenReady (scene: CustomScene) {
            this.scene = scene;
            this.options = this.scene.getOptions();
            this.isReady = true;
        },
        setOption (event, varName: string, needBoxesRefreshed: boolean, needRendererRefreshed: boolean) {
            if (event.target.value != undefined && typeof this.options[varName] === typeof event.target.value) {
                this.options.setOption(varName, event.target.value, needBoxesRefreshed, needRendererRefreshed);
            }
            else if (event.target.checked != undefined && typeof this.options[varName] === typeof event.target.checked) {
                this.options.setOption(varName, event.target.checked, needBoxesRefreshed, needRendererRefreshed);
            }
        }
    },
    created () {
        SceneCommunicationBus.whenInstanceIsReady(CustomScene, this.setupSceneWhenReady)
    }
})
export default class MenuOptionsProjet extends Vue {
    scene!: CustomScene;
    options!: SceneOptions;
    isReady = false;
}
