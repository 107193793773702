<template>
    <div id="corpsPage">
        <div id="conteneurMillieu">
            <StepBanner />
            <div class="content" v-if="canRender">
                <h2>Résumé</h2>
                <div class="contentItem"><span>Prix total:</span> <span>{{ projet.blocks.length * 30}} $</span></div>
                <div class="contentItem"><span>Performance carbone:</span> <span>{{ projet.blocks.length * 40}}</span></div>
                <div class="contentItem"><span>Nombre de pièces:</span><span>{{ projet.blocks.length}}</span></div><br>
            <button class="paimentBtn">Continuer vers le paiment</button>
            </div>
        </div>
  </div>
</template>

<script lang="ts">
import { EventBus } from "@/events/EventBus";
import { EventType } from "@/events/EventType";
import { ProjectManager } from "@/Managers/ProjectManager";
import { Project } from "@/project/Project";
import { Options, Vue } from "vue-class-component";
import StepBanner from "../components/StepBanner.vue";

@Options({
    components: {
        StepBanner
    },
    methods: {
        updateProject () {
            if(ProjectManager.getProject().id !== null) {
                console.log()
                this.projet = ProjectManager.getProject();
                this.canRender = true;
            }
        }
    },
    created () {
        if(ProjectManager.getCurrentStep() === -1) {
            ProjectManager.setCurrentStep(7);
        }
        else {
            EventBus.on(EventType.avalableStepsUpdated, this.updateProject);
        }
    }
})

export default class Synthese extends Vue {
    projet!: Project;
    canRender = false;
}
</script>

<style scoped>
.contentItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 20vw;
    margin: 0.5vh;
}
.content {
    background-color: white;
    display: flex;
    margin: auto;
    width: 30vw;
    height: 30vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.paimentBtn {
    width: 16vh;
    height: 7vh;
    font-size: 1.9vh;
    font-weight: bolder;
    background-color: #A8CE6C;
    border-color: transparent;
    color: white;
    border-radius: 5vh;
}
#corpsPage
{
  display:flex;
  flex-direction:column;
  mix-blend-mode: luminosity;
  justify-content: center;
  align-items: center;
  height:95vh;
}
#conteneurMillieu
{
  background-color:white;
  background-image: url("/wood-grispale.jpg");
  width:100%;
  display:flex;
  flex-direction:column;
  margin-right:auto;
  margin-left:auto;
  height:100%;
}
</style>