import { Vector3 } from "three";

export class UIDebugParams {
    private lastFrameTimestamp = 0;
    public frameRate = 0;
    public cameraAngle = new Vector3();

    public setLastFrameTimestamp (lastFrameTimestamp: number) {
        this.setFrameRateFromNewTimestamp(lastFrameTimestamp);
        this.lastFrameTimestamp = lastFrameTimestamp;
    }

    private setFrameRateFromNewTimestamp (newFrameTimestamp: number) {
        const difference = newFrameTimestamp - this.lastFrameTimestamp;
        this.frameRate = Math.round(1/difference*1000);
    }
}