import { Box } from "@/Box/Box";
import boxParams from '../../configs/boxParams.json'
import { BoxDefaultValues } from "@/Box/BoxDefaultValues";
import { BoxFactory } from "@/factorys/BoxFactory";
import { Repository } from "./Repository";


export class BoxRepository extends Repository<Box>{

    private boxFactory: BoxFactory;
    private currentID = 0;

    public constructor (boxFactory: BoxFactory) {
        super();
        this.boxFactory = boxFactory;
    }

    public setCurrentID (value: number) {
        this.currentID = value;
    }

    protected loadObjects() {
        const jsonAny = boxParams as any[];
        const jsonBoxParam = jsonAny as BoxDefaultValues[];
        this.nbObjectTotal = jsonAny.length;
        let defaultValue: BoxDefaultValues | undefined;
        jsonBoxParam.forEach(param => {
            defaultValue = new BoxDefaultValues();
            Object.assign(defaultValue,param);
            this.boxFactory.create(defaultValue).then((obj) => {
                this.objectMap.set(obj.getName(), obj);
                this.verifyIfEndLoading();
            });
        });
    }

    public getCopyByName(name: string, assignNewID: number | boolean = false): Box | undefined {
        let id = -1;
        if(assignNewID === true) {
            id = this.currentID++;
        }
        else if(assignNewID !== false){
            id = assignNewID;
        }
        return this.objectMap.get(name)?.getCopy(id);
    }
}