import { Vector3 } from "three";
import { ProjectBoxWallGroup } from "./ProjectBoxWallGroup";

export class ProjectBox {
    public floorName: string | null = null;
    public frontFacingName: string | null = null;
    public wallName: string | null = null;
    public windowName: string | null = null;
    public position: Vector3 = new Vector3();
    public positionX: number | null = null;
    public positionY: number | null = null;
    public positionZ: number | null = null;
    public rotation: number | null = null;
    public internalId: number | null = null;
    public boxName: string | null = null;
    public roomType: string | null = null;
    public wall_group: Array<ProjectBoxWallGroup> | null = null;
}