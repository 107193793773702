import { BoxPart } from "./BoxPart";

export class BoxMiddleFloor extends BoxPart {
    
    public getCopy(): BoxMiddleFloor {
        const parentCopy = super.getCopy();
        const trueCopy = new BoxMiddleFloor(parentCopy.getObject(),parentCopy.getHitbox(), this.name);
        trueCopy.setVisible(this.getObject().visible);
        return trueCopy;
    }
}