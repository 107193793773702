import { BoxRoom } from "@/Box/BoxParts/BoxRoom";
import { Object3DTruePositionCalculator } from "@/calculators/Object3DTruePositionCalculator";
import { CustomColladaLoader } from "@/loaders/CustomColladaLoader";
import { BoxHelper } from "three";
import roomType from '../../configs/roomType.json';
import { Repository } from "./Repository";

export class RoomRepository extends Repository<BoxRoom> {

    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    public constructor () {
        super();
        this.object3DTruePositionCalculator = new Object3DTruePositionCalculator();
    }

    protected loadObjects() {
        const loader = new CustomColladaLoader();
        this.nbObjectTotal = (roomType as any[]).length;
        roomType.forEach(item => {
            loader.loadObj('models/'+item.location).then(instance => {
                instance.position.copy(this.object3DTruePositionCalculator.getTruePosition(instance));
                const hitbox = new BoxHelper(instance, 0xff0000);
                hitbox.update();
                this.objectMap.set(item.name, new BoxRoom(instance, hitbox, item.name, item.prix));
                this.verifyIfEndLoading();
            })
        })
    }

    public getCopyByName(name: string): BoxRoom | undefined{
        if (this.objectMap.has(name)) {
            return this.objectMap.get(name)?.getCopy();
        }
        return undefined;
    }
}