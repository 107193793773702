import { Project } from "@/project/Project";
import { ProjectBox } from "@/project/ProjectBox";
import { Desereliazer } from "./Desereliazer";
import { ProjectBoxDeseresiazer } from "./ProjectBoxDeseresiazer";

export class ProjectDesereliazer extends Desereliazer{
    protected static keyComportement<Project>(key: string, obj: Project, json: any) {
        if(key === "blocks") {
            const blocksArray = new Array<ProjectBox>();
            json[key].forEach(element => {
                blocksArray.push(ProjectBoxDeseresiazer.toInstance(new ProjectBox(), element));
            });
            obj[key] = blocksArray;
        }
        else {
            obj[key] = json[key];
        }
    }
}
