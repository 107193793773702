
import { ProjectDesereliazer } from '@/Desereasizer/ProjectDesereliazer';
import { EventBus } from '@/events/EventBus';
import { EventType } from '@/events/EventType';
import { ProjectManager } from '@/Managers/ProjectManager';
import { Project } from '@/project/Project';
import { Action } from 'vuex-class';
import { Options, Vue } from 'vue-class-component';
import { LoadSaveHelper } from '@/helpers/LoadSaveHelper';
import { SceneCommunicationBus } from '@/scene/SceneCommunicationBus';

@Options({
    computed: {
        maxSteps () {
            return ProjectManager.getMaximumStep();
        }
    },
    methods: {
        onChangedStep (infoChangeStep) {
            ProjectManager.calculateAvalablesSteps(infoChangeStep.oldStep === -1);
            ProjectManager.changePageDependingCurrentStep(this.$router);
            this.updateStepsShowed();
        },
        incrementSteps () {
            ProjectManager.incrementSteps();
        },
        decrementSteps () {
            ProjectManager.decrementSteps();
        },
        changeStep (newStep: number) {
            ProjectManager.setCurrentStep(newStep);
        },
        saveProject () {
            this.loadSaveHelper.saveProject(this.save, true).then(() => {
                ProjectManager.calculateAvalablesSteps(true);
            });
        },
        updateStepsShowed () {
            this.currentStep = ProjectManager.getCurrentStep();
            this.showNextStepButtom = !ProjectManager.isCurrentStepAtMaximumStep() && ProjectManager.getIfStepAvalable(this.currentStep+1) && !ProjectManager.getIfIsChanging();
            this.showLastStepButtom = !ProjectManager.isCurrentStepAtMinimumStep() && !ProjectManager.getIfIsChanging();
            this.bannerText = ProjectManager.getCurrentStepBannerText();
            this.stepsNumbers = ProjectManager.getAllStepsNumbersIfCompleted();
        }
    },
    created () {
        EventBus.on(EventType.stepChanged, this.onChangedStep);
        EventBus.on(EventType.avalableStepsUpdated, this.updateStepsShowed);
        SceneCommunicationBus.whenInstanceIsReady(LoadSaveHelper, (loadSaveHelper) => { this.loadSaveHelper = loadSaveHelper; });
        if(ProjectManager.getProject().id === null && this.$route.params.projectId !== "") {
            this.getSave(Number.parseInt(this.$route.params.projectId)).then(response => {
                ProjectManager.setProject(ProjectDesereliazer.toInstance(new Project(), response));
                ProjectManager.getProject().isSet = true;
                ProjectManager.calculateAvalablesSteps(true);
            });
        }
    },
    mounted () {
        if(ProjectManager.getProject().id !== null) {
            ProjectManager.calculateAvalablesSteps(true);
        }
    }
})
export default class StepBanner extends Vue {
  currentStep: number = 0;
  showNextStepButtom = true;
  showLastStepButtom = true;
  bannerText = "";
  stepsNumbers: [number, boolean] [] = ProjectManager.getAllStepsNumbersIfCompleted();
  @Action("save/getSave") getSave!: any;
  @Action("save/saveProject") save: any;
  loadSaveHelper!: LoadSaveHelper;
}
