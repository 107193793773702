import { CustomEnumLibrairy } from "./CustomEnumLibrairy";

export class CustomEnum {
    public key: any;
    public value: any;
    public preview?: string;

    protected constructor (key: any, value: any, preview?: string) {
        this.key = key;
        this.value = value;
        this.preview = preview
    }

    public static getPossibleEntrys(): CustomEnum[] | undefined {
        return CustomEnumLibrairy.getEnumPossiblesEntry(this);
    }

    public static getEntryOf(key: any): CustomEnum | undefined {
        return CustomEnumLibrairy.findEnumByKey(this, key);
    }
}