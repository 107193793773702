import { Material } from '@/CustomEnums/Material';
import { WallType } from '@/CustomEnums/WallType';
import { EditableParameter } from '@/decorators/EditableParameter/EditableParameter';
import { EditableParameterType } from '@/decorators/EditableParameter/EditableParameterType';
import { ObjectSearchIgnore } from '@/decorators/ObjectSearchIgnore';
import { ParameterDisplay } from '@/decorators/ParameterDisplay';
import { ParameterHasChild } from '@/decorators/ParameterHasChild';
import { BoxsObject3D } from '@/factorys/Object3DFromBoxFactory';
import { Box3, BoxHelper, Object3D, Vector3 } from 'three';
import { BoxDefaultValues } from './BoxDefaultValues';
import { BoxRotationDirection, getNextClockwiseDirection } from './BoxRotationDirection';
import { BoxWall } from './BoxParts/BoxWall/BoxWall';
import { BoxWindow } from './BoxParts/BoxWall/BoxWindow';
import { Object3DColorSetter } from '@/helpers/Object3DColorSetter';
import { Collision } from '@/physic/Collision';
import { BoxFloor } from './BoxParts/BoxFloor';
import { Object3DTruePositionCalculator } from '@/calculators/Object3DTruePositionCalculator';
import { BoxFrontFacing } from './BoxParts/BoxFrontFacing';
import { BoxPart } from './BoxParts/BoxPart';
import { BoxMiddleFloor } from './BoxParts/BoxMiddleFloor';
import { FrontFacingMaterial } from '@/CustomEnums/FrontFacingMaterial';
import { WindowType } from '@/CustomEnums/WindowType';
import { BoxStairs } from './BoxParts/BoxStairs';
import { BoxWallBit } from './BoxParts/BoxWall/BoxWallBit';
import { BoxRoom } from './BoxParts/BoxRoom';
import { RoomType } from '@/CustomEnums/RoomType';
import { BoxWallGroup } from './BoxParts/BoxWall/BoxWallGroup';
import { BoxSideRelativeToWindow } from './BoxSideRelativeToWindow';
import { BoxWallState } from './BoxParts/BoxWall/BoxWallState';

export class Box{

    @ObjectSearchIgnore
    private id: number;

    @ObjectSearchIgnore
    private position: Vector3;

    @ObjectSearchIgnore
    private scale!: number;
    
    @ObjectSearchIgnore
    private object3DTruePositionCalculator: Object3DTruePositionCalculator;

    @ObjectSearchIgnore
    // @ParameterDisplay("Rotation")
    // @EditableParameter({setter: "setRotation", type: EditableParameterType.enum, enumClass: BoxRotationDirection })
    private rotation: BoxRotationDirection;

    @ParameterDisplay({ name: "Détails à propos de la pièce", stepsVisible: []})
    @ParameterHasChild
    private boxDefaultValues: BoxDefaultValues | undefined;

    @ParameterDisplay({ name: "Nom pièce", stepsVisible: [3,4,5,6]})    
    @EditableParameter({setter: "setName", type: EditableParameterType.text, nullable: false })
    private boxName: string;

    @ParameterDisplay({ name: "Matériel fenêtre", stepsVisible: [5]})    
    @EditableParameter({setter: "setWindowsMaterial", type: EditableParameterType.enum, enumClass: Material, needRefresh: true, refreshMethodName:"refreshBox", nullable: false })
    private windowMaterial: Material | undefined;

    @ParameterDisplay({ name: "Type fenêtre", stepsVisible: [5]})    
    @EditableParameter({setter: "setWindowsType", type: EditableParameterType.enum, enumClass: WindowType, needRefresh: true, refreshMethodName:"refreshBox", nullable: false })
    private windowType: WindowType | undefined;

    @ParameterDisplay({ name: "Type de murs", stepsVisible: [4]})    
    @EditableParameter({setter: "setWallType", type: EditableParameterType.enum, enumClass: WallType, needRefresh: true, refreshMethodName:"refreshBox", nullable: false })
    private wallType: WallType | undefined;

    @ObjectSearchIgnore
    private hitbox!: Collision;

    @ObjectSearchIgnore
    private floor!: BoxFloor;

    @ParameterDisplay({ name: "Type de pièce", stepsVisible: [6]})    
    @EditableParameter({setter: "setRoomType", type: EditableParameterType.enum, enumClass: RoomType, needRefresh: true, refreshMethodName:"refreshBox", nullable: true })
    private roomType?: RoomType;

    @ParameterDisplay({ name: "Type de Facade", stepsVisible: [5]})    
    @EditableParameter({setter: "setFrontFacingMaterial", type: EditableParameterType.enum, enumClass: FrontFacingMaterial, needRefresh: true, refreshMethodName:"refreshBox", nullable: false })
    private frontFacingMaterial;

    @ObjectSearchIgnore
    private frontFacing!: BoxFrontFacing;

    @ObjectSearchIgnore
    private boxMiddleFloors: BoxMiddleFloor[];

    @ObjectSearchIgnore
    private walls!: BoxWallGroup[];

    @ObjectSearchIgnore
    private stairs?: BoxStairs;

    @ObjectSearchIgnore
    private wallBits: BoxWallBit[];

    @ObjectSearchIgnore 
    private room?: BoxRoom;

    public constructor (id: number, object3DTruePositionCalculator: Object3DTruePositionCalculator, collision: Collision, boxDefaultValues: BoxDefaultValues | undefined){
        this.id = id;
        this.position = new Vector3();
        this.rotation = BoxRotationDirection.Sud;
        this.object3DTruePositionCalculator = object3DTruePositionCalculator;
        this.boxDefaultValues = boxDefaultValues;
        this.boxName = "";
        this.windowMaterial = (Material.getPossibleEntrys() as Material[])[0];
        this.wallType = (WallType.getPossibleEntrys() as WallType[])[0];
        this.boxMiddleFloors = new Array<BoxMiddleFloor>();
        this.hitbox = collision;
        this.wallBits = new Array<BoxWallBit>();
        this.roomType = undefined;
    }

    public updateAllObjectsInHitbox () {
        this.hitbox.resetObjectsInHitbox();
        this.hitbox.addObjectsInHitbox(this.getAllObjects3dOfBox());
        this.hitbox.update();
    }

    public updateCollisionPosition () {
        this.hitbox.setPosition(this.position);
    }

    public getId (): number {
        return this.id;
    }

    public setRoomType (value: string | undefined) {
        if (value === undefined) {
            this.roomType = undefined;
            //this.room = undefined;
        }
        else {
            this.roomType = RoomType.getEntryOf(value) as RoomType;
            if (this.room === undefined) {
                this.room = new BoxRoom(new Object3D(), new BoxHelper(new Object3D()), this.roomType.key, 0);
            }
        }
    } 

    public getScale (): number {
        return this.scale;
    }

    public getCollision(): Collision {
        return this.hitbox;
    }

    public setScale (newScale: number){
        this.scale = newScale;
    }

    public getWallBits (): BoxWallBit[] {
        return this.wallBits;
    }

    public setWallBits (value: BoxWallBit[])  {
        this.wallBits = value;
    }

    public getRoom (): BoxRoom | undefined {
        return this.room;
    }

    public getRoomType (): RoomType | undefined {
        return this.roomType;
    }

    public getWindowMaterial (): Material {
        return this.windowMaterial as Material;
    }

    public getWindowType (): WindowType {
        return this.windowType as WindowType;
    }

    public getFloor (): BoxFloor {
        return this.floor;
    }

    public setFloor (newFloor: BoxFloor) {
        this.floor = newFloor;
    }

    public setStairs (value: BoxStairs) {
        this.stairs = value;
        this.updateAllScales();
        this.updateStairsPositionAndRotation();
    }

    public updateWallBitsVisible () {
        const lastWall0Index = this.walls[0].length - 1;
        this.wallBits[0].setVisible(!this.walls[0][lastWall0Index].getIsVisible());
        this.wallBits[1].setVisible(!this.walls[2][0].getIsVisible());
    }

    public removeStairs () {
        this.stairs = undefined;
        this.updateAllScales();
    }

    public getStairs(): BoxStairs | undefined {
        return this.stairs;
    } 

    public getFrontFacing(): BoxFrontFacing {
        return this.frontFacing;
    }

    public setFrontFacing (newFrontFacing: BoxFrontFacing) {
        this.frontFacing = newFrontFacing;
        this.frontFacingMaterial = newFrontFacing.getMaterial();
    }

    public setFrontFacingMaterial (newFrontFacingMaterial: string) {
        this.frontFacingMaterial = FrontFacingMaterial.getEntryOf(newFrontFacingMaterial);
        this.frontFacing.setMaterial(this.frontFacingMaterial);
    }

    public getMiddleFloors (): BoxMiddleFloor[] {
        return this.boxMiddleFloors;
    }

    public setBoxMiddleFloors(middleFloors: BoxMiddleFloor[]) {
        this.boxMiddleFloors = middleFloors;
    }

    public updateAllScales () {
        this.getAllObjects3dOfBox().forEach(obj => {
            obj.scale.set(this.scale, this.scale, this.scale);
        });
    } 

    public setWalls (walls: BoxWall[]) {
        this.walls = new Array<BoxWallGroup>();
        let size = 0;
        let bufferWallGroup!: BoxWallGroup;
        let side: BoxSideRelativeToWindow;
        walls.forEach(wall => {
            if (size % 3 === 0) {
                bufferWallGroup = new BoxWallGroup();
                this.walls.push(bufferWallGroup);
                side = (size / 3);
                bufferWallGroup.setEmplacement(side);
            }
            size += wall.getUnitSize();
            bufferWallGroup.push(wall);
        });
        this.setWindowsMaterial((walls[10] as BoxWindow).getMaterial().key);
        this.setWindowsType((walls[10] as BoxWindow).getName());
        this.setWallType(walls[0].getWallType().key);
    }

    public setWindowsMaterial (mat: string) {
        const newMat = Material.getEntryOf(mat) as Material;
        this.windowMaterial = newMat;
        this.getWindows().forEach(wall => {
            wall.setMaterial(newMat);
        });
    }

    public setWindowsType (type: string) {
        const newType = WindowType.getEntryOf(type) as WindowType;
        this.windowType = newType;
        this.getWindows().forEach(wall => {
            wall.setType(newType);
        });
    }

    public setWallType (wallType: string) {
        const newWallType = WallType.getEntryOf(wallType) as WallType;
        this.wallType = newWallType;
        this.trueWalls().forEach(wall => {
            wall.setWallType(newWallType);
        });
    }

    public getWallType (): WallType {
        return this.wallType as WallType;
    }

    private trueWalls (): BoxWall[] {
        const wallList = new Array<BoxWall>();
        this.walls.forEach(wallsGroup => wallList.concat(wallsGroup as BoxWall[]));
        return wallList.filter(wall => wall.constructor.name == "BoxWall");
    }

    public getWindows (): BoxWindow[] {
        const windowList = new Array<BoxWindow>();
        this.walls.forEach(wallsGroup =>  wallsGroup.forEach(wall => { 
            if (wall.constructor.name === BoxWindow.name) {
                windowList.push(wall as BoxWindow);
            }}));
        return windowList;
    }

    public getAllObjects3dOfBox(): Object3D[] {
        let objects = new Array<Object3D>();
        objects.push(this.floor.getObject());
        objects.push(this.frontFacing.getObject());
        this.boxMiddleFloors.forEach(middleFloor => {
            objects.push(middleFloor.getObject());
        });
        this.walls.forEach(wall => {
            objects = objects.concat(wall.getAllObjects());
        });
        this.wallBits.forEach(wallBit => {
            objects.push(wallBit.getObject());
        });
        if (this.stairs !== undefined && this.stairs.isBoxTheBottomBox(this)) {
            objects.push(this.stairs.getObject());
        }
        if(this.room !== undefined) {
            objects.push(this.room.getObject());
        }
        return objects;
    }

    public getAllHitboxsOfAllObjects3dOfBox(): Object3D[] {
        let objects = new Array<Object3D>();
        objects.push(this.floor.getHitbox());
        objects.push(this.frontFacing.getHitbox());
        this.boxMiddleFloors.forEach(middleFloor => {
            objects.push(middleFloor.getHitbox());
        });
        this.walls.forEach(wall => {
            objects = objects.concat(wall.getAllHitboxes());
        });
        this.wallBits.forEach(wallBit => {
            objects.push(wallBit.getHitbox());
        });
        if(this.stairs != undefined) {
            objects.push(this.stairs.getHitbox());
        }
        if(this.room != undefined) {
            objects.push(this.room.getHitbox());
        }
        return objects;
    }

    private updateStairsPositionAndRotation () {
        if (this.stairs != undefined && this.stairs.isBoxTheBottomBox(this)) {
            this.stairs.setPositionAndRotationFromBox(this);
        }
    }

    private updateRoomRotation () {
        (this.room as BoxRoom).setRotation(this.room!.getRotation());
    }

    private updateRoomPosition () {
        if (this.room !== undefined) {
            this.updateRoomRotation();
            const newPos = this.floor.getTruePosition().clone();
            newPos.y = this.position.y;
            // newPos.x = newPos.x- this.getSize().x/2 + this.room.getSize().x/2 + 0.5;
            // newPos.z = newPos.z- this.getSize().z/2 + this.room.getSize().z/2 + 0.5;
            newPos.y += (this.room as BoxRoom).getSize().y/2;
            newPos.y += this.floor.getSize().y;
            (this.room as BoxRoom).setPosition(newPos);
        }
    }

    private updateWallsPosition () {
        const boxPos = this.position.clone();
        const wallHeight = this.floor.getTruePosition().y + this.floor.getSize().y/2;
        let rotatePositions = 0;
        switch (this.rotation) {
            case BoxRotationDirection.Est: {
                rotatePositions = 1;
                break;
            }
            case BoxRotationDirection.Ouest:{
                rotatePositions = 3;
                break;
            }
            case BoxRotationDirection.Sud:{
                rotatePositions = 0;
                break;
            }
            case BoxRotationDirection.Nord:{
                rotatePositions = 2;
                break;
            }
        }
        const newWallLists = this.rotateArray(this.walls, rotatePositions);

        newWallLists[0].setPosition(new Vector3(boxPos.x - 3.25,wallHeight,boxPos.z));
        newWallLists[1].setPosition(new Vector3(boxPos.x,wallHeight,boxPos.z - 3.25));
        newWallLists[2].setPosition(new Vector3(boxPos.x + 3.25,wallHeight,boxPos.z));
        newWallLists[3].setPosition(new Vector3(boxPos.x,wallHeight,boxPos.z + 3.25));

        this.walls.forEach(wall => {
            wall.deplacer(new Vector3(0,wall.getSize().y/2,0));
        });
    }

    private rotateArray<T>(array: T[], rotations: number) {
        const newArray = new Array<T>();
        array.forEach(obj => newArray.push(obj));
        for(let x = 0; x < rotations; x++) {
            newArray.push(newArray.shift() as T);
        }
        return newArray;
    }

    private updateMiddleFloorPosition() {
        this.updateMiddleFloorRotation();
        let newX = 0;
        let newZ = 0;
        let changeZ = false;
        let signeMultiplication = 1;
        switch (this.getRotation()) {
            case BoxRotationDirection.Est: {
                newX = -0.5;
                newZ = 0;
                break;
            }
            case BoxRotationDirection.Ouest:{
                newX = 0.5;
                newZ = 0;
                signeMultiplication = -signeMultiplication;
                break;
            }
            case BoxRotationDirection.Sud:{
                newX = 0;
                newZ = -0.5;
                changeZ = true;
                break;
            }
            case BoxRotationDirection.Nord:{
                newX = 0;
                newZ = 0.5;
                signeMultiplication = -signeMultiplication;
                changeZ = true;
                break;
            }
        }
        const middleFloorHeight = this.walls[0][0].getTruePosition().y + this.walls[0].getSize().y/2;
        this.boxMiddleFloors[0].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z +newZ));
        if(changeZ) {
            newZ -= (this.boxMiddleFloors[0].getSize().z/2 - 1.2427) * signeMultiplication;
            this.boxMiddleFloors[1].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z +newZ));
            newZ += (this.boxMiddleFloors[1].getSize().z) * signeMultiplication;
            this.boxMiddleFloors[2].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z + newZ));
            newZ += (this.boxMiddleFloors[2].getSize().z) * signeMultiplication;
            this.boxMiddleFloors[3].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z + newZ));
        }
        else {
            newX -= (this.boxMiddleFloors[0].getSize().x/2 - 1.2427) * signeMultiplication;
            this.boxMiddleFloors[1].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z + newZ));
            newX += (this.boxMiddleFloors[1].getSize().x) * signeMultiplication;
            this.boxMiddleFloors[2].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z + newZ));
            newX += (this.boxMiddleFloors[2].getSize().x) * signeMultiplication;
            this.boxMiddleFloors[3].setPosition(new Vector3(this.position.x + newX,middleFloorHeight, this.position.z + newZ));
        }
        this.boxMiddleFloors.forEach(middleFloor => {
            middleFloor.deplacer(new Vector3(0,middleFloor.getSize().y/2,0));
        });
    }

    private updateWallBitRotation () {
        this.wallBits.forEach(wallBit => {
            wallBit.setRotation(this.rotation);
        });
    }

    private updateWallBitPosition () {
        this.updateWallBitRotation();
        const boxPos = this.position.clone();
        const yStartPosition = this.floor.getSize().y;
        let newX = 0;
        let newZ = 0;
        let ifOnZDifference = false;
        switch (this.getRotation()) {
            case BoxRotationDirection.Est: {
                newX = -3.25;
                newZ = 3.25;
                ifOnZDifference = true;
                break;
            }
            case BoxRotationDirection.Ouest:{
                newX = 3.25;
                newZ = -3.25;
                ifOnZDifference = true;
                break;
            }
            case BoxRotationDirection.Sud:{
                newX = -3.25;
                newZ = -3.25;
                break;
            }
            case BoxRotationDirection.Nord:{
                newX = 3.25;
                newZ = 3.25;
                break;
            }
        }
        if (ifOnZDifference) {
            this.wallBits[0].setPosition(boxPos.clone().add(new Vector3(newX, yStartPosition, newZ)));
            this.wallBits[1].setPosition(boxPos.clone().add(new Vector3(newX, yStartPosition, -newZ)));
        }
        else {
            this.wallBits[0].setPosition(boxPos.clone().add(new Vector3(newX, yStartPosition, newZ)));
            this.wallBits[1].setPosition(boxPos.clone().add(new Vector3(-newX, yStartPosition, newZ)));
        }

        this.wallBits.forEach(wallBit => {
            wallBit.deplacer(new Vector3(0,wallBit.getSize().y/2,0));
        });
    }

    private updateMiddleFloorRotation () {
        this.boxMiddleFloors.forEach(middleFloor => {
            middleFloor.setRotation(this.rotation);
        });
    }

    private updateWallsRotation () {
        const leftOfFacingRotation = this.getRotation();
        const rightOfFacingRotation = getNextClockwiseDirection(this.getRotation(),2) as BoxRotationDirection;
        const reverseOfFacingRotation = getNextClockwiseDirection(this.getRotation()) as BoxRotationDirection;
        const frontRotation = getNextClockwiseDirection(this.getRotation(),3) as BoxRotationDirection;
        this.walls[0].setRotation(rightOfFacingRotation);
        this.walls[1].setRotation(reverseOfFacingRotation);
        this.walls[2].setRotation(leftOfFacingRotation);
        this.walls[3].setRotation(frontRotation);
    }

    public getWalls(): BoxWallGroup[]{
        return this.walls;
    }

    public setCollision(value: Collision) {
        this.hitbox = value;
    }

    public getName(): string {
        return (this.boxDefaultValues as BoxDefaultValues).name;
    }

    public getPerformance(): number {
        return (this.boxDefaultValues as BoxDefaultValues).performance;
    }

    public getPrice(): number {
        return (this.boxDefaultValues as BoxDefaultValues).price;
    }

    public setRotation(angle: BoxRotationDirection) {
        this.rotation = angle;
        this.updateAllBox();
    }

    public getRotation (): BoxRotationDirection {
        return this.rotation;
    }

    public setName(name: string) {
        this.boxName = name;
    }

    public setPosition(nouvellePosition: Vector3) {
        this.position.copy(nouvellePosition);
        this.updateAllBox();
        this.updateAllObjectsInHitbox();
        this.hitbox.setPosition(this.position.clone())
    }

    public updateAllBox() {
        this.updateFloorPosition();
        this.updateWallsRotation();
        this.updateFrontFacingPosition();
        this.updateWallsPosition();
        this.updateMiddleFloorPosition();
        this.updateStairsPositionAndRotation();
        this.updateWallBitPosition();
        this.updateRoomPosition();
        this.hitbox.update();
    }


    private updateFloorPosition() {
        let newX = 0;
        let newZ = 0;
        switch (this.getRotation()) {
            case BoxRotationDirection.Est: {
                newX = -0.5;
                newZ = 0;
                break;
            }
            case BoxRotationDirection.Ouest:{
                newX = 0.5;
                newZ = 0;
                break;
            }
            case BoxRotationDirection.Sud:{
                newX = 0;
                newZ = -0.5;
                break;
            }
            case BoxRotationDirection.Nord:{
                newX = 0;
                newZ = 0.5;
                break;
            }
        }
        this.floor.setRotation(this.rotation);
        this.floor.setPosition(this.position.clone().add(new Vector3(newX,-this.getDecalage(this.floor),newZ)));
    }

    private updateFrontFacingPosition() {
        let newX = 0;
        let newZ = 0;
        switch (this.getRotation()) {
            case BoxRotationDirection.Est: {
                newX = 3;
                newZ = 0;
                break;
            }
            case BoxRotationDirection.Ouest:{
                newX = -3;
                newZ = 0;
                break;
            }
            case BoxRotationDirection.Sud:{
                newX = 0;
                newZ = 3;
                break;
            }
            case BoxRotationDirection.Nord:{
                newX = 0;
                newZ = -3;
                break;
            }
        }
        this.updateFrontFacingRotation();
        this.frontFacing.setPosition(this.position.clone().add(new Vector3(newX,-this.getDecalage(this.frontFacing),newZ)));
    }

    private updateFrontFacingRotation () {
        this.frontFacing.setRotation(this.rotation);
    }

    private getDecalage (boxPart: BoxPart): number {
        const box = new Box3().setFromObject(boxPart.getObject());
        const center = box.getCenter( new Vector3() );
        return ( boxPart.getObject().position.y - center.y );
    }

    public getSize(): Vector3 {
        return this.hitbox.getSize();
    }
    
    public deplacer(deplacement: Vector3){
        this.position.add(deplacement);
    }

    public getPosition (): Vector3 {
        return this.position;
    } 

    public refreshBox (objects: BoxsObject3D) {
        for(let x = 0; x < this.walls.length; x++) {
            for(let y = 0; y < this.walls[x].length; y++) {
                if(this.walls[x][y].constructor.name === objects.walls[x][y][0].name){
                    this.walls[x][y].refreshObject3D(objects.walls[x][y][1]);
                }
                else {
                    const oldPosition = this.walls[x][y].getTruePosition();
                    const oldForcedState = this.walls[x][y].getForcedState();
                    const isVisible = this.walls[x][y].getForcedState() !== BoxWallState.none && this.walls[x][y].getIsVisible();
                    const olderDefaultState = this.walls[x][y].getDefaultState();
                    switch (objects.walls[x][y][0].name) {
                        case BoxWindow.name: {
                            this.walls[x][y] = new BoxWindow(objects.walls[x][y][1], new BoxHelper(objects.walls[x][y][1]),this.windowType!.key, this.windowMaterial as Material, this.walls[x][y].getUnitSize());
                            break;
                        }
                        case BoxWall.name: {
                            this.walls[x][y] = new BoxWall(objects.walls[x][y][1], new BoxHelper(objects.walls[x][y][1]),this.wallType!.key, this.walls[x][y].getUnitSize());
                            break;
                        }
                    }
                    this.walls[x][y].setDefaultState(olderDefaultState);
                    this.walls[x][y].setPosition(oldPosition); 
                    this.walls[x][y].setForcedState(oldForcedState);
                    this.walls[x][y].setVisible(isVisible);
                    this.walls[x][y].refreshObject3D(objects.walls[x][y][1]);
                }
                if (this.walls[x][y].hasForcedState()) {
                    if (!objects.invisiblesWalls[x][y]){
                        this.walls[x][y].setVisible(false);
                    }
                    else {
                        this.walls[x][y].setVisible(true);
                    }
                }
            }
        }
        this.walls.forEach(wallsGroup => wallsGroup.updateHitbox());
        for(let x = 0; x < objects.wallBits.length; x++) {
            this.wallBits[x].refreshObject3D(objects.wallBits[x]);
        }
        for(let x = 0; x < objects.middleFloors.length; x++) {
            this.boxMiddleFloors[x].refreshObject3D(objects.middleFloors[x]);
        }
        this.floor.refreshObject3D(objects.floor);
        this.frontFacing.refreshObject3D(objects.frontFacing);
        if (this.stairs != undefined && objects.stairs != undefined && this.stairs.isBoxTheBottomBox(this)) {
            this.stairs.refreshObject3D(objects.stairs);
        }
        if(objects.room !== undefined) {
            if (this.room !== undefined) {
                this.room.refreshObject3D(objects.room);
            }
            else {
                this.room = new BoxRoom(objects.room, new BoxHelper(objects.room), (this.roomType as RoomType).key, objects.roomPrice as number);
                this.room.refreshObject3D(objects.room);
            }
        }
        else {
            this.room = undefined;
        }
        this.updateAllScales();
        this.updateAllObjectsInHitbox();
        this.updateAllBox();
        //this.hitbox.setPosition(this.getPosition, true);
    }

    public setBoxColor(colorSetter: Object3DColorSetter, color: string) {
        this.getAllObjects3dOfBox().forEach(obj => {
            colorSetter.setEmissiveOfObjectsAndHisChildrens(obj, color);
        });
    }

    public setBoxTransparent (colorSetter: Object3DColorSetter) {
        this.getAllObjects3dOfBox().forEach(obj => {
            colorSetter.setTransparentOfObjectAndHisChildren(obj);
        });
    }
    
    public getCopy(id: number): Box {
        const trueCopy = new Box(id, this.object3DTruePositionCalculator, this.hitbox, this.boxDefaultValues);
        trueCopy.boxName = this.boxName;
        trueCopy.rotation = this.rotation;
        //trueCopy.scale = this.scale;
        //trueCopy.updateAllScales();
        return trueCopy;
    }
}